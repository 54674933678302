import React, { useState, useEffect } from 'react';
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

export default function RulesEntityStatuses({ dataRule, phoneStates, addressStates, emailStates, urlStates, ruleChanged }) {
    const stateTabs = {
        phoneStatesTab: 0,
        addressStatesTab: 1,
        emailStatesTab: 2,
        urlStatesTab: 3,
    };

    const [selectedTab, setSelectedTab] = useState(stateTabs.phoneStatesTab);

    const [phoneStatesList, setPhoneStatesList] = useState([]);
    const [addressStatesList, setAddressStatesList] = useState([]);
    const [emailStatesList, setEmailStatesList] = useState([]);
    const [urlStatesList, setUrlStatesList] = useState([]);

    useEffect(() => mountProperties(), [dataRule.id, phoneStates, addressStates, emailStates, urlStates]);

    // TODO: Add Tabs
    return <div className="dm-container dm-no-padding">
        <TabStrip style={{ height: "-webkit-fill-available" }}
            selected={selectedTab}
            onSelect={tabSelected}
            scrollable={true}>
            <TabStripTab title={window.captions.PhoneStates} key={stateTabs.phoneStatesTab}>
                <ItemSelector
                    items={phoneStatesList}
                    selectedItemsChanged={selectedPhoneStatesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.AddressState} key={stateTabs.addressStatesTab}>
                <ItemSelector
                    items={addressStatesList}
                    selectedItemsChanged={selectedAddressStatesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.EmailStates} key={stateTabs.emailStatesTab}>
                <ItemSelector
                    items={emailStatesList}
                    selectedItemsChanged={selectedEmailStatesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.UrlStatuses} key={stateTabs.urlStatesTab}>
                <ItemSelector
                    items={urlStatesList}
                    selectedItemsChanged={selectedUrlStatesChanged} />
            </TabStripTab>
        </TabStrip>
    </div>;

    function mountProperties() {
        if (!dataRule || (!phoneStates && !addressStates && !emailStates && !urlStates)) {
            setPhoneStatesList([]);
            setAddressStatesList([]);
            setEmailStatesList([]);
            setUrlStatesList([]);
            return;
        }

        let phoneStatesLocal = Array.isArray(phoneStates) && phoneStates.length ? phoneStates : [];
        phoneStatesLocal.forEach(i => i.isSelected = dataRule.phoneStates.some(id => id == i.id));
        let addressStatesLocal = Array.isArray(addressStates) && addressStates.length ? addressStates : [];
        addressStatesLocal.forEach(i => i.isSelected = dataRule.addressStates.some(id => id == i.id));
        let emailStatesLocal = Array.isArray(emailStates) && emailStates.length ? emailStates : [];
        emailStatesLocal.forEach(i => i.isSelected = dataRule.emailStates.some(id => id == i.id));
        let urlStatesLocal = Array.isArray(urlStates) && urlStates.length ? urlStates : [];
        urlStatesLocal.forEach(i => i.isSelected = dataRule.urlStates.some(id => id == i.id));

        setPhoneStatesList(phoneStatesLocal);
        setAddressStatesList(addressStatesLocal);
        setEmailStatesList(emailStatesLocal);
        setUrlStatesList(urlStatesLocal);
    }

    function selectedPhoneStatesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                phoneStates: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedAddressStatesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                addressStates: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedEmailStatesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                emailStates: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedUrlStatesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                urlStates: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function tabSelected(e) {
        setSelectedTab(e.selected);
    }
}