import React, { useState, useEffect } from 'react';
import { TreeView } from "@progress/kendo-react-treeview";

export default function RuleAdmin({ dataRule, privilegesAdminGeneral, privilegesAdminCustom, ruleChanged }) {
    const [itemsGeneral, setItemsGeneral] = useState(null);
    const [itemsCustom, setItemsCustom] = useState(null);

    useEffect(() => mountProperties(), [dataRule.id, privilegesAdminGeneral, privilegesAdminCustom]);

    return <div className="dm-container dm-no-padding">
        <span>
            <TreeView
                key="privilegesAdminGeneralTree"
                checkboxes={true}
                expandIcons={true}
                data={itemsGeneral}
                checkIndeterminateField="checkIndeterminate"
                textField="name"
                onExpandChange={onExpandChange}
                onCheckChange={(e) => onCheckChangeTreeView(e, () => setItemsGeneral([...itemsGeneral]))}
            />
        </span>
        <span>
            <TreeView
                key="privilegesAdminCustomTree"
                checkboxes={true}
                expandIcons={true}
                data={itemsCustom}
                checkIndeterminateField="checkIndeterminate"
                textField="name"
                onExpandChange={onExpandChange}
                onCheckChange={(e) => onCheckChangeTreeView(e, () => setItemsCustom([...itemsCustom]))}
            />
        </span>
    </div>;

    function mountProperties() {
        if (!dataRule || (!privilegesAdminGeneral && !privilegesAdminCustom)) {
            setItemsGeneral([]);
            setItemsCustom([]);
            return;
        }

        let privilegesAdminGeneralList = Array.isArray(privilegesAdminGeneral) && privilegesAdminGeneral.length ? privilegesAdminGeneral : [];
        privilegesAdminGeneralList.forEach(i => setParents(i));
        privilegesAdminGeneralList.forEach(i => setCheckPrivileges(i, dataRule));
        let privilegesAdminCustomList = Array.isArray(privilegesAdminCustom) && privilegesAdminCustom.length ? privilegesAdminCustom : [];
        privilegesAdminCustomList.forEach(i => setParents(i));
        privilegesAdminCustomList.forEach(i => setCheckPrivileges(i, dataRule));

        setItemsGeneral(privilegesAdminGeneralList);
        setItemsCustom(privilegesAdminCustomList);
    }

    function setCheckPrivileges(data, dataRule) {
        if (!data) {
            return;
        }

        if (data.privilege && data.privilege >= 0 && dataRule.priveliges.length > data.privilege) {
            data.checked = dataRule.priveliges[data.privilege];
            if (data.checked) {
                data.items.forEach(i => i.checked = true);
                return;
            }
        }

        if (data.items && data.items.length > 0) {
            data.items.forEach(i => setCheckPrivileges(i, dataRule));
            if (data.items.some((item) => item.checked)) {
                if (data.items.every((item) => item.checked)) {
                    data.checked = true;
                    data.checkIndeterminate = false;
                } else {
                    data.checked = false;
                    data.checkIndeterminate = true;
                }
            }
            else {
                data.checked = false;
                data.checkIndeterminate = false;
            }
        }
    }

    function licenseNotificationChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                licenseNotificationDays: e.value,
                isUpdated: true
            });
    }

    function numberPagedLinesSearchResultChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                countSearch: e.value,
                isUpdated: true
            });
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };

    function onCheckChangeTreeView(event, changed) {
        event.item.checked = !event.item.checked;
        checkRecursive(event.item);
        checkParents(event.item);

        changed();
    }

    function checkRecursive(item) {
        if (item.items && item.items.length > 0)
            item.items.forEach(i => i.checked = item.checked);
    }

    function checkParents(item) {
        var parent = item.getParent();
        if (!parent) {
            return;
        }

        if (parent.items.some((item) => item.checked)) {
            if (parent.items.every((item) => item.checked)) {
                parent.checked = true;
                parent.checkIndeterminate = undefined;
            } else {
                parent.checked = undefined;
                parent.checkIndeterminate = true;
            }
        }
        else {
            parent.checked = false;
            parent.checkIndeterminate = undefined;
        }
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }
}