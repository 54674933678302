import React, { useState, useEffect } from 'react';
import { fetchGet } from "../../../../../utils/requestsHelper.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import MultilineTextArea from "../../../../../components/controls/multilineTextArea.jsx";
import DropDownTreeView from "../../../../../components/controls/dropDownTreeView.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { formatDate } from "@progress/kendo-intl";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { getFormatDateTime, dateTimeWidth, dateTimeFormat } from "../../../../../utils/systemConstants.jsx";
import { getUiCulture } from "../../../../../utils/authHelper.jsx";
import MessageMask from "../../../../../components/messageMask.jsx";
import { getSoftPhoneTypes } from "../../../../../utils/systemConstants.jsx";


export default function AccessParametersTab({ dataUser, rules, groupList, userChanged, selectRuleItem }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);

    const [softPhoneTypes, setSoftPhoneTypes] = useState([]);
    const [stages, setStages] = useState([]);

    const [selectedSoftPhoneType, setSelectedSoftPhoneType] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);
    const [selectedDeputy, setSelectedDeputy] = useState(null);

    useEffect(() => {
        var softPhoneTypesLocal = getSoftPhoneTypes();
        var softPhoneType = softPhoneTypesLocal.find(x => x.value === dataUser.softPhoneTypeId) ?? softPhoneTypesLocal[0];

        setSoftPhoneTypes(softPhoneTypesLocal);
        setStages([]);
        setPending(window.captions.LoadingData);
        setError(null);
        setSelectedRule(rules.find(x => x.id === dataUser.rule));
        setSelectedDeputy(getUserFromGroupList(dataUser.deputy));
        setSelectedSoftPhoneType(softPhoneType);

        fetchGet(`${window.constants.getStagesList}/`,
            (items) => {
                var stages = items ?? [];
                stages.unshift({ id: 0, name: window.constants.getStagesList });
                var stageId = dataUser.cardViewStage ?? 0;
                var selectedStage = stages.find(x => x.id === stageId) ?? stages[0];

                setStages(stages);
                setSelectedStage(!selectedStage ? null : selectedStage);
                setPending(null);
            },
            (ex) => {
                setPending(null);
                setError(ex);
            }
        );
    }, [dataUser.id, rules, groupList]);

    if (pending || error) {
        return <div className="dm-container dm-no-padding">
            <MessageMask inline text={pending} error={error} />
        </div>;
    }

    const elements = [];

    if (dataUser.id) {
        renderPair(elements, "Id", "Id", null, dataUser.id, false);
    }

    renderPair(elements, window.captions.Fio, "fio", () => <div id="userFio">
        <MultilineTextArea changeText={changeUserName} textLength={255} text={dataUser.name} />
    </div>);

    renderPair(elements, window.captions.Login, "login", () => <Input
        value={dataUser.login}
        name="login"
        onChange={onChangeLogin}
        style={{ width: "100%" }}
        minLength={1}
        maxLength={255}
        required={true}
        validationMessage={window.captions.CorrectLogin} />);

    if (dataUser.isCurrentUser) {
        renderPair(elements,
            window.captions.CurrentPassword,
            "changePasswordOld",
            () => <Input
                key="passwordOld"
                name="passwordOld"
                type="password"
                onChange={onChangePasswordOld}
                style={{ width: "100%" }}
                minLength={1}
                maxLength={255} 
                value={dataUser.passwordOld ?? ""}/>);
    }

    renderPair(elements,
        window.captions.NewPassword,
        "changePassword",
        () => <Input
            key="password"
            name="password"
            type="password"
            onChange={onChangeUserPassword}
            style={{ width: "100%" }}
            minLength={1}
            maxLength={255}
            value={dataUser.password ?? ""} />);

    renderPair(elements, window.captions.Role, "role", () => <DropDownList
        style={{ width: 200 }}
        dataItemKey="id"
        data={rules}
        textField="name"
        value={selectedRule}
        onChange={onChangeRule} />);

    renderPair(elements,
        window.captions.GoToRoleSettings,
        "goToRoleSettings",
        () => <Button key="goToRoleSettingsUser" onClick={(e) => goToRoleSettings(selectedRule)} title={window.captions.GoToRoleSettings}        >
            {window.captions.GoToRoleSettings}
        </Button>);

    renderPair(elements,
        window.captions.DenyAccessProgram,
        "denyAccessProgram",
        () => <Checkbox key="denyAccessProgramUser" value={dataUser.isDenyAccess} onChange={onChangeDenyAccessProgram} />);

    renderPair(elements,
        window.captions.AccessOnlyRead,
        "accessOnlyRead",
        () => <Checkbox key="accessOnlyReadUser" value={dataUser.isReadOnly} onChange={accessOnlyReadChange} />);

    renderPair(elements,
        window.captions.BusinessAdministrator,
        "businessAdministrator",
        () => <Checkbox key="businessAdministratorUser" value={dataUser.isBusinessAdministrator} onChange={businessAdministratorChange} />);

    renderPair(elements,
        window.captions.MultipleUsersAllowLogin,
        "multipleUsersAllowLogin",
        () => <Checkbox key="multipleUsersAllowLoginUser" value={dataUser.multipleUsersAllowLogin} onChange={multipleUsersAllowLoginChange} />);

    renderPair(elements, window.captions.UserBlocked, "userBlocked",
        () => <Checkbox key="userBlockedUser" value={dataUser.isBlocked} onChange={userBlockedUserChange} />);

    renderPair(elements, window.captions.IpPhoneCode, "ipPhoneCode",
        () => <MultilineTextArea id="ipPhoneCodeUser" changeText={ipPhoneCodeUserChanged} text={dataUser.ipPhoneCodeUser} />);

    renderPair(elements,
        window.captions.Password,
        "ipPhonePassword",
        () => <Input
            key="ipPhonePasswordUser"
            name="ipPhonePasswordUser"
            type="password"
            onChange={ipPhonePasswordChange}
            style={{ width: "100%" }}
            minLength={1}
            maxLength={255}
            validationMessage={window.captions.CorrectLogin}
            value={dataUser.ipPhonePassword ?? ""} />);

    renderPair(elements, window.captions.Email, "email",
        () => <MultilineTextArea id="emailUser" changeText={emailChanged} text={dataUser.email} />);

    renderPair(elements, window.captions.Password, "emailPassword",
        () => <Input
            key="emailPasswordUser"
            name="emailPasswordUser"
            type="password"
            onChange={emailPasswordChanged}
            style={{ width: "100%" }}
            minLength={1}
            maxLength={255}
            validationMessage={window.captions.CorrectLogin}
            value={dataUser.emailPassword ?? ""} />);

    renderPair(elements,
        window.captions.TypeSoftPhone,
        "typeSoftPhone",
        () => <DropDownList
            style={{ width: 200 }}
            dataItemKey="value"
            data={softPhoneTypes}
            textField="name"
            value={selectedRule}
            onChange={typeSoftPhoneChanged} />);

    renderPair(elements,
        window.captions.ActionAddStage,
        "actionAddStage",
        () => <DropDownList
            style={{ width: 200 }}
            dataItemKey="id"
            data={stages}
            textField="name"
            value={selectedStage}
            onChange={actionAddStageChanged} />);

    renderPair(elements,
        window.captions.AdministrativeUserDeputy,
        "administrativeUserDeputy",
        () => <DropDownTreeView
            id="TaskDialogUser"
            treeData={groupList}
            selectedId={dataUser.deputy}
            selectionChanged={administrativeUserDeputyChanged}
            style={{ width: "100%" }} />);

    renderPair(elements,
        window.captions.Created,
        "created",
        null,
        formatDate(dataUser.created, dateTimeFormat, getUiCulture()),
        false
    );

    renderPair(elements, window.captions.Fired, "fired",
        () => <Checkbox
            key="isFired"
            value={dataUser.isDeleted}
            onChange={isFiredChange}
        >
            {dataUser.isDeleted ? (
                <DateTimePicker
                    onChange={firedDateChange}
                    min={dataUser.deleted ? dataUser.deleted : new Date()}
                    value={dataUser.deleted}
                    width={dateTimeWidth}
                    formatPlaceholder={getFormatDateTime()}
                />
            ) : null}
        </Checkbox>);

    return <div className="dm-flex-height">
        <div className="dm-container dm-no-padding">
            {elements}
        </div>
    </div>;

    function getUserFromGroupList(userId) {
        if (!userId)
            return null;

        for (const group of groupList) {
            if (!group.items)
                continue;

            for (const user of group.items) {
                if (user.id == userId)
                    return user;
            }
        }

        return null;
    }

    function changeUserName(e) {
        userChanged({ ...dataUser, name: e.value });
    }

    function onChangeLogin(e) {
        userChanged({ ...dataUser, login: e.value });
    }

    function onChangeUserPassword(e) {
        userChanged({ ...dataUser, password: e.value });
    }

    function onChangePasswordOld(e) {
        userChanged({ ...dataUser, passwordOld: e.value });
    }

    function onChangeRule(e) {
        setSelectedRule(e.value);
        userChanged({ ...dataUser, rule: e.value.id });
    }

    function parentuserChange(e) {
        userChanged({ ...dataUser, parentId: e.value.id });
    }

    function goToRoleSettings(rule) {
        if (selectRuleItem)
            selectRuleItem(rule.id);
    }

    function onChangeDenyAccessProgram(e) {
        userChanged({ ...dataUser, isDenyAccess: e.value });
    }

    function accessOnlyReadChange(e) {
        userChanged({ ...dataUser, isReadOnly: e.value });
    }

    function businessAdministratorChange(e) {
        userChanged({ ...dataUser, isBusinessAdministrator: e.value });
    }

    function multipleUsersAllowLoginChange(e) {
        userChanged({ ...dataUser, multipleUsersAllowLogin: e.value });
    }

    function userBlockedUserChange(e) {
        userChanged({ ...dataUser, isBlocked: e.value });
    }

    function ipPhoneCodeUserChanged(e) {
        userChanged({ ...dataUser, ipPhoneCodeUser: e.value });
    }

    function ipPhonePasswordChange(e) {
        userChanged({ ...dataUser, ipPhonePassword: e.value });
    }

    function emailChanged(e) {
        userChanged({ ...dataUser, email: e.value });
    }

    function emailPasswordChanged(e) {
        userChanged({ ...dataUser, emailPassword: e.value });
    }

    function typeSoftPhoneChanged(e) {
        setSelectedSoftPhoneType(e.value);
        userChanged({ ...dataUser, softPhoneTypeId: e.value.id });
    }

    function actionAddStageChanged(e) {
        setSelectedStage(e.value);
        userChanged({ ...dataUser, cardViewStage: e.value.id });
    }

    function administrativeUserDeputyChanged(e) {
        userChanged({ ...dataUser, deputy: e.value });
    }

    function isFiredChange(e) {
        userChanged({ ...dataUser, isDeleted: e.value });
    }

    function firedDateChange(e) {
        userChanged({ ...dataUser, deleted: e.value });
    }
}