import React, { useState, useEffect } from 'react';
import { fetchGet } from "../../../../utils/requestsHelper.jsx";
import { renderPair } from "../../../cards/cardRenderFunctions.jsx";
import MultilineTextArea from "../../../../components/controls/multilineTextArea.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import ColorPickerControl from "../../../cards/controls/colorPickerControl.jsx";
import MessageMask from "../../../../components/messageMask.jsx";
import LangItemEditor from "../../../../components/controls/langItemEditor.jsx";
import { getUiLang } from "../../../../utils/authHelper.jsx";
import { getUiSources } from "../../../../utils/systemConstants.jsx";


export default function GroupControl({ dataGroup, rules, groupList, groupChanged, addNewUser }) {
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [isGroupTaskListLoaded, setIsGroupTaskListLoaded] = useState(false);

    const [groupTaskList, setGroupTaskList] = useState(null);

    const [selectedRule, setSelectedRule] = useState(null);
    const [parentGroup, setParentGroup] = useState(null);
    const [groupTask, setGroupTask] = useState(null);

    useEffect(() => mountProperties(), [dataGroup.id]);

    if (pending || error || !isGroupTaskListLoaded) {
        return <div className="dm-container dm-no-padding">
            <MessageMask inline text={pending} error={error} />
        </div>;
    }

    const elements = [];

    if (dataGroup.id) {
        renderPair(elements,
            "Id",
            "Id",
            null,
            dataGroup.id,
            false
        );
    }

    if (dataGroup && dataGroup.langItem) {
        renderPair(elements,
            window.captions.Caption,
            "LangEditor",
            () => <LangItemEditor key={`langEditorGroup_${dataGroup.id}`}
                defaultName={dataGroup.name}
                langItem={dataGroup.langItem}
                namedChanged={changeGroupName} />);
    }

    renderPair(elements,
        window.captions.Role,
        "role",
        () => <DropDownList
            style={{ width: 200 }}
            dataItemKey="id"
            data={rules}
            textField="name"
            value={selectedRule}
            onChange={selectGroupRule}
        />);

    renderPair(elements,
        window.captions.ParentGroup,
        "parentGroup",
        () => <DropDownList
            style={{ width: 200 }}
            dataItemKey="id"
            data={groupList}
            textField="name"
            value={parentGroup}
            onChange={parentGroupChange}
        />);

    renderPair(elements,
        window.captions.GroupTask,
        "groupTask",
        () => <DropDownList
            style={{ width: 200 }}
            dataItemKey="id"
            data={groupTaskList}
            textField="name"
            value={groupTask}
            onChange={selectGroupTask}
        />);

    if (!groupTask) {
        renderPair(elements,
            window.captions.GroupIntervals,
            "groupIntervals",
            () => <Input
                style={{ width: 200 }}
                value={dataGroup.groupIntervals}
                onChange={groupIntervalsChange}
            />);
    }

    renderPair(elements,
        window.captions.CanBeExternalUser,
        "canBeExternalUser",
        () => <Checkbox
            key="groupCanBeExternalUser"
            value={dataGroup.canBeExternalUser}
            onChange={canBeExternalUserChange}
        />);

    renderPair(elements,
        window.captions.ParticipationInPlanning,
        "participationInPlanning",
        () => <Checkbox
            key="groupParticipationInPlanning"
            value={dataGroup.participationInPlanning}
            onChange={changeParticipationInPlanning}
        />);

    renderPair(elements,
        window.captions.AddUser,
        "addUser",
        () => <Button
            icon="add"
            key="addUserInGroup"
            title={window.captions.AddUser}
            onClick={() => addNewUserLocal()}>
            {window.captions.User}
        </Button>);

    renderPair(elements,
        window.captions.Color,
        "color",
        () => <ColorPickerControl
            key="groupColor"
            view={"combo"}
            value={dataGroup.color}
            changeValue={colorChanged}
        />);

    renderPair(elements,
        window.captions.Comments,
        "comments",
        () => <MultilineTextArea
            id="groupComments"
            changeText={commentsChanged}
            text={dataGroup.comments}
        />);

    return <div className="dm-container">
        {elements}
    </div>;

    function mountProperties() {
        if (!dataGroup || !rules || !groupList) {
            setSelectedRule(null);
            setParentGroup(null);
            setPending(window.captions.LoadDataError);
            setError(null);
            return;
        }

        var selectedRule = rules.find(x => x.id === dataGroup.rule);
        var parentGroup = dataGroup.parentId ? groupList.find(x => x.id === dataGroup.ParentId) : null;

        setSelectedRule(selectedRule);
        setParentGroup(parentGroup ? parentGroup : null);
        setPending(isGroupTaskListLoaded ? null : window.captions.LoadingData);
        setError(null);

        if (isGroupTaskListLoaded === false) {
            fetchGet(`${window.constants.getTaskGroupFieldList}/`,
                (items) => {
                    var groupTask = items.find(x => x.id === dataGroup.taskGroupField);
                    setGroupTaskList(items);
                    setGroupTask(!groupTask ? null : groupTask);
                    setPending(null);
                    setIsGroupTaskListLoaded(true);
                },
                (ex) => {
                    setError(ex);
                    setPending(null);
                    setIsGroupTaskListLoaded(true);
                }
            );
        }
    }

    function changeGroupName(e) {
        const userUi = getUiLang();
        let language = getUiSources().find(i => i.name === userUi);
        let defaulteItem = e.selectLang.items.find(i => i.id === language.id);
        var group = { ...dataGroup, langItem: e.selectLang };
        if (defaulteItem.name != dataGroup.name) {
            group.name = defaulteItem.name;
        }

        groupChanged(group);
    }

    function selectGroupRule(e) {
        setSelectedRule(e.value);
        groupChanged({ ...dataGroup, rule: e.value.id });
    }

    function parentGroupChange(e) {
        setParentGroup(e.value);
        groupChanged({ ...dataGroup, parentId: e.value.id });
    }

    function selectGroupTask(e) {
        setGroupTask(e.value);
        groupChanged({ ...dataGroup, taskGroupField: e.value.id });
    }

    function groupIntervalsChange(e) {
        groupChanged({ ...dataGroup, groupIntervals: e.value });
    }

    function canBeExternalUserChange(e) {
        groupChanged({ ...dataGroup, canBeExternalUser: e.value });
    }

    function changeParticipationInPlanning(e) {
        groupChanged({ ...dataGroup, participationInPlanning: e.value });
    }

    function addNewUserLocal() {
        var users = [];
        groupList.forEach((i) => users = users.concat(i.items));
        if (addNewUser)
            addNewUser(users, dataGroup);
    }

    function colorChanged(e) {
        groupChanged({ ...dataGroup, color: e.value });
    }

    function commentsChanged(e) {
        groupChanged({
            ...dataGroup,
            comments: e.value,
            isUpdated: true
        });
    }
}