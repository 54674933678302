import React, { useState, useEffect } from 'react';
import ViewEditTreeView from "../../../../../components/controls/viewEditTreeView.jsx";

export default function RuleFieldsAccess({ dataRule, fieldsPermissions, ruleChanged }) {
    const [items, setItems] = useState([]);

    useEffect(() => mountProperties(), [dataRule.id, fieldsPermissions]);

    return <div className="dm-container dm-no-padding">
        <ViewEditTreeView
            key="fieldsPermissionsTreeView"
            dataTree={items}
            onCheckChange={onCheckChange}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !fieldsPermissions) {
            setItems([]);
            return;
        }

        let itemsLocal = Array.isArray(fieldsPermissions) && fieldsPermissions.length ? fieldsPermissions : [];
        itemsLocal.forEach(i => setParents(i));
        itemsLocal.forEach(i => {
            i.parent = 4;
            if (i.items)
                i.items.forEach(x => x.parent = 3);
            setAccess(i);
        });

        setItems(itemsLocal);
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }

    function setAccess(item) {
        const fieldAccess = dataRule.fieldsAccess.find((element) => element.id == item.id && element.parent == item.parent);
        if (fieldAccess) {
            item.viewing = fieldAccess.isView;
            item.editing = fieldAccess.isEdit;

            if (fieldAccess.isEdit && item.items)
                item.items.forEach(i => i.editing = true);
            if (fieldAccess.isView && item.items)
                item.items.forEach(i => i.viewing = true);
        }

        if (item.items)
            item.items.forEach(i => setAccess(i));

        if (item.items && item.items.length > 0) {
            if (item.items.some((item) => item.viewing)) {
                if (item.items.every((item) => item.viewing)) {
                    item.viewing = true;
                } else {
                    item.viewing = null;
                }
            }
            else {
                item.viewing = false;
            }


            if (item.items.some((item) => item.editing)) {
                if (item.items.every((item) => item.editing)) {
                    item.editing = true;
                } else {
                    item.editing = null;
                }
            }
            else {
                item.editing = false;
            }
        }
    }

    function onCheckChange(selectedItems) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //stagesForAction: selected,
                isUpdated: true
            });
    }
}