import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FileHelper from "../../../../../utils/fileHelper.jsx";
import { headersFile } from "../../../../../utils/requestsHelper.jsx";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FileIcon, defaultStyles } from "react-file-icon";
import DropDownTreeView from "../../../../../components/controls/dropDownTreeView.jsx";
import AudioPlayer from "../../../../../components/audioPlayer.jsx";
import VideoPlayer from "../../../../../components/videoPlayer.jsx";

export default function DocumentsTab({ dataUser, documentTypes, defaultTypeId, userChanged }) {
    const [preview, setPreview] = useState(null);
    const user = useSelector(state => state.header.model);

    useEffect(() => mountProperties(), [dataUser.id, documentTypes, defaultTypeId]);

    let overContent = null;
    if (preview) {
        var filePath = `${window.constants.fileUserDocument}/${user.id}?fileName=${preview.fileName}`;
        var previewControl = "";
        if (preview.contentType === 1) { //Image
            previewControl = <img src={filePath} alt={preview.name} width="100%" />;
        } else if (preview.contentType === 2) { //Audio
            previewControl = <AudioPlayer url={filePath} />;
        } else if (preview.contentType === 3) { //Video
            previewControl = <VideoPlayer url={filePath} />;
        }

        overContent = <Dialog title={window.captions.Preview} onClose={() => setPreview(null)} width={1000}>
            <div className="dm-container">
                {previewControl}
            </div>
            <DialogActionsBar>
                <Button className="k-success-colored"
                    onClick={() => FileHelper.getUserDocument(preview.fileName)}>
                    {window.captions.Download}
                </Button>
                <Button themeColor="primary"
                    onClick={() => setPreview(null)}>
                    {window.captions.Close}
                </Button>
            </DialogActionsBar>
        </Dialog>;
    }

    var documents = [];
    dataUser.documents.forEach((item, g) => {
        const fileName = item.fileName.substring(item.fileName.lastIndexOf("/") + 1);
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
        documents.push(
            <div key={`DocumentName${g}`} className="dm-size-100">
                <div className="dm-wrapper" style={{ marginBottom: "10px" }}>
                    <span className="dm-icon" style={{ margin: "5px 10px 0px 10px" }}>
                        <FileIcon extension={fileType} {...defaultStyles[fileType]} />
                    </span>
                    <Input placeholder={window.captions.Caption}
                        className="dm-wrapper-content"
                        item={item.index}
                        onChange={(e) => documentName(e)}
                        value={item.name}
                        style={{ width: "calc(50% - 102px)" }} />
                    <DropDownTreeView
                        item={item.index}
                        treeData={documentTypes}
                        selectedId={item.documentType}
                        selectionChanged={changeType}
                        style={{
                            width: "calc(50% - 102px)",
                            marginRight: "5px"
                        }} />
                    <Button icon="eye"
                        onClick={() => previewFile(item)}
                        style={{
                            height: "40px",
                            marginRight: "5px"
                        }} />
                    <Button icon="delete"
                        onClick={() => documentRemove(item)}
                        style={{
                            height: "40px"
                        }} />
                </div>
            </div>
        );
    });

    return <div className="dm-container dm-no-padding">
        <div style={{ marginBottom: "10px" }} >
            <Upload
                batch={false}
                multiple={true}
                defaultFiles={[]}
                onStatusChange={(e) => documentUpload(e)}
                saveHeaders={headersFile()}
                removeHeaders={headersFile()}
                saveUrl={window.constants.fileUpload}
                removeUrl={window.constants.fileRemove}
            />
        </div>
        {documents}
        {overContent}
    </div>;

    function mountProperties() {
        if (!dataUser || !documentTypes) {
            return;
        }

        documentsReindex();
    }

    function documentName(e) {
        const index = e.target.props.item;
        const name = e.target.value;
        const documents = dataUser.documents;
        const document = documents[index];
        if (!document)
            return;

        if (document.name === name)
            return;

        document.name = name;
        if (userChanged)
            userChanged(dataUser);
    }

    function documentsReindex() {
        if (!dataUser.documents)
            return;

        for (let i = 0; i < dataUser.documents.length; i++) {
            dataUser.documents[i].index = i;
        }
    }

    function changeType(e) {
        var index = e.target.props.item;
        var typeId = e.value;
        var document = dataUser.documents[index];
        if (!document)
            return;

        if (document.documentType === typeId)
            return;

        document.documentType = typeId;
        if (userChanged)
            userChanged(dataUser);
    }

    function documentRemove(document) {
        const documents = dataUser.documents;
        if (!document)
            return;

        if (!confirm(window.captions.DeleteItemConfirm))
            return;

        if (document.id > 0)
            dataUser.removedDocuments.push(document.id);

        documents.splice(document.index, 1);
        documentsReindex();
        if (userChanged)
            userChanged(dataUser);
    }

    function getNewDocumentName(documents) {
        if (!documents)
            return window.captions.NewDocument;

        let index = 1;
        let name = window.captions.NewDocument;
        while (documents.some(x => x.name == name)) {
            index++;
            name = `${window.captions.NewDocument} ${index}`;
        }

        return name;
    }

    function documentUpload(e) {
        if (!e.response)
            return;

        const documents = dataUser.documents;
        const files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            documents.push({
                entityId: dataUser.id,
                fileName: file.fileName,
                fileType: file.fileType,
                documentType: defaultTypeId,
                id: 0,
                index: documents.length,
                name: getNewDocumentName(documents),
            });
        }
        if (userChanged)
            userChanged(dataUser);
    }

    function previewFile(item) {
        if (item.contentType === 0 || item.contentType === 4)
            FileHelper.getUserDocument(item.fileName);
        else
            setPreview(item);
    }
}