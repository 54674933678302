import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { varSourceType, varType } from "../../../../../utils/systemConstants.jsx";
import TreeViewCheck from "../../../../../components/controls/treeViewCheck.jsx";
import { useLocation } from "react-router-dom";
import { fetchPost } from '../../../../../utils/requestsHelper.jsx';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { TextArea } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import MessageMask from "../../../../../components/messageMask.jsx";
import { Button } from "@progress/kendo-react-buttons";

export function SourceFieldsTab({ variable, source, names, onEdit }) {
    const location = useLocation();
    const sources = WizardFunction.getVariableSourceTypes(source);
    const sourcesfields = WizardFunction.getVariableSourceFieldsTypes();

    const getSourceItemsRequest = (newData) => {
        if (!newData) {
            if (variable.item.id === 0) {
                var sourceType = variable.item.sourceType === 0
                    ? sources[0]
                    : variable.item.sourceType

                var fieldSourceType = variable.item.fieldSourceType === 0
                    ? sourcesfields[0]
                    : variable.item.fieldSourceType

                return {
                    sourceType: sourceType.id,
                    fieldSourceType: fieldSourceType.id,
                    selected: variable.item.sourceItems,
                    isReport: isReports
                }
            }
            return {
                sourceType: variable.item.sourceType,
                fieldSourceType: variable.item.fieldSourceType,
                selected: variable.item.sourceItems,
                isReport: isReports
            }
        }
        else {
            return {
                sourceType: newData.sourceType,
                fieldSourceType: newData.fieldSourceType,
                selected: newData.sourceItems,
                isReport: isReports
            }
        }
    }

    const getUpdateFiltersRequest = (variable) => {
        return {
            item: variable,
            isReport: isReports
        }
    }

    const [currentVariable, setCurrentVariable] = useState();
    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const isReports = location.pathname.includes("reports");

    useEffect(() => {
        setCurrentVariable(variable.item);
        if (variable.item.type === 0) {
            changeSourceItems(getSourceItemsRequest(null));
        }
    }, [variable.item]);

    if (!currentVariable || isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const changeTypeHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.sourceType = e.value.id;
        newVariable.sourceItems = [];
        changeSourceItems(getSourceItemsRequest(newVariable));
        changeVariableFilters(getUpdateFiltersRequest(newVariable));
    };

    const changeSourceFieldHandler = (e) => {
        var newVariable = currentVariable;
        newVariable.fieldSourceType = e.value.id;

        changeSourceItems(getSourceItemsRequest(newVariable));
        changeVariableFilters(getUpdateFiltersRequest(newVariable));
    };

    const changeScriptHandler = (e) => {
        var newVariable = currentVariable;
        if (currentVariable.type === varType.sql)
            newVariable.script = e.value;
        else if (currentVariable.type === varType.calculation)
            newVariable.totalShowName = e.value;

        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const onCheckFieldHandler = (event) => {
        var newVariable = currentVariable;
        var newSelectedFields = selectedFields;

        const exists = newSelectedFields.some(item => item.id === event.id);

        if (exists) {
            newSelectedFields = newSelectedFields.filter(item => item.id !== event.id);
        } else {
            if (event.items && event.items.length > 0) {
                var fieldItems = findSelectedItems(event.items);
                fieldItems.map((item) => {
                    newSelectedFields.push(item);
                });
            }
            else {
                newSelectedFields.push(event);
            }
        }

        newSelectedFields = WizardFunction.removeDuplicatesById(newSelectedFields);
        newVariable.sourceItems = newSelectedFields.map((field) => { return field.id; });

        onEdit({
            index: variable.index,
            item: newVariable
        });
        setSelectedFields(newSelectedFields);
    };

    var content = [];
    const style = { width: "100%" };

    if (currentVariable.type === 0) {
        renderPair(content,
            window.captions.Source,
            "VariableSourceType",
            () => <DropDownList style={style}
                data={sources}
                textField="text"
                dataItemKey="id"
                onChange={changeTypeHandler}
                value={currentVariable.fieldSourceType === 0
                    ? sources[0]
                    : sources.find(source => source.id === currentVariable.sourceType) } />
        );

        if (currentVariable.sourceType === varSourceType.clientField ||
            currentVariable.sourceType === varSourceType.collateralsOwners ||
            currentVariable.sourceType === varSourceType.linkedPersons) {
            renderPair(content,
                window.captions.Fields,
                "VariableSourceFields",
                () => <DropDownList style={style}
                    data={sourcesfields}
                    textField="text"
                    dataItemKey="id"
                    onChange={changeSourceFieldHandler}
                    value={currentVariable.fieldSourceType === 0
                        ? sourcesfields[0]
                        : sourcesfields.find(field => field.id === currentVariable.fieldSourceType) } />
            );
        }

        renderPair(content,
            window.captions.Fields,
            "VariableFields",
            () => <TreeViewCheck
                scrollHeight="500px"
                dataTree={fields}
                isCheckedField="isSelected"
                onCheckChange={onCheckFieldHandler}
            />
        );
    }
    if (currentVariable.type === 1 || currentVariable.type === 2) {
        renderPair(content,
            "",
            "ScriptAreaContentKey",
            () => <TextArea key="ScriptAreaContent"
                value={currentVariable.type === varType.sql
                    ? currentVariable.script
                    : currentVariable.type === varType.calculation
                        ? currentVariable.totalShowName
                        : "" }
                onChange={changeScriptHandler}
                rows={25} />,
            true);

        if ((currentVariable.type === 1 && names && names.length > 0) || currentVariable.type === 2) {
            renderPair(content,
                "",
                "ScriptAreaTemplateKey",
                () => <PanelBar key={`PanelBarTemplates${currentVariable.id}`} className="k-panelbar dm-m-bottom">
                    <PanelBarItem key="templatesItem"
                        title={window.captions.MergingVariables}
                        style={{ maxHeight: "500px", overflow: "hidden" }}>
                        <div className="k-m-1" style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "8px",
                            maxHeight: "300px",
                            overflow: "auto"
                        }}>
                            {currentVariable.type === 1
                                ? WizardFunction.getVariableNamesFormat(names, currentVariable.id)
                                : WizardFunction.getVariableSqlTemplateFormat()}
                        </div>
                    </PanelBarItem>
                </PanelBar>,
                true);
        }
    }

    return <div className="k-m-1">
        {content}
        {error
            ? <Dialog width={400}>
                <MessageMask inline error={error} />
                <DialogActionsBar>
                    <Button onClick={() => { setError(null) }}>{window.captions.Close}</Button>
                </DialogActionsBar>
            </Dialog>
            : null }
    </div>;

    function changeSourceItems(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.retrieveSourceItems}`,
            request,
            model => {
                if (model) {
                    setFields(model);
                    setSelectedFields(findSelectedItems(model));
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function changeVariableFilters(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.updateFilters}`,
            request,
            model => {
                if (model) {
                    setCurrentVariable(model);
                    onEdit({
                        index: variable.index,
                        item: model
                    });
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }

    function findSelectedItems(fields) {
        let selectedItems = [];

        for (let field of fields) {
            if (field.isSelected === true)
                selectedItems.push(field);

            if (field.items && field.items.length > 0)
                selectedItems = selectedItems.concat(findSelectedItems(field.items));
        }

        return selectedItems;
    }
}