import React from 'react';
import {
	exportFilterTypes,
	entitySources,
	fieldTypes,
	varSourceType,
	varConvertCode,
	varType,
	varDataArrayType,
	spellingGender,
	varFormulaType,
	baseFieldSourceToVarSourceType,
	varDateDivider,
	pdfVersion
} from "../../../utils/systemConstants.jsx";

export default {
	getSources() {
		let sources = [];

		sources.push({ id: entitySources.client, name: this.firstCharUpperCase(window.captions.Client) });
		sources.push({ id: entitySources.loan, name: this.firstCharUpperCase(window.captions.Loan) });
		sources.push({ id: entitySources.collateral, name: this.firstCharUpperCase(window.captions.Collateral) });
		sources.push({ id: entitySources.process, name: this.firstCharUpperCase(window.captions.Process) });
		return sources;
	},
	getSelectedSource(sources, id) {
		let item = sources.find(item => item.id === id);
		if (item)
			return item;
		else
			return sources[0];
	},
	getDefaultAskedFields(item) {
		let source = item.source;
		let isLoan = source === entitySources.loan;
		let isProcess = source === entitySources.process;
		let isLoanOrClient = [entitySources.loan, entitySources.client].includes(source);
		let hasTask = [entitySources.loan, entitySources.client, entitySources.collateral, entitySources.process].includes(source);

		let sources = [];

		if ((!item.isSql && item.isSql === false) && item.hasActual)
			sources.push({ value: exportFilterTypes.actualDate, text: window.captions.QueryDateRanges });

		if (isLoan && item.isMultipleProject)
			sources.push({ value: exportFilterTypes.project, text: window.captions.Project });

		if (!isProcess)
			sources.push({ value: exportFilterTypes.state, text: window.captions.StageAndState });

		if (!item.isSql)
			sources.push({ value: exportFilterTypes.executor, text: window.captions.Executor });

		if (isLoanOrClient)
			sources.push({ value: exportFilterTypes.contactAuthor, text: window.captions.ContactAuthor });

		if (hasTask)
			sources.push({ value: exportFilterTypes.taskExecutor, text: window.captions.TaskExecutor });

		if (isLoan)
			sources.push({ value: exportFilterTypes.dateLoan, text: window.captionsDynamic.ActualLoanDate });

		if (isLoanOrClient) {
			sources.push({ value: exportFilterTypes.dateContact, text: window.captions.ContactDate });
			sources.push({ value: exportFilterTypes.dateNextContact, text: window.captions.NextContactDate });
			sources.push({ value: exportFilterTypes.datePromiss, text: window.captions.PromissPaymentDate });
		}

		if (isLoan) {
			sources.push({ value: exportFilterTypes.datePayment, text: window.captions.ActualPaymentDate });
			sources.push({ value: exportFilterTypes.datePromiseScheduler, text: window.captions.PromiseSchedulerCreationDate });
		}

		if (hasTask) {
			sources.push({ value: exportFilterTypes.dateTaskCreated, text: window.captions.TaskCreated });
			sources.push({ value: exportFilterTypes.dateTaskFinished, text: window.captions.TaskFinished });
			sources.push({ value: exportFilterTypes.dateProcessAction, text: window.captions.ProcessActionDate });
		}

		if (isLoanOrClient) {
			sources.push({ value: exportFilterTypes.dateTelephonyResult, text: window.captions.DateTelephonyResult });
			sources.push({ value: exportFilterTypes.dateSmsCreated, text: window.captions.DateSmsCreated });
			sources.push({ value: exportFilterTypes.dateEmailCreated, text: window.captions.DateEmailCreated });
		}

		sources.push({ value: exportFilterTypes.dateDocumentCreated, text: window.captions.DateDocumentCreated });

		if (item.isSql)
			sources.push({ value: exportFilterTypes.customFields, text: window.captions.FieldFromCard });
			
		return sources;
	},
	firstCharUpperCase(item) {
		return item.charAt(0).toUpperCase() + item.slice(1);
	},
	getOrienrations(item) {
		var horizontal = { id: 0, name: this.firstCharUpperCase(window.captions.HeaderHorizontal) };
		var vertical = { id: 1, name: this.firstCharUpperCase(window.captions.HeaderVertical) };
		var orientations = [horizontal, vertical];

		if (item === undefined) {
			return orientations;
		}
		else {
			if (item == 1)
				return vertical;
			if (item == 0)
				return horizontal;

			return horizontal;
		}
	},
	getFilteredFields(selectedFields, items) {
		if (!selectedFields)
			return [];

		return items.map((item) => ({
			...item,
			isSelected: selectedFields.includes(item.id)
		}));
	},
	getSqlTemplateFields(fields) {
		var templates = [];
		templates.push({ column: "@AuthorId", name: window.captions.ReportAuthor });
		templates.push({ column: "@ContactAuthor", name: `${window.captions.ContactAuthor} (Id)` });
		templates.push({ column: "@ProjectsIdList", name: `${window.captions.Project} (Id)` });
		templates.push({ column: "@StatesIdList", name: `${window.captions.StageAndState} (Id)` });
		templates.push({ column: "@EntityIdList", name: `${window.captions.Selected} (Id)` });
		templates.push({ column: "@LoansAccess", name: `${window.captions.AccessParams} (Id1, Id2)` });
		templates.push({ column: "@DateLoanFrom", name: `${window.captionsDynamic.ActualLoanDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateLoanTo", name: `${window.captionsDynamic.ActualLoanDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DateContactFrom", name: `${window.captions.ContactDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateContactTo", name: `${window.captions.ContactDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DateNextContactFrom", name: `${window.captions.NextContactDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateNextContactTo", name: `${window.captions.NextContactDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DatePromissFrom", name: `${window.captions.PromissPaymentDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DatePromissTo", name: `${window.captions.PromissPaymentDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DateDeclaredFrom", name: `${window.captions.DeclaredDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateDeclaredTo", name: `${window.captions.DeclaredDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DatePaymentFrom", name: `${window.captions.ActualPaymentDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DatePaymentTo", name: `${window.captions.ActualPaymentDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DateTaskCreatedFrom", name: `${window.captions.TaskCreated} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateTaskCreatedTo", name: `${window.captions.TaskCreated} ${window.captions.WordTo}` });
		templates.push({ column: "@DateTaskFinishedFrom", name: `${window.captions.TaskFinished} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateTaskFinishedTo", name: `${window.captions.TaskFinished} ${window.captions.WordTo}` });
		templates.push({ column: "@DateProcessActionFrom", name: `${window.captions.ProcessActionDate} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateProcessActionTo", name: `${window.captions.ProcessActionDate} ${window.captions.WordTo}` });
		templates.push({ column: "@DateTelephonyResultFrom", name: `${window.captions.DateTelephonyResult} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateTelephonyResultTo", name: `${window.captions.DateTelephonyResult} ${window.captions.WordTo}` });
		templates.push({ column: "@DateSmsCreatedFrom", name: `${window.captions.DateSmsCreated} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateSmsCreatedTo", name: `${window.captions.DateSmsCreated} ${window.captions.WordTo}` });
		templates.push({ column: "@DateEmailCreatedFrom", name: `${window.captions.DateEmailCreated} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateEmailCreatedTo", name: `${window.captions.DateEmailCreated} ${window.captions.WordTo}` });
		templates.push({ column: "@DateDocumentCreatedFrom", name: `${window.captions.DateDocumentCreated} ${window.captions.WordFrom}` });
		templates.push({ column: "@DateDocumentCreatedTo", name: `${window.captions.DateDocumentCreated} ${window.captions.WordTo}` });
		templates.push({ column: "@rowFrom", name: `${window.captions.Row} ${window.captions.WordFrom}` });
		templates.push({ column: "@rowTo", name: `${window.captions.Row} ${window.captions.WordTo}` });

		fields.map((field) => {
			if (field.type === fieldTypes.extendedDictionary ||
				field.type === fieldTypes.multiExtendedDictionary ||
				field.type === fieldTypes.multiSimpleDictionary ||
				field.type === fieldTypes.simpleDictionary) {
				templates.push({ column: `@${field.columnName}`, name: `${field.name} (Id)` });
			}
			else if (field.type == fieldTypes.valueArray) {
				templates.push({ column: `@${field.columnName}`, name: `${field.name} (Name)` });
			}
			else if (field.type == fieldTypes.date ||
				field.type == fieldTypes.dateTime ||
				field.type == fieldTypes.time) {
				templates.push({ column: `@${field.columnName}From`, name: `${field.name} ${window.captions.WordFrom}` });
				templates.push({ column: `@${field.columnName}To`, name: `${field.name} ${window.captions.WordTo}` });
			}
			else if (field.type == fieldTypes.integer ||
				field.type == fieldTypes.double) {
				templates.push({ column: `@${field.columnName}From`, name: `${field.name} ${window.captions.WordFrom}` });
				templates.push({ column: `@${field.columnName}To`, name: `${field.name} ${window.captions.WordTo}` });
			}
			else {
				templates.push({ column: `@${field.columnName}`, name: field.name });
			}
		});

		return templates.map((template) => {
			return <span key={`Key_${template.column}`} style={{
				border: "1px solid #dcdcdc",
				padding: "2px",
				borderRadius: "4px",
				boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
			}}><b>{template.column}</b> - {template.name}</span>
		});
	},
	getDefaultDateAffects(source) {
		let sources = [];

		if (source === entitySources.loan) {
			sources.push({ value: exportFilterTypes.dateLoan, text: window.captionsDynamic.ActualLoanDate });
			sources.push({ value: exportFilterTypes.dateContact, text: window.captions.ContactDate });
			sources.push({ value: exportFilterTypes.dateNextContact, text: window.captions.NextContactDate });
			sources.push({ value: exportFilterTypes.datePromiss, text: window.captions.PromissPaymentDate });
			sources.push({ value: exportFilterTypes.datePayment, text: window.captions.ActualPaymentDate });
			sources.push({ value: exportFilterTypes.datePromiseScheduler, text: window.captions.PromiseSchedulerCreationDate });
			sources.push({ value: exportFilterTypes.dateTaskCreated, text: window.captions.TaskCreated });
			sources.push({ value: exportFilterTypes.dateTaskFinished, text: window.captions.TaskFinished });
			sources.push({ value: exportFilterTypes.dateProcessAction, text: window.captions.ProcessActionDate });
			sources.push({ value: exportFilterTypes.dateTelephonyResult, text: window.captions.DateTelephonyResult });
			sources.push({ value: exportFilterTypes.dateUserSchedule, text: window.captions.DateUserSchedule });
			sources.push({ value: exportFilterTypes.dateSmsCreated, text: window.captions.DateSmsCreated });
			sources.push({ value: exportFilterTypes.dateEmailCreated, text: window.captions.DateEmailCreated });
			sources.push({ value: exportFilterTypes.dateDocumentCreated, text: window.captions.DateDocumentCreated });
		}
		else if (source === entitySources.client) {
			sources.push({ value: exportFilterTypes.dateContact, text: window.captions.ContactDate });
			sources.push({ value: exportFilterTypes.dateNextContact, text: window.captions.NextContactDate });
			sources.push({ value: exportFilterTypes.datePromiss, text: window.captions.PromissPaymentDate });
			sources.push({ value: exportFilterTypes.dateTaskCreated, text: window.captions.TaskCreated });
			sources.push({ value: exportFilterTypes.dateTaskFinished, text: window.captions.TaskFinished });
			sources.push({ value: exportFilterTypes.dateProcessAction, text: window.captions.ProcessActionDate });
			sources.push({ value: exportFilterTypes.dateTelephonyResult, text: window.captions.DateTelephonyResult });
			sources.push({ value: exportFilterTypes.dateSmsCreated, text: window.captions.DateSmsCreated });
			sources.push({ value: exportFilterTypes.dateEmailCreated, text: window.captions.DateEmailCreated });
			sources.push({ value: exportFilterTypes.dateDocumentCreated, text: window.captions.DateDocumentCreated });
		}
		else if (source === entitySources.collateral) {
			sources.push({ value: exportFilterTypes.dateContact, text: window.captions.ContactDate });
			sources.push({ value: exportFilterTypes.dateNextContact, text: window.captions.NextContactDate });
			sources.push({ value: exportFilterTypes.dateTaskCreated, text: window.captions.TaskCreated });
			sources.push({ value: exportFilterTypes.dateTaskFinished, text: window.captions.TaskFinished });
			sources.push({ value: exportFilterTypes.dateProcessAction, text: window.captions.ProcessActionDate });
			sources.push({ value: exportFilterTypes.dateDocumentCreated, text: window.captions.DateDocumentCreated });
		}
		else if (source === entitySources.process) {
			sources.push({ value: exportFilterTypes.dateTaskCreated, text: window.captions.TaskCreated });
			sources.push({ value: exportFilterTypes.dateTaskFinished, text: window.captions.TaskFinished });
			sources.push({ value: exportFilterTypes.dateProcessAction, text: window.captions.ProcessActionDate });
			sources.push({ value: exportFilterTypes.dateDocumentCreated, text: window.captions.DateDocumentCreated });
		}

		return sources;
	},
	getDefaultLinkEntities(source) {
		let entities = [];

		if (source == entitySources.loan) {
			entities.push({ value: varSourceType.history, text: window.captions.Actions });
			entities.push({ value: varSourceType.tasks, text: window.captions.Tasks });
			entities.push({ value: varSourceType.linkedPersons, text: window.captions.LinkedPersons });
			entities.push({ value: varSourceType.collaterals, text: window.captionsDynamic.Collaterals });
			entities.push({ value: varSourceType.collateralsOwners, text: window.captionsDynamic.CollateralOwners });
			entities.push({ value: varSourceType.process, text: window.captions.Process });
			entities.push({ value: varSourceType.payments, text: window.captions.Payments });
			entities.push({ value: varSourceType.telephony, text: window.captions.Telephony });
			entities.push({ value: varSourceType.promisePayment, text: window.captions.PromiseScheduler });
			entities.push({ value: varSourceType.smsHistory, text: window.captions.SmsHistory });
			entities.push({ value: varSourceType.emailHistory, text: window.captions.EmailHistory });
			entities.push({ value: varSourceType.documents, text: window.captions.Documents });
		}
		else if (source === entitySources.client) {
			entities.push({ value: varSourceType.history, text: window.captions.Actions });
			entities.push({ value: varSourceType.tasks, text: window.captions.Tasks });
			entities.push({ value: varSourceType.linkedPersons, text: window.captions.LinkedPersons });
			entities.push({ value: varSourceType.process, text: window.captions.Process });
			entities.push({ value: varSourceType.telephony, text: window.captions.Telephony });
			entities.push({ value: varSourceType.smsHistory, text: window.captions.SmsHistory });
			entities.push({ value: varSourceType.emailHistory, text: window.captions.EmailHistory });
			entities.push({ value: varSourceType.documents, text: window.captions.Documents });
		}
		else if (source === entitySources.collateral) {
			entities.push({ value: varSourceType.history, text: window.captions.Actions });
			entities.push({ value: varSourceType.tasks, text: window.captions.Tasks });
			entities.push({ value: varSourceType.process, text: window.captions.Process });
			entities.push({ value: varSourceType.documents, text: window.captions.Documents });
		}
		else if (source === entitySources.process) {
			entities.push({ value: varSourceType.tasks, text: window.captions.Tasks });
			entities.push({ value: varSourceType.process, text: window.captions.Process });
			entities.push({ value: varSourceType.documents, text: window.captions.Documents });
		}

		return entities;
	},
	getConvertCodes() {
		let codes = [];

		codes.push({ id: varConvertCode.none, text: window.captions.NotChosed });
		codes.push({ id: varConvertCode.barcode, text: window.captions.PrintBarcode });
		codes.push({ id: varConvertCode.qrCode, text: window.captions.PrintQrCode });

		return codes;
	},
	getVarTypes() {
		let types = [];

		types.push({ id: varType.dataView, text: window.captions.DataView });
		types.push({ id: varType.calculation, text: window.captions.Calculation });
		types.push({ id: varType.sql, text: window.captions.ScriptSql });

		return types;
	},
	getDataArrayTypes(isReport) {
		let types = [];

		if (isReport) {
			types.push({ id: varDataArrayType.line, text: window.captions.DataArrayLine });
			types.push({ id: varDataArrayType.singleLine, text: window.captions.DataArraySingleLine });
		}
		types.push({ id: varDataArrayType.merge, text: window.captions.DataArrayMarge });
		types.push({ id: varDataArrayType.mergeWithHeaders, text: window.captions.DataArrayMargeWithHeaders });

		if (!isReport) {
			types.push({ id: varDataArrayType.table, text: window.captions.DataArrayTable });
		}

		return types;
	},
	getPeriods() {
		let periods = [];

		periods.push({ id: 0, text: window.captions.DelayDontApplay });
		periods.push({ id: 1, text: window.captions.DelayDontForStart });
		periods.push({ id: 2, text: window.captions.DelayDontForEnd });

		return periods;
	},
	getLanguages(items) {
		let languages = [];

		items.map((item) => {
			languages.push({ value: item.id, label: item.name });
		});

		return languages;
	},
	getSpellingGeneralGender() {
		let generalGenders = [];

		generalGenders.push({ value: spellingGender.male, label: window.captions.GenderCountMale });
		generalGenders.push({ value: spellingGender.female, label: window.captions.GenderCountFemale });
		generalGenders.push({ value: spellingGender.neuter, label: window.captions.GenderCountAverage });
		generalGenders.push({ value: spellingGender.plural, label: window.captions.GenderCountMultiple });

		return generalGenders;
	},
	getSpellingTemplates() {
		let templates = [];

		templates.push({ column: "NI", name: window.captions.SpellNumberInteger });
		templates.push({ column: "NF", name: window.captions.SpellNumberFractional });
		templates.push({ column: "NN", name: window.captions.SpellNumberNumber });
		templates.push({ column: "UI", name: window.captions.SpellUnitInteger });
		templates.push({ column: "UF", name: window.captions.SpellUnitFractional });
		templates.push({ column: "WI", name: window.captions.SpellWorkInteger });
		templates.push({ column: "WF", name: window.captions.SpellWorkFractional });

		return templates.map((template) => {
			return <span key={`Key_${template.column}`} style={{
				border: "1px solid #dcdcdc",
				padding: "2px",
				borderRadius: "4px",
				boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
			}}><b>{template.column}</b> - {template.name}</span>
		});
	},
	getFormulaTypes() {
		let types = [];

		types.push({ id: varFormulaType.none, text: window.captions.Missed });
		types.push({ id: varFormulaType.count, text: window.captions.FormulaCount });
		types.push({ id: varFormulaType.first, text: window.captions.FormulaFirst });
		types.push({ id: varFormulaType.last, text: window.captions.FormulaLast });
		types.push({ id: varFormulaType.min, text: window.captions.FormulaMin });
		types.push({ id: varFormulaType.max, text: window.captions.FormulaMax });
		types.push({ id: varFormulaType.sum, text: window.captions.FormulaSum });
		types.push({ id: varFormulaType.avg, text: window.captions.FormulaAvg });

		types.sort((a, b) => b.text - a.text);

		return types;
	},
	getVariableSourceTypes(source) {
		let sources = [];

		if (source === entitySources.loan) {
			sources.push({ id: varSourceType.linkedPersons, text: window.captions.LinkedPersons });
			sources.push({ id: varSourceType.documents, text: window.captions.Documents });
			sources.push({ id: varSourceType.collaterals, text: window.captionsDynamic.Collaterals });
			sources.push({ id: varSourceType.collateralsOwners, text: window.captionsDynamic.CollateralOwners });
			sources.push({ id: varSourceType.history, text: window.captions.Actions });
			sources.push({ id: varSourceType.tasks, text: window.captions.Tasks });
			sources.push({ id: varSourceType.process, text: window.captions.Process });
			sources.push({ id: varSourceType.processAction, text: window.captions.ProcessAction });
			sources.push({ id: varSourceType.payments, text: window.captions.Payments });
			sources.push({ id: varSourceType.paymentPlan, text: window.captions.PaymentPlan });
			sources.push({ id: varSourceType.telephony, text: window.captions.Telephony });
			sources.push({ id: varSourceType.smsHistory, text: window.captions.SmsHistory });
			sources.push({ id: varSourceType.emailHistory, text: window.captions.EmailHistory });
			sources.push({ id: varSourceType.users, text: window.captions.Users });
			sources.push({ id: varSourceType.project, text: window.captions.Project });
			sources.push({ id: varSourceType.other, text: window.captions.Other });
			sources.push({ id: varSourceType.clientField, text: window.captions.Client });
			sources.push({ id: varSourceType.loanField, text: window.captionsDynamic.LoanSingleNominative });
			sources.push({ id: varSourceType.promisePayment, text: window.captions.PromiseScheduler });
			sources.push({ id: varSourceType.promiseScheduler, text: window.captions.LowLevelPaymentPromises });
		}
		else if (source === entitySources.client) {
			sources.push({ id: varSourceType.linkedPersons, text: window.captions.LinkedPersons });
			sources.push({ id: varSourceType.documents, text: window.captions.Documents });
			sources.push({ id: varSourceType.history, text: window.captions.Actions });
			sources.push({ id: varSourceType.tasks, text: window.captions.Tasks });
			sources.push({ id: varSourceType.process, text: window.captions.Process });
			sources.push({ id: varSourceType.processAction, text: window.captions.ProcessAction });
			sources.push({ id: varSourceType.telephony, text: window.captions.Telephony });
			sources.push({ id: varSourceType.smsHistory, text: window.captions.SmsHistory });
			sources.push({ id: varSourceType.emailHistory, text: window.captions.EmailHistory });
			sources.push({ id: varSourceType.other, text: window.captions.Other });
			sources.push({ id: varSourceType.clientField, text: window.captions.Client });
		}
		else if (source === entitySources.collateral) {
			sources.push({ id: varSourceType.documents, text: window.captions.Documents });
			sources.push({ id: varSourceType.history, text: window.captions.Actions });
			sources.push({ id: varSourceType.tasks, text: window.captions.Tasks });
			sources.push({ id: varSourceType.collaterals, text: window.captionsDynamic.Collaterals });
			sources.push({ id: varSourceType.collateralsOwners, text: window.captionsDynamic.CollateralsOwners });
			sources.push({ id: varSourceType.process, text: window.captions.Process });
			sources.push({ id: varSourceType.processAction, text: window.captions.ProcessAction });
			sources.push({ id: varSourceType.other, text: window.captions.Other });
		}
		else if (source === entitySources.process) {
			sources.push({ id: varSourceType.documents, text: window.captions.Documents });
			sources.push({ id: varSourceType.tasks, text: window.captions.Tasks });
			sources.push({ id: varSourceType.process, text: window.captions.Process });
			sources.push({ id: varSourceType.processAction, text: window.captions.ProcessAction });
			sources.push({ id: varSourceType.other, text: window.captions.Other });
		}

		sources.sort((a, b) => b.text - a.text);

		return sources;
	},
	getVariableSourceFieldsTypes() {
		let sources = [];

		sources.push({ id: varSourceType.field, text: window.captions.Fields });
		sources.push({ id: varSourceType.phone, text: window.captions.Phones });
		sources.push({ id: varSourceType.address, text: window.captions.Addresses });
		sources.push({ id: varSourceType.email, text: window.captions.Email });
		sources.push({ id: varSourceType.url, text: window.captions.Urls });
	
		sources.sort((a, b) => b.text - a.text);

		return sources;
	},
	getMergeCells() {
		let data = [];

		data.push({ value: true, label: window.captions.MargeCellsTrue });
		data.push({ value: false, label: window.captions.MargeCellsFalse });
	
		return data;
	},
	getDocumentFormats() {
		let data = [];

		data.push({ value: 0, label: window.captions.FileTypeWord });
		data.push({ value: 1, label: window.captions.FileTypePDF });
	
		return data;
	},
	getPdfVersions() {
		let data = [];

		data.push({ value: pdfVersion.default, label: window.captions.ByDefault });
		data.push({ value: pdfVersion.version1_0, label: "1.0" });
		data.push({ value: pdfVersion.version1_1, label: "1.1" });
		data.push({ value: pdfVersion.version1_2, label: "1.2" });
		data.push({ value: pdfVersion.version1_3, label: "1.3" });
		data.push({ value: pdfVersion.version1_4, label: "1.4" });
		data.push({ value: pdfVersion.version1_5, label: "1.5" });
		data.push({ value: pdfVersion.version1_6, label: "1.6" });
		data.push({ value: pdfVersion.version1_7, label: "1.7" });
	
		return data;
	},
	getTemplateDividers() {
		let data = [];

		data.push({ value: 0, label: window.captions.PageBrake });
		data.push({ value: 1, label: window.captions.Paragraph });
		data.push({ value: 2, label: window.captions.LineBrake });
	
		return data;
	},
	getAvailableVariableFields(fields) {
		const result = [];
		if (!fields || (field && field.length <= 0))
			return result;

		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];
			result.push(
				<span key={`Key_${field.columnName}_${field.id}`} style={{
					border: "1px solid #dcdcdc",
					padding: "2px",
					borderRadius: "4px",
					boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
				}}>
					<b>{(i + 1)}</b> - {field.name}
				</span>
			);
		}

		return result;
	},
	getVariableNamesFormat(names, id) {
		const result = [];
		var selectedItem = names.find((item) => item.id === id);
		var selectedList = names.filter((data) => data.groupId === selectedItem.groupId && data.id !== id);

		for (let i = 0; i < selectedList.length; i++) {
			const item = selectedList[i];
			result.push(
				<span key={`Key_${item.id}_${i}`} style={{
					border: "1px solid #dcdcdc",
					padding: "2px",
					borderRadius: "4px",
					boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
				}}>
					<b>v{(i + 1)}</b> - {item.name}
				</span>
			);
		}

		return result;
	},
	getVariableSqlTemplateFormat() {
		return <span key="Key_SqlTemplate" style={{
			border: "1px solid #dcdcdc",
			padding: "2px",
			borderRadius: "4px",
			boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
		}}>
			<b>@EntityIdList</b> - {window.captions.Selected} (Id)
		</span>;
	},
	getDateDividers() {
		let dividers = [];

		dividers.push({ id: varDateDivider.none, text: window.captions.DateDividersDontDivide });
		dividers.push({ id: varDateDivider.hour, text: window.captions.DateDividersHour });
		dividers.push({ id: varDateDivider.day, text: window.captions.Day.toLowerCase() });
		dividers.push({ id: varDateDivider.week, text: window.captions.Week.toLowerCase() });
		dividers.push({ id: varDateDivider.month, text: window.captions.Month.toLowerCase() });
		dividers.push({ id: varDateDivider.year, text: window.captions.Year.toLowerCase() });

		return dividers;
	},
	findEntitySourceByValue(value){
		const entry = Object.entries(entitySources).find(([key, val]) => val === value);
		return entry ? entry[0] : null;
	},
	sortObjectsById(arrayOfObjects, idOrder, idField = 'id') {
		if (!Array.isArray(arrayOfObjects) || !Array.isArray(idOrder)) {
			return arrayOfObjects;
		}

		return arrayOfObjects.sort((a, b) => {
			const indexA = idOrder.indexOf(a[idField]);
			const indexB = idOrder.indexOf(b[idField]);

			if (indexA === -1 || indexB === -1)
				return arrayOfObjects;

			return indexA - indexB;
		});
	},
	isVisibleConditionsTab(variable, source) {
		var parentSourceType = baseFieldSourceToVarSourceType(source)
		return parentSourceType == variable.type ||
			(variable.sourceType == varSourceType.clientField &&
				variable.fieldSourceType == varSourceType.field);
	},
	isVisibleEntityConditionsTab(variable, source) {
		if (variable.type === 0) {
		var parentSourceType = baseFieldSourceToVarSourceType(source)
			return parentSourceType == variable.type ||
				(variable.sourceType == varSourceType.clientField &&
					variable.fieldSourceType == varSourceType.field);
		}
		else
			return false;
		
	},
	isVisibleMergingTab(variable) {
		return variable.dataArray == varDataArrayType.mergeWithHeaders ||
			variable.dataArray == varDataArrayType.merge;
	},
	isVisibleConfigFilterTab(variable) {
		return variable.type === 0 && variable.filterSource.filterGroups.length > 0;
	},
	isVisibleContactFilterTab(variable) {
		return variable.filterSourceContact.filterGroups.length > 0;
	},
	isVisibleSortingTab(variable) {
		return variable.sourceItems.length > 1;
	},
	isVisibleFormulaTab(variable) {
		return this.isFieldType(variable);;
	},
	isFieldType(variable) {
		return variable.type === varSourceType.field;
	},
	createDeletedModel(id, type) {
		return {
			id: id,
			type: type
		};
	},
	removeItemById(arr, id) {
		function recursiveRemove(items) {
			return items.filter(item => {
				if (item.id === id) {
					return false;
				} else if (item.items && item.items.length > 0) {
					item.items = recursiveRemove(item.items);
				}
				return true;
			});
		}

		return recursiveRemove(arr);
	},
	findItemById(data, id, findParent = false) {
		function recursiveFind(items, parent = null) {
			for (let item of items) {
				if (item.id === id) {
					return findParent && parent ? parent : item;
				} else if (item.items && item.items.length > 0) {
					const found = recursiveFind(item.items, item);
					if (found) return found;
				}
			}
			return null;
		}
	
		return recursiveFind(data);
	},
	removeItemByObject(arr, targetObj) {
		function deepEqual(obj1, obj2) {
			return JSON.stringify(obj1) === JSON.stringify(obj2);
		}

		function recursiveRemove(items) {
			return items.filter(item => {
				if (deepEqual(item, targetObj)) {
					return false;
				} else if (item.items && item.items.length > 0) {
					item.items = recursiveRemove(item.items);
				}
				return true;
			});
		}

		return recursiveRemove(arr);
	},
	findObject(data, searchObject){
		return data.find(item =>
			Object.keys(searchObject).every(key => searchObject[key] === item[key])
		);
	},
	getAllowedUserModels(allowedUsers, newItem) {
		var list = allowedUsers.filter((item) => item.id !== 0);

		var findItem = list.find((item) => item.id === newItem.id);
		if (!findItem) {
			list.push({
				id: newItem.id,
				isEdit: newItem.isEdit,
				isView: newItem.isView,
				isSelected: newItem.isEdit || newItem.isView,
				linkId: 0,
				parent: newItem.parent
			});
		}
		else {
			findItem.isEdit = newItem.isEdit;
			findItem.isView = newItem.isView;
			findItem.isSelected = newItem.isEdit || newItem.isView;
		}

		if (newItem.items && newItem.items.length > 0) {
			newItem.items.map((item) => {
				var selectedItem = list.find((listItem) => listItem.id === item.id);
				if (!selectedItem) {
					list.push({
						id: item.id,
						isEdit: item.isEdit,
						isView: item.isView,
						isSelected: item.isEdit || item.isView,
						linkId: 0,
						parent: item.parent
					});
				}
				else {
					selectedItem.isEdit = item.isEdit;
					selectedItem.isView = item.isView;
					selectedItem.isSelected = item.isEdit || item.isView;
				}
			})
		}

		return list.filter((item) => item.isSelected == true);
	},
	setAllowedUserModels(users, allowedUsers) {
		var filterAllowedUsers = allowedUsers.filter((item) => item.id !== 0);

		users.map((group) => {
			var groupItem = this.findItemById(filterAllowedUsers, group.id);
			if (groupItem) {
				group.isEdit = groupItem.isEdit;
				group.isSelected = groupItem.isSelected;
				group.isView = groupItem.isView;
			}

			if (group.items && group.items.length > 0) {
				group.items.map((user) => {
					var userItem = this.findItemById(filterAllowedUsers, user.id);
					if (userItem) {
						user.isEdit = userItem.isEdit;
						user.isSelected = userItem.isSelected;
						user.isView = userItem.isView;
					}
				});
			}
		});

		return users;
	},
	removeDuplicatesById(arr) {
		const uniqueItems = arr.reduce((accumulator, current) => {
			if (!accumulator.some(item => item.id === current.id)) {
				accumulator.push(current);
			}
			return accumulator;
		}, []);

		return uniqueItems;
	}
};