import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

export default function AudioPlayer({ url }) {
    const [link, setLink] = useState(null);
    let player = useRef(null);

    useEffect(() => {
        setLink(url)
    }, [url]);

    return <ReactPlayer
        ref={player}
        width="100%"
        height="35px"
        playing={true}
        url={link}
        controls={true}
        onError={e => console.log("onError", e)} />;
}