import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import sharedUiRenderFunctions from "../../controls/sharedUiRenderFunctions.jsx";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";
import { fetchGet, fetchPost } from '../../../../../utils/requestsHelper.jsx';
import { Button, ChipList, Chip } from "@progress/kendo-react-buttons";
import { StackLayout } from '@progress/kendo-react-layout';
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import MessageMask from "../../../../../components/messageMask.jsx";
import { useLocation } from "react-router-dom";

export function EntityFieldConditionsTab({ variable, source, onEdit }) {
    const location = useLocation();
    const [currentVariable, setCurrentVariable] = useState();
    const [currentField, setCurrentField] = useState();
    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const isReports = location.pathname.includes("reports");

    useEffect(() => {
        setCurrentVariable(variable.item);
        if (!fields || fields.length === 0)
            getFields(source);
    }, [variable.item]);

    useEffect(() => {
        if (!currentField)
            setCurrentField(fields[0]);
    }, [currentField]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const getUpdateFiltersRequest = (variable) => {
        return {
            item: variable,
            isReport: isReports,
            isNewConditions: true,
            newData: currentField
        }
    }

    const changeFieldHandler = (e) => {
        setCurrentField(e.value)
    };

    const fieldsAddHandler = () => {
        getConditionsVariableFilters(getUpdateFiltersRequest(currentVariable))
    };

    const fieldsDeleteHandler = (e) => {
        if (!e.value)
            return;

        fields.push(e.value);
        fields.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

        setFields(fields)

        var newSelectedFields = currentVariable.filterCondition.selectedFilters.filter((item) => item.id != e.value.id);
        currentVariable.filterCondition.selectedFilters = newSelectedFields;

        var newFields = currentVariable.filterCondition.filterGroups[0].items.filter((item) => item.id != e.value.id);
        currentVariable.filterCondition.filterGroups[0].items = newFields;

        setCurrentVariable(currentVariable);
        setSelectedFields(selectedFields.filter((field) => field.id != e.value.id))

        onEdit({
            index: variable.index,
            item: currentVariable
        });
    };

    const handleFieldsChange = event => {
        setSelectedFields(event.value);
    };

    const onEditFilters = (item) => {
        var newVariable = currentVariable;
        newVariable.filterSource = item;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    const getChip = (item) => {
        return <Chip text={item.dataItem.name}
            value={item.dataItem}
            removable={true}
            removeIcon={'k-i-close-circle'} />;
    }

    if (!currentVariable || isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    const style = { width: "100%" };
    var content = [];
    var panelItems = [];
    var entity = WizardFunction.findEntitySourceByValue(source);

    if (selectedFields.length > 0)
        panelItems.push({
            title: window.captions.Fields,
            content: <ChipList
                style={{ margin: "5px" }}
                data={selectedFields}
                onDataChange={handleFieldsChange}
                chip={getChip}
                onChange={fieldsDeleteHandler}
                removable={true}
                selection="single"/>
        });

    if (selectedFields.length > 0) {
        const filterGroups = sharedUiRenderFunctions.renderForm(currentVariable.filterCondition, onEditFilters, entity, false);
        filterGroups.map((group) => {
            panelItems.push(group);
        });
    }

    renderPair(content,
        window.captions.ChoseFieldToAddConditions,
        "ChoseFieldToAddConditions",
        () => <StackLayout orientation={"horizontal"}>
            <DropDownList style={style}
                data={fields}
                textField="name"
                dataItemKey="id"
                onChange={changeFieldHandler}
                value={!currentField
                    ? fields[0]
                    : fields.find(field => field.id === currentField.id) ?? fields[0]}
            />
            <Button key="addItem"
                style={{ maxWidth: "40px" }}
                icon="add"
                onClick={fieldsAddHandler}
                className={`dm-m-left`}/>
        </StackLayout>
    );

    if (panelItems.length > 0)
        content.push(<PanelBar key={`PanelBarParameters${currentVariable.id}`} className="k-panelbar dm-m-bottom">
            {PanelBarUtils.mapItemsToComponents(panelItems)}
        </PanelBar>);

    return <div style={{ maxHeight: "750px", overflow: "auto" }}>
        {content}
        {error
            ? <Dialog width={400}>
                <MessageMask inline error={error} />
                <DialogActionsBar>
                    <Button onClick={() => { setError(null) }}>{window.captions.Close}</Button>
                </DialogActionsBar>
            </Dialog>
            : null}
    </div>

    function getFields(request) {
        setIsLoaded(true);
        fetchGet(`${window.constants.retrieveFields}/${request}`,
            model => {
                if (model) {
                    var selectedFilters = [];
                    variable.item.filterCondition.selectedFilters.map((item) => {
                        selectedFilters.push(item.id)
                    });

                    var selectedList = findSelectedItems(model, selectedFilters);

                    setSelectedFields(selectedList);

                    var fields = model.filter(field => !selectedList.some(item => item.id === field.id));
                    setFields(fields);
                    setCurrentField(fields[0]);

                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function getConditionsVariableFilters(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.updateFilters}`,
            request,
            model => {
                if (model) {
                    setCurrentVariable(model);

                    var newList = selectedFields;
                    newList.push(currentField);
                    setSelectedFields(newList);

                    setFields(fields.filter(field => !newList.some(item => item.id === field.id)));
                    setCurrentField(null);

                    onEdit({
                        index: variable.index,
                        item: model
                    });
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
    function findSelectedItems(fields, selectedFilters) {
        return fields.filter(field => selectedFilters.includes(field.id));
    }
}