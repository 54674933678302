import React, { useState } from "react";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import DropDownTreeView from "../../../components/controls/dropDownTreeView.jsx";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { headersFile } from "../../../utils/requestsHelper.jsx";

export function RenderProjectGroupGeneralTab({
                                                 selectedProject,
                                                 changeGeneralTabField,
                                                 onSearchKey,
                                                 addGroupingPayment,
                                                 uploadStatusChange,
                                                 remove
                                             }) {
    const controls = [];

    if (selectedProject.id > 0) {
        renderPair(controls,
            window.captions.Caption,
            "Caption",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "name")}
                          value={selectedProject.name}/>));
        renderPair(controls,
            window.captions.MinimumAmountPerOnePromise,
            "MinimumAmountPerOnePromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "minimumAmountPerOnePromise")}
                          value={selectedProject.minimumAmountPerOnePromise}/>));
        renderPair(controls,
            window.captions.MaximumPeriodSchedulerPromise,
            "MaximumPeriodSchedulerPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumPeriodBetweenPromise")}
                          onKeyDown={(e) => onSearchKey(e)}
                          value={selectedProject.maximumPeriodBetweenPromise}/>));
        renderPair(controls,
            window.captions.MaximumPeriodFirstPromiseDate,
            "MaximumPeriodFirstPromiseDate",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumPeriodSchedulerPromise")}
                          value={selectedProject.maximumPeriodSchedulerPromise}/>));

        renderPair(controls,
            window.captions.LastDayInMonthForFirstPromise,
            "LastDayInMonthForFirstPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "lastDayInMonthForFirstPromise")}
                          value={selectedProject.lastDayInMonthForFirstPromise}/>));

        renderPair(controls,
            window.captions.PromisePaymentPercent,
            "PromisePaymentPercent",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "promisePaymentPercent")}
                          value={selectedProject.promisePaymentPercent}/>));

        renderPair(controls,
            window.captions.OverdueMonthsCount,
            "OverdueMonthsCount",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "overdueOutcomeMonthsCount")}
                          value={selectedProject.overdueOutcomeMonthsCount}/>));

        renderPair(controls,
            window.captions.GroupPaymentsByThisField,
            "GroupPaymentsByThisFieldButton",
            () => (<Button onClick={() => addGroupingPayment()}>{window.captions.Add}</Button>));

        //todo
        let index = 0;
        for (let key in selectedProject.paymentGrouping) {
            const grouping = selectedProject.paymentGrouping[key];
            const selectField = grouping.fields.find(i => i.id === grouping.fieldId);
            index++;
            renderPair(controls,
                window.captions.GroupPaymentsByThisField,
                `GroupPaymentsByThisField${index}`,
                () => (<DropDownList textField="name"
                                     dataItemKey="id"
                                     data={grouping.fields}
                                     onChange={(e) => changeGeneralTabField(e, "maximumPeriodBetweenPromise")}
                                     value={selectField}/>));
        }

        renderPair(controls,
            window.captions.ConfigFieldsPayments,
            "ConfigFieldsPayments",
            () => (<DropDownList textField="name"
                                 dataItemKey="fieldId"
                                 data={selectedProject.fieldForAmountList}
                                 onChange={(e) => changeGeneralTabField(e, "fieldForAmountToPromise")}
                                 value={selectedProject.fieldForAmountToPromise}/>));

        renderPair(controls,
            window.captions.PromiceAmountPersentageIncrease,
            "PromiceAmountPersentageIncrease",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "promiceAmountPersentageIncrease")}
                          value={selectedProject.promiceAmountPersentageIncrease}/>));

        renderPair(controls,
            window.captions.PercentShowPromise,
            "PercentShowPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "percentShowPromise")}
                          value={selectedProject.percentShowPromise}/>));

        renderPair(controls,
            window.captions.ProjectConfigActionField,
            "ProjectConfigActionField",
            () => (<DropDownList textField="name"
                                 dataItemKey="fieldId"
                                 data={selectedProject.fieldForAmountList}
                                 onChange={(e) => changeGeneralTabField(e, "promisePaymentFieldInHistory")}
                                 value={selectedProject.promisePaymentFieldInHistory}/>));

        renderPair(controls,
            window.captions.ProjectConfigActionFieldFactor,
            "MaximumValuePaymentPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumValuePaymentPromise")}
                          value={selectedProject.maximumValuePaymentPromise}/>));

        renderPair(controls,
            window.captions.PaymentDistributeNoStatusCheck,
            "IsPaymentDistributeNoStatusCheck",
            () => (<Checkbox key="IsPaymentDistributeNoStatusCheck" value={selectedProject
                .isPaymentDistributeNoStatusCheck}
                             label={window.captions.PaymentDistributeNoStatusCheck}
                             onChange={(e) => changeGeneralTabField(e, "isPaymentDistributeNoStatusCheck")}/>));

        renderPair(controls,
            window.captions.DefaultState,
            "DefaultState",
            () => (<DropDownTreeView
                       id="TaskDialogType"
                       treeData={selectedProject.states}
                       selectedId={selectedProject.defaultState}
                       selectionChanged={(e) => changeGeneralTabField(e, "defaultState")}/>));

        renderPair(controls,
            window.captions.BackgroundImage,
            "BackgroundImage",
            () => (<Upload
                       batch={false}
                       multiple={true}
                       defaultFiles={[]}
                       onStatusChange={uploadStatusChange}
                       onRemove={remove}
                       saveHeaders={headersFile()}
                       removeHeaders={headersFile()}
                       saveUrl={window.constants.fileUpload}
                       removeUrl={window.constants.fileRemove}/>));

        renderPair(controls,
            window.captions.HideGroup,
            "HideGroup",
            () => (<Checkbox key="IsHidden" value={selectedProject.isHidden} label={window
                .captions.HideGroup} onChange={(e) => changeGeneralTabField(e, "isHidden")}/>));

    }
    return controls;
}