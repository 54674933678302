import React, { useState, useEffect } from 'react';
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import {
    TreeList,
    orderBy,
    mapTree,
    extendDataItem,
    TreeListToolbar,
    TreeListTextEditor,
    TreeListNumericEditor,
    TreeListDateEditor,
    TreeListBooleanEditor,
} from "@progress/kendo-react-treelist";

export default function RuleProjectStageAccess({ dataRule, projectGroups, programStages }) {
    const subItemsField = "items";
    const expandField = "expanded";
    const editField = "inEdit";

    const columns = [
        {
            field: "name",
            title: window.captions.Caption,
            expandable: true,
            width: "300px"
        },
        {
            field: "view",
            title: window.captions.View,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "editAddons",
            title: window.captions.AddonFieldsEdit,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "editFull",
            title: window.captions.FullFieldsEdit,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "historyView",
            title: window.captions.HistoryView,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "historyAdd",
            title: "DynamicLocalization.AddingActions",
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "stateSetting",
            title: window.captions.ChangeToThisState,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "stateChanging",
            title: window.captions.ChangeToOtherState,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "fullAccess",
            title: window.captions.FullAccess,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "fieldVerification",
            title: window.captions.FieldNeedVerification,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            field: "callRecords",
            title: window.captions.CallRecords,
            width: "100px",
            editCell: TreeListBooleanEditor,
        },
        {
            title: "Action",
            cell: cellWithEditing,
            width: "62px",
            locked: true,
        },
    ];

    const [data, setData] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [addable, setAddable] = useState([]);
    const [projectGroupsList, setProjectGroupsList] = useState([]);
    const [programStagesList, setProgramStagesList] = useState([]);
    const [editId, setEditId] = useState(null);

    useEffect(() => mountProperties(), [dataRule.id, projectGroups, programStages]);

    return <TreeList
        data={mapTree(data, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.id),
            })
        )}
        style={{
            maxHeight: '1010px',
            overflow: 'auto',
            width: '1020px'
        }}
        tableProps={{
            style: {
                width: '1900px'
            }
        }}
        editField={editField}
        expandField={expandField}
        subItemsField={subItemsField}
        onExpandChange={onExpandChange}
        columns={columns}
        onRowClick={rowClick}
        onItemChange={onItemChange}
    />;

    function mountProperties() {
        if (!dataRule || !programStages) {
            setProgramStagesList([]);
            setProjectGroupsList([]);
            setData([]);
            setAddable([]);
            return;
        }

        let projectGroupsLocal = Array.isArray(projectGroups) && projectGroups.length ? projectGroups : [];
        let programStagesLocal = Array.isArray(programStages) && programStages.length ? programStages : [];
        const globalAccess = dataRule.accessGlobal;
        let general = {
            id: 0,
            name: window.captions.RuleConfigAccessGeneral,
            ...globalAccess,
            fullAccess: globalAccess.view && globalAccess.editAddons && globalAccess.editFull && globalAccess.historyView && globalAccess.historyAdd && globalAccess.stateSetting && globalAccess.stateChanging && globalAccess.stateChanging,
        };

        let dataList = [general];
        let addableList = [];
        projectGroupsLocal.forEach(i => {
            let access = dataRule.accessProject.find(x => i.id == x.id);
            if (access) {
                let group = createGroup(i, access, programStagesLocal, null);
                dataList.push(group);
            }
            else {
                addableList.push({ ...i, isGroup: true });
            }
        });

        programStagesLocal.forEach(i => {
            let access = dataRule.accessStages.find(x => i.id == x.id);
            if (access) {
                let stage = createStage(i, access, null);
                dataList.push(stage);
            }
            else {
                addableList.push({ ...i, isStage: true });
            }
        });

        setProgramStagesList(programStagesLocal);
        setProjectGroupsList(projectGroupsLocal);
        setData(dataList);
        setAddable(addableList);
    }

    function createGroup(item, access, stages, parent) {
        let group = createAccessElement(item, access);
        group.isGroup = true;
        group.items = [];
        group.addable = [];
        group.getParent = function () { return parent; };
        group.getOriginalObject = function () { return group; };

        stages?.forEach(s => {
            let stageAccess = access?.stages.find(x => s.id == x.id);
            if (stageAccess) {
                let stage = createStage(s, stageAccess, group);
                group.items.push(stage);
            }
            else {
                group.addable.push({ ...s, isStage: true });
            }
        });

        item.items?.forEach(p => {
            let projectAccess = access?.projects.find(x => p.id == x.id);
            if (projectAccess) {
                let project = createProject(p, projectAccess, stages, group);
                group.items.push(project);
            }
            else {
                group.addable.push({ ...p, isProject: true });
            }
        });

        return group;
    }

    function createProject(item, access, stages, parent) {
        let project = createAccessElement(item, access);
        project.isProject = true;
        project.items = [];
        project.addable = [];
        project.getParent = function () { return parent; };
        project.getOriginalObject = function () { return project; };
        stages?.forEach(s => {
            let stageAccess = access?.stages.find(x => s.id == x.id);
            if (stageAccess) {
                let stage = createStage(s, stageAccess, project);
                project.items.push(stage);
            }
            else {
                project.addable.push({ ...s, isStage: true });
            }
        });

        return project;
    }

    function createStage(item, access, parent) {
        let stage = createAccessElement(item, access);
        stage.isStage = true;
        stage.items = [];
        stage.addable = [];
        stage.getParent = function () { return parent; };
        stage.getOriginalObject = function () { return stage; };
        item.states.forEach(i => {
            let stateAccess = access?.states.find(x => i.id == x.id);
            if (stateAccess) {
                stage.items.push(createState(i, stateAccess, stage));
            }
            else {
                stage.addable.push({ ...i, isState: true });
            }
        });

        return stage;
    }

    function createState(item, access, parent) {
        let state = createAccessElement(item, access);
        state.isState = true;
        state.items = [];
        state.getParent = function () { return parent; };
        state.getOriginalObject = function () { return state; };
        return state;
    }

    function createAccessElement(item, access) {
        if (!access)
            return createDefaultAccess(item);

        return {
            id: item.id,
            name: item.name,
            ...access.access,
            fullAccess: access.view && access.editAddons && access.editFull
                && access.historyView && access.historyAdd && access.stateSetting
                && access.stateChanging && access.stateChanging,
        };
    }

    function createDefaultAccess(item) {
        return {
            id: item.id,
            name: item.name,
            view: false,
            editAddons: false,
            editFull: false,
            historyView: false,
            historyAdd: false,
            stateSetting: false,
            stateChanging: false,
            fieldVerification: false,
            callRecords: false,
            fullAccess: false,
        };
    }

    function cellWithEditing(props) {
        const { dataItem } = props;

        if (dataItem.isGroup) {
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                {Array.isArray(dataItem.addable) && dataItem.addable.length
                    ? <DropDownButton
                        onItemClick={(e) => addProject(dataItem, e)}
                        popupSettings={{ animate: false }}
                        icon="add"
                        items={dataItem.addable}
                        textField="name"
                    />
                    : null
                }
                <Button
                    icon="close"
                    onClick={() => removeItem(dataItem, (item) => item.isGroup && item.id == dataItem.id)}
                />
            </td>;
        } else if (dataItem.isProject) {
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                {Array.isArray(dataItem.addable) && dataItem.addable.length
                    ? <DropDownButton
                        onItemClick={(e) => addStage(dataItem, e)}
                        popupSettings={{ animate: false }}
                        icon="add"
                        items={dataItem.addable}
                        textField="name"
                    />
                    : null
                }
                <Button
                    icon="close"
                    onClick={() => removeItem(dataItem, (item) => item.isProject && item.id == dataItem.id)}
                />
            </td>;
        } else if (dataItem.isStage) {
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                {Array.isArray(dataItem.addable) && dataItem.addable.length
                    ? <DropDownButton
                        onItemClick={(e) => addState(dataItem, e)}
                        popupSettings={{ animate: false }}
                        icon="add"
                        items={dataItem.addable}
                        textField="name"
                    />
                    : null
                }
                <Button
                    icon="close"
                    onClick={() => removeItem(dataItem, (item) => item.isStage && item.id == dataItem.id)}
                />
            </td>;
        } else if (dataItem.isState) {
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                <Button
                    icon="close"
                    onClick={() => removeItem(dataItem, (item) => item.isState && item.id == dataItem.id)}
                />
            </td>;
        } else {
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                {Array.isArray(addable) && addable.length
                    ? <DropDownButton
                        onItemClick={(e) => addProjectGroupOrStage(e)}
                        popupSettings={{ animate: false }}
                        icon="add"
                        items={addable}
                        textField="name"
                    />
                    : null
                }
            </td>;
        }

        return null;
    }

    function addProjectGroupOrStage(e) {
        let newData = [...data];
        let newAddable = [...addable];
        let item = e.item;

        if (item.isGroup) {
            let index = newAddable.findIndex((i) => i.isGroup && i.id == item.id);
            if (index != -1) {
                newAddable.splice(index, 1);
                let group = createGroup(item, null, programStages, null);
                newData.push(group);
            }
        } else if (item.isStage) {
            let index = newAddable.findIndex((i) => i.isStage && i.id == item.id);
            if (index != -1) {
                newAddable.splice(index, 1);
                let stage = createStage(i, null, null);
                newData.push(stage);
            }
        }

        setData(newData);
        setAddable(newAddable);
    }

    function addProject(parentItem, e) {
        let item = e.item;
        let origin = parentItem.getOriginalObject();
        let index = origin.addable.findIndex((i) => i.isProject && i.id == item.id);
        if (index != -1) {
            origin.addable.splice(index, 1);
            let project = createProject(item, null, programStages, origin);
            origin.items.push(project);
        }
    }

    function addStage(parentItem, e) {
        let item = e.item;

        let origin = parentItem.getOriginalObject();
        let index = origin.addable.findIndex((i) => i.isStage && i.id == item.id);
        if (index != -1) {
            origin.addable.splice(index, 1);
            let stage = createStage(item, null, origin);
            origin.items.push(stage);
        }
    }

    function addState(parentItem, e) {
        let item = e.item;

        let origin = parentItem.getOriginalObject();
        let index = origin.addable.findIndex((i) => i.id == item.id);
        if (index != -1) {
            origin.addable.splice(index, 1);
            origin.items.push(createState(item, null, origin));
        }
    }

    function removeItem(item, findIndex) {
        let parent = item.getParent();
        if (parent) {
            let index = parent.items.findIndex(findIndex);
            if (index == -1)
                return;

            parent.items.splice(index, 1);
            parent.addable.push(item);
            setData(data);
            return;
        }

        let index = data.findIndex(findIndex);
        if (index == -1)
            return;

        var newData = [...data];
        newData.splice(index, 1);

        setData(newData);
        setAddable([...addable, item]);
    }

    function onExpandChange(e) {
        setExpanded(e.value
            ? expanded.filter((id) => id !== e.dataItem.id)
            : [...expanded, e.dataItem.id]);
    }

    function rowClick(event) {
        setEditId(editId === event.dataItem.id ? null : event.dataItem.id);
    }

    function onItemChange(event) {
        const field = event.field;
        setData(mapTree(data, subItemsField, (item) => {
            if (item.id === event.dataItem.id) {
                const result = extendDataItem(item, subItemsField, {
                    [field]: event.value,
                });
                if (field == "fullAccess") {
                    result.view = result.fullAccess;
                    result.editAddons = result.fullAccess;
                    result.editFull = result.fullAccess;
                    result.historyView = result.fullAccess;
                    result.historyAdd = result.fullAccess;
                    result.stateSetting = result.fullAccess;
                    result.stateChanging = result.fullAccess;
                } else {
                    result.fullAccess = result.view && result.editAddons && result.editFull && result.historyView && result.historyAdd && result.stateSetting && result.stateChanging && result.stateChanging;
                }

                return result;
            } else {
                return item;
            }
        }));
    }
}