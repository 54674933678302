import React, { useState, useEffect } from 'react';
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { statGeneralTypeEnum, dateTimeWidth, getFormatDateTime } from "../../../utils/systemConstants.jsx";
import { StatGeneralItemComponent } from "./statGeneralItemComponent.jsx";
import { FieldFilteredTab } from "../reports/fieldFilteredTab.jsx";
import { ReportSqlTab } from "../reports/reportSqlTab.jsx";
import { StatisticTabControl } from "./statisticTabControl.jsx";
import { VariablesTab } from "./variablesTab.jsx";
import { DataFilterTab } from "./dataFilterTab.jsx";
import { TextBox } from "@progress/kendo-react-inputs";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { GroupUI } from "../documents/groupUI.jsx"
import { FolderUI } from "./folderUi.jsx"
import { fetchPost } from '../../../utils/requestsHelper.jsx';
import LoadingMask from "../../../components/loadingMask.jsx";
import MessageMask from "../../../components/messageMask.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

export function WizardComponent({ template, languages, onEdit, onAddItem, onDelete }) {
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [fields, setFields] = useState([]);
    const [templateFields, setTemplatedFields] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleSelectTab = (e) => {
        setSelectedTab(e.selected);
    };

    const handleChangeName = (e) => {
        if (e.value.length === 0) {
            setError(window.captions.TitleMustNotBeEmpty);
            return;
        }

        var template = currentTemplate;
        template.name = e.value;
        setCurrentTemplate(template);
        onEdit(template)
    };

    const getRequest = (type) => {
        let names = [];
        currentTemplate.items.forEach(item => names.push({ id: names.length, name: item.name }));

        let request = {
            id: currentTemplate.id,
            names: names,
            type: type
        };

        return request;
    };

    if (!template) {
        return;
    }

    useEffect(() => {
        setCurrentTemplate(template);
        setSelectedTab(0);
        loadFields();
        loadTemplatedFields();

    }, [template]);


    if (!currentTemplate)
        return;

    if (isLoaded)
        return <div style={{ height: "300px" }}><LoadingMask /></div>;

    let content = [];

    const folderContent = [];
    if (currentTemplate.type === statGeneralTypeEnum.folder) {
        const titleContent = [];

        renderPair(titleContent,
            window.captions.Identifier,
            `StatFolderIdentifier_${currentTemplate.id}`,
            () => <TextBox key="FolderIdentifier" value={currentTemplate.id} readOnly={true} />
        );

        renderPair(titleContent,
            window.captions.Author,
            `StatAuthor_${currentTemplate.id}`,
            () => <TextBox key="FolderAuthor" value={currentTemplate.author} readOnly={true} />
        );

        if ("created" in currentTemplate) {
            renderPair(titleContent,
                window.captions.Created,
                `StatCreated_${currentTemplate.id}`,
                () => <DateTimePicker key="Created"
                    value={new Date(currentTemplate.created)}
                    width={dateTimeWidth}
                    disabled
                    formatPlaceholder={getFormatDateTime()}
                    readOnly={true} />
            );
        }

        folderContent.push(<div key="titleUi" className="dm-container dm-container-two">{titleContent}</div>);
        folderContent.push(<div key="dividerUi" className="dm-menu-divider" />);

        renderPair(folderContent,
        	window.captions.Caption,
            `FolderName_${currentTemplate.id}`,
        	() => <div className="k-d-flex-row dm-m-left">
        		      <TextBox key="FolderName"
        		               className="dm-m-right"
        		               value={currentTemplate.name}
        		               onChange={handleChangeName}/>
        	      </div>
        );
        content = <FolderUI handleSelectTab={handleSelectTab}
            currentTemplate={currentTemplate}
            folderContent={folderContent}
            onAddItem={onAddItem}
            getRequest={getRequest} />;
    } else if (currentTemplate.type === statGeneralTypeEnum.documentGroup) {
        content = <GroupUI handleSelectTab={handleSelectTab}
            templateFields={templateFields}
            template={currentTemplate}
            onAddItem={onAddItem}
            onEdit={onEdit}
            getRequest={getRequest}/>;
    } else {
        content = <TabStrip className="dm-container dm-container-slim"
                            key="mainUi"
                            style={{maxWidth:"1500px"}}
    	                    selected={selectedTab}
    	                    onSelect={handleSelectTab}>
    		          {getTabs()}
    	          </TabStrip>;
    }
    
    return <div className="dm-container">
        {content}
        {error
            ? <Dialog width={400}>
                <MessageMask inline error={error} />
                <DialogActionsBar>
                    <Button onClick={() => { setError(null) }}>{window.captions.Close}</Button>
                </DialogActionsBar>
            </Dialog>
            : null}
    </div>;

    function getTabs() {
        const tabs = [];

        tabs.push(<TabStripTab key="generalTab" title={window.captions.General}>
                    <StatGeneralItemComponent template={currentTemplate} templateFields={templateFields} onEdit={onEdit} />
                  </TabStripTab>);
        if (currentTemplate.type === statGeneralTypeEnum.reportSql) {
            tabs.push(<TabStripTab key="scriptTab" title={window.captions.ScriptSql}>
                          <ReportSqlTab template={currentTemplate} fields={fields} onEdit={onEdit} onDelete={onDelete} />
        	          </TabStripTab>);
        	tabs.push(<TabStripTab key="filteringTab" title={window.captions.Filtering}>
                          <FieldFilteredTab fields={fields} template={currentTemplate} onEdit={onEdit}/>
        	          </TabStripTab>);
        }
        if (currentTemplate.type === statGeneralTypeEnum.report ||
            currentTemplate.type === statGeneralTypeEnum.document) {
            tabs.push(<TabStripTab key="dataFilterTab" title={window.captions.DataFlter}>
                          <DataFilterTab template={currentTemplate} onEdit={onEdit} />
        	          </TabStripTab>);
            tabs.push(<TabStripTab key="valiablesTab" title={window.captions.Variables}>
                          <VariablesTab template={currentTemplate} languages={languages} onEdit={onEdit} onDelete={onDelete}/>
        	          </TabStripTab>);
        }
        tabs.push(<TabStripTab key="statisticTab" title={window.captions.MainTabStatistic}>
                      <StatisticTabControl template={currentTemplate} />
                  </TabStripTab>);
        
        return tabs;
    }


    function loadFields() {
        setError(null);
        setIsLoaded(true);

        fetchPost(window.constants.loadWizardItem,
            {
                type: 1,
                source: currentTemplate ? currentTemplate.source : template.source
            },
            (data) => {
                setFields(data);
                setIsLoaded(false);
            },
            (ex) => {
                setFields([]);
                showError(ex);
            });
    }
    function loadTemplatedFields() {
        setError(null);
        setIsLoaded(true);

        fetchPost(window.constants.loadWizardItem,
            {
                type: 0
            },
            (data) => {
                setTemplatedFields(data);
                setIsLoaded(false);
            },
            (ex) => {
                setTemplatedFields([]);
                showError(ex);
            });
    }

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
    }
}