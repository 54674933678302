import React, { useState, useEffect } from 'react';
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";
import TreeViewCheck from "../../../../../components/controls/treeViewCheck.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { numericWidth } from "../../../../../utils/systemConstants.jsx";
import {
    TreeView,
    moveTreeViewItem,
    TreeViewDragAnalyzer,
    TreeViewDragClue,
    processTreeViewItems,
    handleTreeViewCheckChange,
} from "@progress/kendo-react-treeview";

export default function ToolsTab({ dataRule, privilegesMacros, ruleChanged }) {
    const [privilegesMacrosList, setPrivilegesMacrosList] = useState(null);
    //const [privilegesMacrosList2, setPrivilegesMacrosList2] = useState(null);

    const [data, setData] = useState(null);
    const [pending, setPending] = useState(null);
    const [error, setError] = useState(null);
    const [check, setCheck] = useState({
        ids: [],
        applyCheckIndeterminate: true,
    });

    useEffect(() => mountProperties(), [dataRule.id]);

    //const data = processTreeViewItems(privilegesMacrosList2, {
    //    check: check,
    //});

    return <div className="dm-container dm-no-padding">
        {/*<TreeView*/}
        {/*    key="privilegesMacrosTree"*/}
        {/*    //checkboxes={true}*/}
        {/*    expandIcons={true}*/}
        {/*    data={privilegesMacrosList}*/}
        {/*    //data={data}*/}
        {/*    checkIndeterminateField="checkIndeterminate"*/}
        {/*    textField="name"*/}
        {/*    onExpandChange={onExpandChange}*/}
        {/*    onCheckChange={(e) => onCheckChangeTreeView(e, "privilegesMacros")}*/}
        {/*/>*/}
        {/*<div>*/}
        {/*    <Button style={{ marginRight: 5 }}>*/}
        {/*        Add '1' to the names end of second level elements*/}
        {/*    </Button>*/}
        {/*    <Button>*/}
        {/*        Add new element*/}
        {/*    </Button>*/}
        {/*</div>*/}
        <TreeViewCheck
            key="treeViewCheck"
            dataTree={privilegesMacrosList}
            onCheckChange={selectedDocumentTypesChanged}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !privilegesMacros) {
            setPrivilegesMacrosList([]);
            return;
        }

        let privilegesMacrosLocal = Array.isArray(privilegesMacros) && privilegesMacros.length ? privilegesMacros : [];
        privilegesMacrosLocal.forEach(i => { setParents(i); });
        //privilegesMacrosLocal[0].checkIndeterminate = false;
        //privilegesMacrosLocal[1].checkIndeterminate = true;
        //let privilegesMacrosLocal2 = privilegesMacrosLocal.map(i => { return { ...i, items: i.items.map(x => { return { ...x, getParent: function () { return i; } }; }) }; });
        privilegesMacrosLocal.forEach(i => setCheckPrivileges(i, dataRule));

        setPrivilegesMacrosList(privilegesMacrosLocal);
        //setPrivilegesMacrosList2(privilegesMacrosLocal2);
    }

    function setCheckPrivileges(data, dataRule) {
        if (!data) {
            return;
        }

        if (data.privilege && data.privilege >= 0 && dataRule.priveliges.length > data.privilege) {
            data.checked = dataRule.priveliges[data.privilege];
            if (data.checked && data.items) {
                data.items.forEach(i => i.checked = true);
                return;
            }
        }

        if (data.items && data.items.length > 0) {
            data.items.forEach(i => setCheckPrivileges(i, dataRule));
            if (data.items.some((item) => item.checked)) {
                if (data.items.every((item) => item.checked)) {
                    data.checked = true;
                } else {
                    data.checked = null;
                }
            }
            else {
                data.checked = false;
            }
        }
    }

    function licenseNotificationChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                licenseNotificationDays: e.value,
                isUpdated: true
            });
    }

    function numberPagedLinesSearchResultChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                countSearch: e.value,
                isUpdated: true
            });
    }

    //function onExpandChange  (event)  {
    //    event.item.expanded = !event.item.expanded;
    //};

    //function onCheckChangeTreeView  (event, arrayName)  {
    //    event.item.checked = !event.item.checked;
    //    if (event.item.checked) {
    //        event.item.cloned = true;
    //    } else {
    //        if (event.item.hasOwnProperty("cloned"))
    //            delete event.item.cloned;
    //    }
    //    checkRecursive(event.item);
    //    checkParents(event.item);

    //    removeCheckIndeterminate(event.item);

    //    let arrayChanged = model[arrayName];
    //    //const settings = {
    //    //    singleMode: false,
    //    //    checkChildren: true,
    //    //    checkParents: true,
    //    //};
    //    //const check = handleTreeViewCheckChange(
    //    //    event,
    //    //    check,
    //    //    privilegesMacrosList2,
    //    //    settings
    //    //);

    //    setCheck(check);
    //    set[arrayName](arrayChanged);
    //}

    //function checkRecursive(item) {
    //    if (item.items && item.items.length > 0) {
    //        item.items.forEach(i => {
    //            i.checked = item.checked;
    //            removeCheckIndeterminate(i);
    //            checkRecursive(i);
    //        });
    //    }
    //}

    //function removeCheckIndeterminate(item) {
    //    if (item.hasOwnProperty("checkIndeterminate"))
    //        delete item.checkIndeterminate;
    //}

    //function checkParents(item) {
    //    if (item.getParent)
    //        var parent = item.getParent();
    //    if (!parent) {
    //        return;
    //    }

    //    if (parent.items.some((item) => item.checked)) {
    //        if (parent.items.every((item) => item.checked)) {
    //            parent.checked = true;
    //            parent.cloned = true;
    //            //removeCheckIndeterminate(parent);
    //            parent.checkIndeterminate = false;
    //        } else {
    //            if (parent.hasOwnProperty("checked"))
    //                delete parent.checked;
    //            parent.checkIndeterminate = true;
    //            parent.cloned = true;
    //        }
    //    }
    //    else {
    //        parent.checked = false;
    //        if (parent.hasOwnProperty("cloned"))
    //            delete parent.cloned;
    //       // removeCheckIndeterminate(parent);
    //        parent.checkIndeterminate = false;
    //    }
    //}

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }

    function selectedDocumentTypesChanged(item) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //urlsTypes: selected,
                isUpdated: true
            });
    }
}