import React, { useState, useEffect } from 'react';
import TreeViewCheck from "../../../../../components/controls/treeViewCheck.jsx";

export default function FullWorkHistory({ dataRule, historyActionView, historyActionAdd, ruleChanged }) {
    const [historyActionViewList, setHistoryActionViewList] = useState([]);
    const [historyActionAddList, setHistoryActionAddList] = useState([]);

    useEffect(() => mountProperties(), [dataRule.id, historyActionView, historyActionAdd]);

    return <div className="dm-container dm-no-padding">
        <div className="dm-wrapper">
            <div className="dm-wrapper-content">
                <TreeViewCheck
                    key="historyActionsHiddenView"
                    dataTree={historyActionViewList}
                    onCheckChange={selectedHistoryActionViewChanged}
                />
                <TreeViewCheck
                    key="historyActionsHiddenAdd"
                    dataTree={historyActionAddList}
                    onCheckChange={selectedHistoryActionAddChanged}
                />
            </div>
        </div>
    </div>;

    function mountProperties() {
        if (!dataRule || !historyActionView || !historyActionAdd) {
            setHistoryActionViewList([]);
            setHistoryActionAddList([]);
            return;
        }

        let historyActionViewLocal = Array.isArray(historyActionView) && historyActionView.length ? historyActionView : [];
        historyActionViewLocal.forEach(i => setParents(i));
        historyActionViewLocal.forEach(i => setCheckPrivileges(i, dataRule.historyActionsHiddenView));


        let historyActionAddLocal = Array.isArray(historyActionAdd) && historyActionAdd.length ? historyActionAdd : [];
        historyActionAddLocal.forEach(i => setParents(i));
        historyActionAddLocal.forEach(i => setCheckPrivileges(i, dataRule.historyActionsHiddenAdd));

        setHistoryActionViewList(historyActionViewLocal);
        setHistoryActionAddList(historyActionAddLocal);
    }

    function setCheckPrivileges(data, selectedList) {
        if (!data) {
            return;
        }

        if (data.id && data.id >= 0 && selectedList.length > 0) {
            data.checked = selectedList.some(id => id == data.id);
            if (data.checked && data.items) {
                data.items.forEach(i => i.checked = true);
                return;
            }
        }

        if (data.items && data.items.length > 0) {
            data.items.forEach(i => setCheckPrivileges(i, selectedList));
            if (data.items.some((item) => item.checked)) {
                if (data.items.every((item) => item.checked)) {
                    data.checked = true;
                } else {
                    data.checked = null;
                }
            }
            else {
                data.checked = false;
            }
        }
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }

    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    }

    function selectedHistoryActionViewChanged(item) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //urlsTypes: selected,
                isUpdated: true
            });
    }

    function selectedHistoryActionAddChanged(item) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //urlsTypes: selected,
                isUpdated: true
            });
    }
}