import React, { useState } from "react";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import LangItemEditor from "../../../components/controls/langItemEditor.jsx";
import ItemSelector from "../../../components/controls/itemSelector.jsx";
import FieldsControl from "../../cards/controls/fieldsControl.jsx";
import { TreeView, TreeViewDragClue } from "@progress/kendo-react-treeview";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export function RenderProjectsListTreeView({items, onItemClick, onExpandChange, onItemDragOver, onItemDragEnd, dragClue, removeStageView}) {
    const controls = [];
    controls.push(<div>
                      <TreeView
                          data={items}
                          onItemClick={onItemClick}
                          onExpandChange={onExpandChange}
                          animate={false}
                          textField="name"
                          expandIcons={true}
                          draggable={true}
                          onItemDragOver={onItemDragOver}
                          onItemDragEnd={onItemDragEnd}
                          itemRender={props => <span>{props.name}</span>}
                          style={{ width: "100%" }}/>
                      <TreeViewDragClue ref={(dragClue) => (dragClue)}/>
                  </div>);

    controls.push(
        <Button style={{ margin: "10px 0 0 0" }} onClick={removeStageView}>{window.captions
            .HideGroup}</Button>);

    return controls;
}

export function RenderFieldsNeededTab({ selectedProject, selectedItemsChanged }) {
    const controls = [];

    if (selectedProject.id === 0) {
        return <span></span>;
    }

    let fields = [];
    switch (selectedProject.fieldsNeededSelectTab) {
    case 0:
            fields = selectedProject.clientFields;
        break;
    case 1:
            fields = selectedProject.loanFields;
        break;
    case 2:
            fields = selectedProject.paymentFields;
        break;
    case 3:
            fields = selectedProject.paymentPlanFields;
        break;
    case 4:
            fields = selectedProject.collateralFields;
        break;
    case 5:
            fields = selectedProject.ownerFields;
        break;
    case 6:
            fields = selectedProject.linkedFields;
        break;
    case 7:
            fields = selectedProject.calculatedFields;
        break;
    case 8:
            fields = selectedProject.accountFields;
        break;
    }

    if (selectedProject.id > 0) {
        controls.push(<ItemSelector
                          items={fields}
                          selectedItemsChanged={selectedItemsChanged}
                          sourceTypeField="source"/>);
    }

    return controls;
}

export function RenderVerificationFieldsNeededTab({selectedProject, verificationNeededSelectTab, selectedItemsChanged}) {
    const controls = [];

    if (selectedProject.length === 0) {
        return <span></span>;
    }

    let fields = [];
    switch (verificationNeededSelectTab) {
    case 0:
        fields = selectedProject.verificationView.client.items;
        break;
    case 1:
        fields = selectedProject.verificationView.loan.items;
        break;
    case 2:
        fields = selectedProject.verificationView.collaterals.items;
        break;
    case 3:
        fields = selectedProject.verificationView.process.items;
        break;
    case 4:
        fields = selectedProject.verificationView.processAction.items;
        break;
    }

    if (selectedProject.id > 0) {
        controls.push(<ItemSelector
                          items={fields}
                          selectedItemsChanged={selectedItemsChanged}
                          sourceTypeField="source"/>);
    }

    return controls;
}

export function RenderStagesAndActionTab({selectedProject, onExpandChange, onCheckChangeTreeView}) {
    const controls = [];

    if (selectedProject.length === 0) {
        return <span></span>;
    }

    controls.push(<div className="dm-wrapper-content">
                      <h1 className="dm-title">{window.captions.Stages}</h1>
                      <div className="dm-under-title-content">
                          <TreeView
                              key="stagesTree"
                              checkboxes={true}
                              expandIcons={true}
                              data={selectedProject.stages}
                              checkIndeterminateField="checkIndeterminate"
                              textField="name"
                              onExpandChange={onExpandChange}
                              onCheckChange={(e) => onCheckChangeTreeView(e, "stages")}/>
                      </div>
                  </div>);
    controls.push(
        <div className="dm-wrapper-content">
            <h1 className="dm-title">{window.captions.Actions}</h1>
            <div className="dm-under-title-content">
                <TreeView
                    key="actionsTree"
                    checkboxes={true}
                    expandIcons={true}
                    data={selectedProject.actions}
                    checkIndeterminateField="checkIndeterminate"
                    textField="name"
                    onExpandChange={onExpandChange}
                    onCheckChange={(e) => onCheckChangeTreeView(e, "actions")}/>
            </div>
        </div>);
    return controls;
}

export function RenderTasksTab({selectedProject, onExpandChange, onCheckChangeTreeView}) {
    const controls = [];

    if (selectedProject.length === 0) {
        return <span></span>;
    }

    controls.push(<TreeView
                      key="taskTree"
                      checkboxes={true}
                      expandIcons={true}
                      data={selectedProject.tasks}
                      checkIndeterminateField="checkIndeterminate"
                      textField="name"
                      onExpandChange={onExpandChange}
                      onCheckChange={(e) => onCheckChangeTreeView(e, "tasks")}/>);

    return controls;
}

//#region render card view

function renderCardViewTabs(selectedProject, changedName, selectedItemsChanged) {
    const controls = [];
    if (!selectedProject.cardView.cardView.view || selectedProject.cardView.cardView.view.cardViews.length === 0)
        return controls;

    for (let key in selectedProject.cardView.cardView.view.cardView.tabs) {
        const tab = selectedProject.cardView.cardView.view.cardView.tabs[key];
        controls.push(
            <TabStripTab title={tab.headerText}>
                <LangItemEditor style={{ width: "100%" }}
                                langItem={tab.content.selectLangItem}
                                namedChanged={changedName}
                                defaultName={tab.content.name}/>
                <ItemSelector
                    items={tab.content.fields}
                    selectedItemsChanged={selectedItemsChanged}
                    sourceTypes={selectedProject.cardView.sourceTypes}/>
            </TabStripTab>);
    }
    return controls;
}

export function RenderStagesView({selectedProject, changedStageCardView, removeStageView, addTab, addSearchView}) {
    const controls = [];

    if (!selectedProject.cardView.cardView.view || selectedProject.cardView.cardView.view.cardViews.length === 0)
        return controls;

    const copyView = [];
    for (let key in selectedProject.cardView.cardViews) {
        const cardView = selectedProject.cardView.cardViews[key];
        if (cardView.view && cardView.view.length === 0)
            continue;

        const test = cardView.view.cardViews.filter(x => x.id !== selectedProject.cardView.cardView.id);
        copyView.push(test);
    }

    controls.push(<DropDownList style={{ margin: "5px 5px 0 0" }}
                                textField="name"
                                dataItemKey="id"
                                onChange={changedStageCardView}
                                data={selectedProject.cardView.cardView.view.cardViews}
                                value={selectedProject.cardView.cardView.view.cardView}/>);
    controls.push(<Button style={{ margin: "5px 5px 0 0" }} iconClass="dm-i dm-i-remove"
                          onClick={removeStageView}>
                      {window.captions.DeleteCardView}
                  </Button>);
    controls.push(
        <Button style={{ margin: "5px 5px 0 0" }} iconClass="dm-i dm-i-remove"
                onClick={addTab}>
            {window.captions.AddTab}
        </Button>);
    controls.push(
        <DropDownButton text={window.captions.CopyCardView}
                        items={copyView}
                        textField="name"
                        onItemClick={(e) => addSearchView(e)}
                        style={{ margin: "5px 5px 0 0" }}/>);

    return controls;
}

function isValidViewTab(selectedProject) {
    if (selectedProject.cardView &&
        selectedProject.cardView.cardViews.length > 0)
        return true;

    return false;
}

export function RenderCardViewTab({
                                      selectedProject,
                                      handleCardViewSelectTab,
                                      changedName,
                                      selectedItemsChanged,
                                      addRule,
                                      changedCardView,
                                      addRuleIn,
                                      removeRuleView,
                                      addStageView
                                  }) {
    const controls = [];

    if (selectedProject.id === 0) {
        return <span></span>;
    }
    let stages = [];
    let deleteRules = [];
    let stagesView = [];
    let tabs = [];
    let tabsView = [];

    const rules =
        selectedProject.cardView.rules.filter(
            x => selectedProject.cardView.cardViews.every(s => s.selectedRules.every(o => o !== x.id)));
    if (isValidViewTab(selectedProject)) {
        if (selectedProject.cardView.cardView.id === 0)
            deleteRules.push(selectedProject.cardView.cardView);
        else
            deleteRules = selectedProject.cardView.rules.filter(x => {
                return selectedProject.cardView.cardView.selectedRules.find(s => s === x.id);
            });

        if (selectedProject.cardView && !selectedProject.cardView.cardView.view ||
            selectedProject.cardView.cardView.view.cardViews.every(t => t.id !== 0))
            stages.push(...selectedProject.cardView.stages.filter(x => x.id === 0));
        if (selectedProject.cardView && selectedProject.cardView.cardView.view)
            stages.push(...selectedProject.cardView.stages.filter(
                x => selectedProject.stages.filter(q => q.checked).some(i => i.id === x.id) &&
                selectedProject.cardView.cardView.view.cardViews.every(t => t.id !== x.id)));
        else {
            stages.push(...selectedProject.cardView.stages.filter(
                x => selectedProject.stages.filter(q => q.checked).some(i => i.id === x.id)));
        }

        if (selectedProject.cardView &&
            selectedProject.cardView.cardView.view &&
            selectedProject.cardView.cardView.view.cardViews.length > 0) {
            tabs = renderCardViewTabs(selectedProject, changedName, selectedItemsChanged);
            if (tabs.length > 0)
                tabsView.push(
                    <TabStrip
                        selected={selectedProject.cardViewTab}
                        onSelect={handleCardViewSelectTab} scrollable={true}
                        style={{ margin: "5px 5px 0 0" }}>
                        {tabs}
                    </TabStrip>);
        }
        stagesView = <RenderStagesView selectedProject={selectedProject}/>;
    }

    if (selectedProject.id > 0 && selectedProject.cardView.cardViews.length > 0) {
        controls.push(<div className="dm-container dm-container-slim">
                          <div className="dm-wrapper">
                              <div className="dm-wrapper-content">
                                  <DropDownButton text={window.captions.Role}
                                                  items={rules}
                                                  textField="name"
                                                  onItemClick={(e) => addRule(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                                  <DropDownList style={{ margin: "0 5px 0 0" }}
                                                textField="name"
                                                dataItemKey="id"
                                                onChange={changedCardView}
                                                data={selectedProject.cardView.cardViews}
                                                value={selectedProject.cardView.cardView}/>
                                  {selectedProject.cardView.cardView.id !== 0
                                      ? <DropDownButton text={window.captions.AddRoleToView}
                                                        items={rules}
                                                        textField="name"
                                                        onItemClick={(e) => addRuleIn(e)}
                                                        style={{ margin: "0 5px 0 0" }}/>
                                      : null}
                                  <DropDownButton text={window.captions.DeleteCardView}
                                                  items={deleteRules}
                                                  textField="name"
                                                  onItemClick={(e) => removeRuleView(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                              </div>
                          </div>
                          <div className="dm-wrapper">
                              <div className="dm-wrapper-content">
                                  <DropDownButton text={window.captions.Stage}
                                                  items={stages}
                                                  textField="name"
                                                  onItemClick={(e) => addStageView(e)}
                                                  style={{ margin: "5px 5px 0 0" }}/>
                                  {stagesView}
                              </div>
                          </div>
                          {tabsView}
                      </div>);
    } else if (selectedProject.id > 0)
        controls.push(<div className="dm-container dm-container-slim">
                          <div className="dm-wrapper">
                              <div className="dm-wrapper-content">
                                  <DropDownButton text={window.captions.AddSearchView}
                                                  items={rules}
                                                  textField="name"
                                                  onItemClick={(e) => addRule(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                              </div>
                          </div>
                      </div>);

    return controls;
}

//#endregion

export function RenderSearchViewTab({ selectedProject, addSearchView, changedView, removeView, copyView }) {
    const controls = [];

    if (selectedProject.id === 0) {
        return <span></span>;
    }
    const stages = [];
    if (selectedProject.searchView.searchViews.every(t => t.id !== 0))
        stages.push(...selectedProject.searchView.stages.filter(x => x.id === 0));

    const selectedStages = selectedProject.stages.filter(x => x.checked);
    const copyViews =
        selectedProject.searchView.searchViews.filter(x => x.id !== selectedProject.searchView.searchView.id);
    stages.push(...selectedProject.searchView.stages.filter(x => selectedStages.some(i => i.id === x.id) &&
        selectedProject.searchView.searchViews.every(t => t.id !== x.id)));
    if (selectedProject.id > 0 && selectedProject.searchView.searchView) {
        controls.push(<div className="dm-container dm-container-slim">
                          <div className="dm-wrapper">
                              <div className="dm-wrapper-content">
                                  <DropDownButton text={window.captions.AddSearchView}
                                                  items={stages}
                                                  textField="name"
                                                  onItemClick={(e) => addSearchView(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                                  <DropDownList style={{ margin: "0 5px 0 0" }}
                                                textField="name"
                                                dataItemKey="id"
                                                onChange={changedView}
                                                data={selectedProject.searchView.searchViews}
                                                value={selectedProject.searchView.searchView}/>
                                  <Button style={{ margin: "0 5px 0 0" }} iconClass="dm-i dm-i-remove"
                                          onClick={removeView}>
                                      {window.captions.DeleteCardView}
                                  </Button>
                                  <DropDownButton text={window.captions.CopyCardView}
                                                  items={copyViews}
                                                  textField="name"
                                                  onItemClick={(e) => copyView(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                              </div>
                          </div>
                          <ItemSelector
                              items={selectedProject.searchView.searchView.fields}
                              selectedItemsChanged={selectedItemsChanged}
                              sourceTypeField="source"/>
                      </div>);
    } else if (selectedProject.id > 0)
        controls.push(<div className="dm-container dm-container-slim">
                          <div className="dm-wrapper">
                              <div className="dm-wrapper-content">
                                  <DropDownButton text={window.captions.AddSearchView}
                                                  items={stages}
                                                  textField="name"
                                                  onItemClick={(e) => control.addSearchView(e)}
                                                  style={{ margin: "0 5px 0 0" }}/>
                              </div>
                          </div>
                      </div>);

    return controls;
}

export function RenderProcessesTab({selectedProject, onExpandChange, onCheckChangeTreeView}) {
    const controls = [];

    if (selectedProject.length === 0) {
        return <span></span>;
    }

    controls.push(<div className="dm-container">
                      <h1 className="dm-title">{window.captions.Process}</h1>
                      <div className="dm-under-title-content">
                          <TreeView
                              key="processTree"
                              checkboxes={true}
                              expandIcons={true}
                data={selectedProject.process}
                              checkIndeterminateField="checkIndeterminate"
                              textField="name"
                              onExpandChange={onExpandChange}
                              onCheckChange={(e) => onCheckChangeTreeView(e, "process")}/>
                      </div>
                  </div>);
    const selectActions = selectedProject.processActions.filter(
        i => selectedProject.process.find(s => s.id === i.id && (s.checked || s.checkIndeterminate)));
    controls.push(
        <div className="dm-container">
            <h1 className="dm-title">{window.captions.Actions}</h1>
            <div className="dm-under-title-content">
                <TreeView
                    key="processActionsTree"
                    checkboxes={true}
                    expandIcons={true}
                    data={selectActions}
                    checkIndeterminateField="checkIndeterminate"
                    textField="name"
                    onExpandChange={onExpandChange}
                    onCheckChange={(e) => onCheckChangeTreeView(e, "processActions")}/>
            </div>
        </div>);
    return controls;
}

export function RenderFieldsTab({selectedProject, isEdit, setEntityFields}) {
    const fields = selectedProject.fields && Object.keys(selectedProject.fields).length > 0
        ? <FieldsControl id={selectedProject.id}
            fields={selectedProject.fields}
                         setFields={setEntityFields}
                         entity="projectGroup"
                         sources={selectedProject.sources}
                         isEditMode={isEdit}/>
        : null;

    return fields;
}