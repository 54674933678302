import React, { useState, useEffect } from 'react';
import { fetchPost } from "../../../../../utils/requestsHelper.jsx";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import { numericWidth } from "../../../../../utils/systemConstants.jsx";
import MultilineTextArea from "../../../../../components/controls/multilineTextArea.jsx";
import ViewEditTreeView from "../../../../../components/controls/viewEditTreeView.jsx";

export default function RulesEntityTypes({ dataRule, phonesTypes, addressTypes, emailTypes, urlTypes, documentTypes, ruleChanged }) {
    const stateTabs = {
        phoneTypesTab: 0,
        addressTypesTab: 1,
        emailTypesTab: 2,
        urlTypesTab: 3,
        documentTypesTab: 4,
        fieldMaskTab: 5,
    };

    const [selectedTab, setSelectedTab] = useState(stateTabs.phoneTypesTab);

    const [maskConfig, setMaskConfig] = useState([]);
    const [phoneTypesList, setPhoneTypesList] = useState([]);
    const [addressTypesList, setAddressTypesList] = useState([]);
    const [emailTypesList, setEmailTypesList] = useState([]);
    const [urlTypesList, setUrlTypesList] = useState([]);

    const [documentTypesList, setDocumentTypesList] = useState([]);

    useEffect(() => mountProperties(), [dataRule.id, phonesTypes, addressTypes, emailTypes, urlTypes, documentTypes]);

    const fieldMask = [];
    const phoneFieldMask = [];

    renderPair(phoneFieldMask,
        window.captions.MaskSymbol,
        "maskSymbol",
        () => <div id="maskSymbol">
            <MultilineTextArea
                changeText={maskCharactersChanged}
                textLength={1}
                text={maskConfig.maskCharacters}
            />
        </div>
    );

    renderPair(phoneFieldMask,
        window.captions.NumberFirstCharacters,
        "numberFirstCharacters",
        () => <div id="numberFirstCharacters">
            <NumericTextBox onChange={firstCharactersChanged}
                defaultValue={0}
                min={0}
                format="n0"
                width={numericWidth}
                value={maskConfig.firstCharacters}
            />
        </div>
    );

    renderPair(phoneFieldMask,
        window.captions.NumberLastCharacters,
        "numberLastCharacters",
        () => <div id="numberLastCharacters">
            <NumericTextBox onChange={lastCharactersChanged}
                defaultValue={0}
                min={0}
                format="n0"
                width={numericWidth}
                value={maskConfig.lastCharacters}
            />
        </div>
    );

    renderPair(phoneFieldMask,
        window.captions.Example,
        "example",
        () => <div id="numberLastCharacters">
            {maskConfig.example}
            <Button icon="k-i-refresh"
                onClick={() => refreshPhoneMaskExample()}
                title={window.captions.Refresh} />
        </div>
    );

    renderPair(fieldMask,
        window.captions.Phones,
        "phones",
        () => <div id="phoneFieldMask">
            {phoneFieldMask}
        </div>
    );

    return <div className="dm-container dm-no-padding">
        <TabStrip style={{ height: "-webkit-fill-available" }}
            selected={selectedTab}
            onSelect={tabSelected}
            scrollable={true}>
            <TabStripTab title={window.captions.PhoneTypes} key={stateTabs.phoneTypesTab}>
                <ItemSelector
                    items={phoneTypesList}
                    selectedItemsChanged={selectedPhoneTypesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.AddressType} key={stateTabs.addressTypesTab}>
                <ItemSelector
                    items={addressTypesList}
                    selectedItemsChanged={selectedAddressTypesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.EmailTypes} key={stateTabs.emailTypesTab}>
                <ItemSelector
                    items={emailTypesList}
                    selectedItemsChanged={selectedEmailTypesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.UrlsTypes} key={stateTabs.urlTypesTab}>
                <ItemSelector
                    items={urlTypesList}
                    selectedItemsChanged={selectedUrlTypesChanged} />
            </TabStripTab>
            <TabStripTab title={window.captions.MainTabAdministrationDictDocumentType} key={stateTabs.documentTypesTab}>
                <ViewEditTreeView
                    key="documentTypesTreeView"
                    dataTree={documentTypesList}
                    onCheckChange={selectedDocumentTypesChanged}
                />
            </TabStripTab>
            <TabStripTab title={window.captions.FieldMask} key={stateTabs.fieldMaskTab}>
                {fieldMask}
            </TabStripTab>
        </TabStrip>
    </div>;

    function mountProperties() {
        if (!dataRule || (!phonesTypes && !addressTypes && !emailTypes && !urlTypes && !documentTypes)) {
            setPhoneTypesList([]);
            setAddressTypesList([]);
            setEmailTypesList([]);
            setUrlTypesList([]);
            setDocumentTypesList([]);
            return;
        }

        let phoneTypesLocal = Array.isArray(phonesTypes) && phonesTypes.length ? phonesTypes : [];
        phoneTypesLocal.forEach(i => i.isSelected = dataRule.phonesTypes.some(id => id == i.id));

        let addressTypesLocal = Array.isArray(addressTypes) && addressTypes.length ? addressTypes : [];
        addressTypesLocal.forEach(i => i.isSelected = dataRule.addressesTypes.some(id => id == i.id));

        let emailTypesLocal = Array.isArray(emailTypes) && emailTypes.length ? emailTypes : [];
        emailTypesLocal.forEach(i => i.isSelected = dataRule.emailsTypes.some(id => id == i.id));

        let urlTypesLocal = Array.isArray(urlTypes) && urlTypes.length ? urlTypes : [];
        urlTypesLocal.forEach(i => i.isSelected = dataRule.urlsTypes.some(id => id == i.id));

        let documentTypesLocal = Array.isArray(documentTypes) && documentTypes.length ? documentTypes : [];
        //documentTypesLocal.forEach(i => i.isSelected = dataRule.documentsTypes.some(id => id == i.id));
        documentTypesLocal.forEach(i => setParents(i));
        documentTypesLocal.forEach(i => setAccess(i));

        setPhoneTypesList(phoneTypesLocal);
        setAddressTypesList(addressTypesLocal);
        setEmailTypesList(emailTypesLocal);
        setUrlTypesList(urlTypesLocal);
        setDocumentTypesList(documentTypesLocal);
        setMaskConfig(dataRule.maskConfig);
    }

    function setParents(data) {
        if (Array.isArray(data.items) && data.items.length > 0) {
            data.items.forEach(x => {
                x.getParent = function () { return data; };
                setParents(x);
            });
        }
    }

    function setAccess(item, isView, isEdit) {
        const fieldAccess = dataRule.documentsTypes.find((element) => element.id == item.id);
        if (fieldAccess) {
            item.viewing = fieldAccess.isView;
            item.editing = fieldAccess.isEdit;
        }
        else {
            if (isView)
                item.viewing = isView;
            if (isEdit)
                item.editing = isEdit;
        }

        if (item.items)
            item.items.forEach(i => setAccess(i, item.viewing, item.editing));

        if (item.items && item.items.length > 0) {
            if (item.items.some((item) => item.viewing)) {
                if (item.items.every((item) => item.viewing)) {
                    item.viewing = true;
                } else {
                    item.viewing = null;
                }
            }
            else {
                item.viewing = false;
            }


            if (item.items.some((item) => item.editing)) {
                if (item.items.every((item) => item.editing)) {
                    item.editing = true;
                } else {
                    item.editing = null;
                }
            }
            else {
                item.editing = false;
            }
        }
    }

    function updateParents(item, field, dependentField) {
        if (item.getParent)
            var parent = item.getParent();
        if (!parent) {
            return;
        }
        if (parent.items.some((item) => item[field])) {
            if (parent.items.every((item) => item[field])) {
                parent[field] = true;
                if (dependentField && e.value) {
                    parent[dependentField] = true;
                }
            } else {
                parent[field] = null;
            }
        }
        else {
            parent[field] = false;
        }
    }

    function updateChildrens(item, field, dependentField) {
        if (item.items) {
            item.items.forEach(i => {
                i[field] = item[field];
                if (dependentField && item[field]) {
                    i[dependentField] = item[field];
                }
            });
        }
    }

    function selectedPhoneTypesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                phonesTypes: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedAddressTypesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                addressesTypes: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedEmailTypesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                emailsTypes: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedUrlTypesChanged(selectedItems) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                urlsTypes: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }

    function selectedDocumentTypesChanged(item) {
        //let selected = selectedItems ? selectedItems.map(i => i.id) : [];
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                //urlsTypes: selected,
                //isUpdated: true
            });

    }

    function maskCharactersChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                maskConfig: {
                    ...maskConfig,
                    maskCharacters: e.value,
                },
                isUpdated: true
            });
    }

    function firstCharactersChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                maskConfig: {
                    ...maskConfig,
                    firstCharacters: e.value,
                },
                isUpdated: true
            });
    }

    function lastCharactersChanged(e) {
        if (ruleChanged)
            ruleChanged({
                ...dataRule,
                maskConfig: {
                    ...maskConfig,
                    lastCharacters: e.value,
                },
                isUpdated: true
            });
    }


    function refreshPhoneMaskExample() {
        fetchPost(`${window.constants.refreshPhoneMaskExample}`,
            maskConfig,
            (data) => setMaskConfig({ ...maskConfig, example: data })
        );
    }

    function tabSelected(e) {
        setSelectedTab(e.selected);
    }
}