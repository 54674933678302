import React, { useState, useEffect } from 'react';
import LoadingMask from "../../../../../components/loadingMask.jsx";
import WizardFunction from "../../wizardFunctions.jsx";
import sharedUiRenderFunctions from "../../controls/sharedUiRenderFunctions.jsx";
import { PanelBar, PanelBarUtils } from "@progress/kendo-react-layout";

export function ConditionsFilterTab({ variable, source, onEdit }) {
    const [currentVariable, setCurrentVariable] = useState();

    useEffect(() => {
        setCurrentVariable(variable.item);
    }, [variable.item]);

    if (!currentVariable) {
        return <div style={{ height: "300px" }}><LoadingMask /></div>;
    }

    const onEditFilters = (item) => {
        var newVariable = currentVariable;
        newVariable.filterSource = item;
        setCurrentVariable(newVariable);
        onEdit({
            index: variable.index,
            item: newVariable
        });
    };

    var content = [];
    var panelItems = [];
    var entity = WizardFunction.findEntitySourceByValue(source);

    const filterGroups = sharedUiRenderFunctions.renderForm(currentVariable.filterField, onEditFilters, entity, false);
    filterGroups.map((group) => {
        panelItems.push(group);
    });

    if (panelItems.length > 0)
        content.push(<PanelBar key={`PanelBarParameters${currentVariable.id}`} className="k-panelbar dm-m-bottom">
            {PanelBarUtils.mapItemsToComponents(panelItems)}
        </PanelBar>);

    return <div style={{ maxHeight: "750px", overflow: "auto" }}>{content}</div>
}