import React, { useState } from "react";
import { renderPair } from "../../cards/cardRenderFunctions.jsx";
import DropDownTreeView from "../../../components/controls/dropDownTreeView.jsx";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ItemSelector from "../../../components/controls/itemSelector.jsx";
import { headersFile } from "../../../utils/requestsHelper.jsx";
import { TabStripTab } from "@progress/kendo-react-layout";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { getFormatDate, dateFormat } from "../../../utils/systemConstants.jsx";
import { formatDate } from "@progress/kendo-intl";
import { getUiCulture } from "../../../utils/authHelper.jsx";

export function RenderProjectQuestionnaire({ selectedProject, changeGeneralTabField, selectedItemsChanged }) {
    const controls = [];
    const tabs = [];

    renderPair(controls,
        window.captions.FailureLimitWarning,
        "FailureLimitWarning",
        () => (<Input onChange={(e) => changeGeneralTabField(e, "questionWarningMessage")}
                      value={selectedProject.questionWarningMessage}/>));
    renderPair(controls,
        window.captions.NumberRejectionsQuestion,
        "NumberRejectionsQuestion",
        () => (<Input onChange={(e) => changeGeneralTabField(e, "maxFailuresCountQuestionnaire")}
                      value={selectedProject.maxFailuresCountQuestionnaire}/>));

    tabs.push(<TabStripTab title={window.captions.General}>
                  <div className="dm-container dm-no-padding">
                      {controls}
                  </div>
              </TabStripTab>);
    tabs.push(<TabStripTab title={window.captions.Fields}>
                  <ItemSelector
                      items={selectedProject.questionnaireFields}
                      selectedItemsChanged={selectedItemsChanged}
                      sourceTypeField="source"
                      sourceTypes={selectedProject.questionnaireSourceTypes}/>
              </TabStripTab>);
    tabs.push(<TabStripTab title={window.captions.AddonFields}>
                  <ItemSelector
                      items={selectedProject.questionAddonFields}
                      selectedItemsChanged={selectedItemsChanged}/>
              </TabStripTab>);

    return tabs;
}

export function RenderProjectGeneralTab({
                                            selectedProject,
                                            changeGeneralTabField,
                                            changeLimit,
                                            onSearchKey,
                                            addGroupingPayment,
    uploadStatusChange,
                                            remove
                                        }) {
    const controls = [];

    if (selectedProject.id > 0) {
        renderPair(controls,
            window.captions.Caption,
            "CaptionProject",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "name")}
                          value={selectedProject.name}/>));
        renderPair(controls,
            window.captions.DateCreated,
            "LabelDataCreated",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "created")}
                          value={selectedProject.created}/>),
            selectedProject.created,
            false);
        renderPair(controls,
            window.captions.DateWorkGet,
            "DateWorkGet",
            () => (<DateTimePicker
                       onChange={(e) => { changeLimit(new Date(e.value), props, "expectedEndDate") }}
                       value={selectedProject.startDate ? new Date(selectedProject.startDate) : null}
                       formatPlaceholder={getFormatDate()}/>,
                selectedProject.startDate
                    ? formatDate(new Date(selectedProject.startDate), dateFormat, getUiCulture())
                    : null));

        controls.push(<div className="dm-wrapper">
                          <div className="dm-wrapper-content">
                              <Checkbox key="checkEndDate" labelPlacement={"before"} style={{ margin: "5px" }} label={window.captions.DateWorkEnd}
                                        value={selectedProject.endDate} onChange={(e) => changeGeneralTabField(e,
                                            "endDate")}/>
                              {selectedProject.isPaymentDistributeNoStatusCheck
                                  ? <Input onChange={(e) => changeGeneralTabField(e, "endDate")}
                                           onKeyDown={(e) => onSearchKey(e)}
                                           value={selectedProject.endDate}/>
                                  : <div>{selectedProject.endDate}</div>}
                          </div>
                      </div>);

        renderPair(controls,
            window.captions.StartProjectValue,
            "StartProjectValue",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "startValue")}
                          value={selectedProject.startValue}/>));

        renderPair(controls,
            window.captions.ProjectAdditionalPrefixToPhone,
            "ProjectAdditionalPrefixToPhone",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "prefix")}
                          value={selectedProject.prefix}/>));

        renderPair(controls,
            window.captions.NextContactDateDay,
            "NextContactDateDay",
            () => (<div className="dm-wrapper">
                       <div className="dm-wrapper-content">
                           <Input onChange={(e) => changeGeneralTabField(e, "nextContactMin")}
                                  value={selectedProject.nextContactMin}/>
                           <Input onChange={(e) => changeGeneralTabField(e, "nextContactMax")}
                                  value={selectedProject.nextContactMax}/>
                       </div>
                   </div>));

        renderPair(controls,
            window.captions.PromissDateDay,
            "PromissDateDay",
            () => (<div className="dm-wrapper">
                       <div className="dm-wrapper-content">
                           <Input onChange={(e) => changeGeneralTabField(e, "nextPromissMin")}
                                  value={selectedProject.nextPromissMin}/>
                           <Input onChange={(e) => changeGeneralTabField(e, "nextPromissMax")}
                                  value={selectedProject.nextPromissMax}/>
                       </div>
                   </div>));

        renderPair(controls,
            window.captions.NextContactDateDay,
            "DeclaredDateDay",
            () => (<div className="dm-wrapper">
                       <div className="dm-wrapper-content">
                           <Input onChange={(e) => changeGeneralTabField(e, "nextPaymentMin")}
                                  value={selectedProject.nextPaymentMin}/>
                           <Input onChange={(e) => changeGeneralTabField(e, "nextPaymentMax")}
                                  value={selectedProject.nextPaymentMax}/>
                       </div>
                   </div>));

        renderPair(controls,
            window.captions.PTPDays,
            "PTPDays",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "promissPaymentDays")}
                          value={selectedProject.promissPaymentDays}/>));

        renderPair(controls,
            window.captions.DefaultState,
            "ProjectDefaultState",
            () => (<DropDownTreeView
                       id="TaskDialogType"
                       treeData={selectedProject.states}
                       selectedId={selectedProject.defaultState}
                       selectionChanged={(e) => changeGeneralTabField(e, "defaultState")}/>));

        renderPair(controls,
            window.captions.MinimumAmountPerOnePromise,
            "ProjectMinimumAmountPerOnePromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "minimumAmountPerOnePromise")}
                          value={selectedProject.minimumAmountPerOnePromise}/>));

        renderPair(controls,
            window.captions.MaximumPeriodBetweenPromise,
            "ProjectMaximumPeriodBetweenPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumPeriodBetweenPromise")}
                          value={selectedProject.maximumPeriodBetweenPromise}/>));

        renderPair(controls,
            window.captions.MaximumPeriodSchedulerPromise,
            "ProjectMaximumPeriodSchedulerPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumPeriodSchedulerPromise")}
                          value={selectedProject.maximumPeriodSchedulerPromise}/>));

        renderPair(controls,
            window.captions.MaximumPeriodFirstPromiseDate,
            "ProjectMaximumPeriodFirstPromiseDate",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumPeriodFirstDatePromise")}
                          value={selectedProject.maximumPeriodFirstDatePromise}/>));

        renderPair(controls,
            window.captions.PromisePaymentPercent,
            "PromisePaymentPercentBlock",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "promisePaymentPercent")}
                          value={selectedProject.promisePaymentPercent}/>));

        renderPair(controls,
            window.captions.LastDayInMonthForFirstPromise,
            "LastDayInMonthForFirstPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "lastDayInMonthForFirstPromise")}
                          value={selectedProject.lastDayInMonthForFirstPromise}/>));

        renderPair(controls,
            window.captions.OverdueMonthsCount,
            "OverdueMonthsCount",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "overdueOutcomeMonthsCount")}
                          value={selectedProject.overdueOutcomeMonthsCount}/>));

        renderPair(controls,
            window.captions.ProjectConfigActionField,
            "ConfigActionField",
            () => (<DropDownList textField="name"
                                 dataItemKey="fieldId"
                                 data={selectedProject.fieldForAmountList}
                                 onChange={(e) => changeGeneralTabField(e, "promisePaymentFieldInHistory")}
                                 value={selectedProject.promisePaymentFieldInHistory}/>));

        renderPair(controls,
            window.captions.ProjectConfigActionFieldFactor,
            "MaximumValuePaymentPromise",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "maximumValuePaymentPromise")}
                          value={selectedProject.maximumValuePaymentPromise}/>));

        renderPair(controls,
            window.captions.GroupPaymentsByThisField,
            "ProjectPaymentsByThisFieldButton",
            () => (<Button onClick={() => addGroupingPayment()}>{window.captions.Add}</Button>));

        //todo
        let index = 0;
        for (let key in selectedProject.paymentGrouping) {
            const grouping = selectedProject.paymentGrouping[key];
            const selectField = grouping.fields.find(i => i.id === grouping.fieldId);
            index++;
            renderPair(controls,
                window.captions.GroupPaymentsByThisField,
                `ProjectPaymentsByThisField${index}`,
                () => (<DropDownList textField="name"
                                     dataItemKey="id"
                                     data={grouping.fields}
                                     onChange={(e) => changeGeneralTabField(e, "maxFailuresCountQuestionnaire")}
                                     value={selectField}/>));
        }

        //renderPair(controls,
        //    "Сортування погашення за цим полем",
        //    "SelectedSortPaymentField",
        //    () => (<DropDownList textField="name"
        //                         dataItemKey="fieldId"
        //                         data={selectedProject.automationPriorities}
        //                         onChange={this.changePromisePaymentFieldInHistory}
        //                         value={selectedProject.automationPriority} />));

        renderPair(controls,
            window.captions.AutomationPriority,
            "AutomationPriorities",
            () => (<DropDownList textField="name"
                                 dataItemKey="id"
                                 data={selectedProject.automationPriorities}
                                 onChange={(e) => changeGeneralTabField(e, "automationPriority")}
                                 value={selectedProject.automationPriority}/>));

        renderPair(controls,
            window.captions.BackgroundImage,
            "ProjectBackgroundImage",
            () => (<Upload
                       batch={false}
                       multiple={true}
                       defaultFiles={[]}
                       onStatusChange={uploadStatusChange}
                       onRemove={remove}
                       saveHeaders={headersFile()}
                       removeHeaders={headersFile()}
                       saveUrl={window.constants.fileUpload}
                       removeUrl={window.constants.fileRemove}/>));

        renderPair(controls,
            window.captions.HideGroup,
            "ProjectHideGroup",
            () => (<Checkbox key="IsHidden" value={selectedProject.isHidden} label={window
                .captions.HideGroup} onChange={(e) => changeGeneralTabField(e, "isHidden")}/>));
    }
    return controls;
}

export function RenderDescription({ selectedProject, changeGeneralTabField }) {
    const controls = [];
    if (selectedProject.id > 0) {
        renderPair(controls,
            window.captions.Comments,
            "Comments",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "comments")}
                value={selectedProject.comments}/>));
        renderPair(controls,
            window.captions.DescriptionImport,
            "DescriptionImport",
            () => (<Input onChange={(e) => changeGeneralTabField(e, "importDescription")}
                value={selectedProject.importDescription}/>));
    }
    return controls;
}

//#region render like groups

export function RenderLikeAGroup({ selectedProject, changeLikeAGroup }) {
    let controls = [];

    //#region fields
    controls.push(<div key="likeAGroupFieldsNeeded">{window.captions.FeeldsNeeded}</div>);
    renderPair(controls,
        "",
        "likeAGroupFieldsForLoan",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "loanFields")} value={selectedProject.likeGroupView
            .loanFields}
                         label={window.captions.ForLoan}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForClient",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "clientFields")} value={selectedProject.likeGroupView
            .clientFields}
                         label={window.captions.ForClient}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForPayments",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "paymentFields")} value={selectedProject.likeGroupView
            .paymentFields}
                         label={window.captions.ForPayments}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForPaymentPlan",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "paymentPlanFields")} value={selectedProject.likeGroupView
            .paymentPlanFields}
                         label={window.captions.ForPaymentPlan}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForCollaterals",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "collateralsFields")} value={selectedProject.likeGroupView
            .collateralsFields}
                         label={window.captions.Collateral}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForOwners",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "ownersFields")} value={selectedProject.likeGroupView
            .ownersFields}
                         label={window.captions.ForOwners}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForLinked",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "linkedFields")} value={selectedProject.likeGroupView
            .linkedFields}
                         label={window.captions.ForLinked}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsCalculated",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "calculatedFields")} value={selectedProject.likeGroupView
            .calculatedFields}
                         label={window.captions.CalculatedLoanClient}/>));

    renderPair(controls,
        "",
        "likeAGroupFieldsForAccounts",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "accountFields")} value={selectedProject.likeGroupView
            .accountFields}
                         label={window.captions.ForAccounts}/>));

    //#endregion

    //#region stages
    controls.push(<div key="likeAGroupStagesAndActionsNeeded">{window.captions.StagesAndActionsNeeded}</div>);
    renderPair(controls,
        "",
        "likeAGroupStages",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "stages")} value={selectedProject.likeGroupView.stages}
                         label={window.captions.Stages}/>));

    renderPair(controls,
        "",
        "likeAGroupActions",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "actions")} value={selectedProject.likeGroupView.actions}
                         label={window.captions.Actions}/>));

    renderPair(controls,
        "",
        "likeAGroupTasks",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "tasks")} value={selectedProject.likeGroupView.tasks}
                         label={window.captions.Tasks}/>));

    renderPair(controls,
        "",
        "likeAGroupDefaultState",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "defaultState")} value={selectedProject.likeGroupView
            .defaultState}
                         label={window.captions.DefaultState}/>));

    //#endregion

    //#region Process

    controls.push(<div key="likeAGroupProcessLabel">{window.captions.Process}</div>);
    renderPair(controls,
        "",
        "likeAGroupProcess",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "process")} value={selectedProject.likeGroupView.process}
                         label={window.captions.Process}/>));

    renderPair(controls,
        "",
        "likeAGroupProcessActions",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "processActions")} value={selectedProject.likeGroupView
            .processActions}
                         label={window.captions.Actions}/>));

    //#endregion

    //#region verification
    controls.push(<div key="likeAGroupNeedVerification">{window.captions.FieldNeedVerification}</div>);
    renderPair(controls,
        "",
        "likeAGroupClientVerif",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "clientVerif")} value={selectedProject.likeGroupView
            .clientVerif}
                         label={window.captions.Clients}/>));

    renderPair(controls,
        "",
        "likeAGroupLoanVerif",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "loanVerif")} value={selectedProject.likeGroupView
            .loanVerif}
                         label={window.captions.Loans}/>));
    renderPair(controls,
        "",
        "likeAGroupCollateralsVerif",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "collateralsVerif")} value={selectedProject.likeGroupView
            .collateralsVerif}
                         label={window.captions.Collateral}/>));

    renderPair(controls,
        "",
        "likeAGroupProcessVerif",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "processVerif")} value={selectedProject.likeGroupView
            .processVerif}
                         label={window.captions.Process}/>));

    renderPair(controls,
        "",
        "likeAGroupProcessActionVerif",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "processActionVerif")} value={selectedProject
            .likeGroupView.processActionVerif}
                         label={window.captions.ProcessAction}/>));

    //#endregion

    //#region likeAGroupPromiseScheduler
    controls.push(<div key="likeAGroupPromiseScheduler">{window.captions.PromiseScheduler}</div>);
    renderPair(controls,
        "",
        "likeAGroupMinimumAmountPerOnePromise",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "minimumAmountPerOnePromiseVerif")} value={selectedProject
            .likeGroupView.minimumAmountPerOnePromiseVerif}
                         label={window.captions.MinimumAmountPerOnePromise}/>));

    renderPair(controls,
        "",
        "likeAGroupMaximumPeriodBetweenPromise",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "maximumPeriodBetweenPromiseVerif")} value={
selectedProject.likeGroupView.maximumPeriodBetweenPromiseVerif}
                         label={window.captions.MaximumPeriodBetweenPromise}/>));
    renderPair(controls,
        "",
        "likeAGroupMaximumPeriodSchedulerPromise",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "maximumPeriodSchedulerPromiseVerif")} value={
selectedProject.likeGroupView.maximumPeriodSchedulerPromiseVerif}
                         label={window.captions.MaximumPeriodSchedulerPromise}/>));

    renderPair(controls,
        "",
        "likeAGroupMaximumPeriodFirstPromiseDate",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "maximumPeriodFirstDatePromiseVerif")} value={
selectedProject.likeGroupView.maximumPeriodFirstDatePromiseVerif}
                         label={window.captions.MaximumPeriodFirstPromiseDate}/>));

    renderPair(controls,
        "",
        "likeAGroupPromisePaymentPercent",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "promisePaymentPercentVerif")} value={selectedProject
            .likeGroupView.promisePaymentPercentVerif}
                         label={window.captions.PromisePaymentPercent}/>));

    renderPair(controls,
        "",
        "likeAGroupLastDayInMonthForFirstPromise",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "lastDayInMonthForFirstPromiseVerif")} value={
selectedProject.likeGroupView.lastDayInMonthForFirstPromiseVerif}
                         label={window.captions.LastDayInMonthForFirstPromise}/>));

    renderPair(controls,
        "",
        "likeAGroupSortPaymentByThisField",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "selectedSortPaymentFieldVerif")} value={selectedProject
            .likeGroupView.selectedSortPaymentFieldVerif}
                         label={window.captions.SortPaymentByThisField}/>));

    //#endregion

    //#region likeAGroupOverdue
    controls.push(<div key="likeAGroupOverdue">{window.captions.Overdue}</div>);
    renderPair(controls,
        "",
        "likeAGroupOverdueMonthsCount",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "overdueMonthsCountVerif")} value={selectedProject
            .likeGroupView.overdueMonthsCountVerif}
                         label={window.captions.OverdueMonthsCount}/>));

    controls.push(<div key="likeAGroupAction">{window.captions.Action}</div>);
    renderPair(controls,
        "",
        "likeAGroupProjectConfigActionField",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "promisePaymentFieldInHistoryVerif")} value={
selectedProject.likeGroupView.promisePaymentFieldInHistoryVerif}
                         label={window.captions.ProjectConfigActionField}/>));
    renderPair(controls,
        "",
        "likeAGroupProjectConfigActionFieldFactor",
        () => (<Checkbox onChange={(e) => changeLikeAGroup(e, "maximumValuePaymentPromiseVerif")} value={selectedProject
            .likeGroupView.maximumValuePaymentPromiseVerif}
                         label={window.captions.ProjectConfigActionFieldFactor}/>));

    //#endregion

    return controls;
}

//#endregion

export function RenderLinkedData({ selectedProject, selectedItemsChanged }) {
    const controls = [];
    if (selectedProject.id > 0) {
        controls.push(<ItemSelector
                          items={selectedProject.linkedData}
                          selectedItemsChanged={selectedItemsChanged}
                          sourceTypeField="source"/>);
    }
    return controls;
}

export function RenderAttachments({ selectedProject, addAttachment, changeType, changeName, uploadAttachmentChange }) {
    const controls = [];
    controls.push(<div className="dm-wrapper">
        <div className="dm-wrapper-content">
            <Button style={{ margin: "0 0 5px 0" }} onClick={addAttachment}>{window.captions
                .AddFieldGroup}</Button>
        </div>
    </div>);
    const attachments =
        selectedProject.attachments.map((item, g) => {
            return [
                <div className="dm-wrapper">
                    <div className="dm-wrapper-content">
                        <DropDownTreeView
                            item={item.index}
                            treeData={selectedProject.attachmentsTypes}
                            selectedId={item.documentType}
                            selectionChanged={changeType} />
                        <Input key={`DocumentName${g}`}
                            item={item.index}
                            placeholder={window.captions.Caption}
                            onChange={changeName}
                            style={{ width: "100%" }}
                            value={item.name} />
                        {item.id > 0
                            ? <div className="dm-card-value">{item.fileType}</div>
                            : <Upload batch={false}
                                multiple={false}
                                defaultFiles={[]}
                                onStatusChange={uploadAttachmentChange}
                                onRemove={remove}
                                saveHeaders={headersFile()}
                                removeHeaders={headersFile()}
                                saveUrl={window.constants.fileUpload}
                                removeUrl={window.constants.fileRemove} />}
                    </div>
                </div>
            ];
        });

    controls.push(attachments);
    return controls;
}

export function RenderCaptions(selectedProject) {
    const controls = [];
    controls.push(<Input onChange={(e) => changeGeneralTabField(e, "titlePattern")}
                         value={selectedProject.titlePattern} />);

    for (let key in selectedProject.titleExampleList) {
        const item = selectedProject.titleExampleList[key];
        controls.push(<div key={`titleField${key}`} className="dm-label">
                          <div>{`{${item.value}}`} - {item.name}</div></div>);
    }

    return controls;
}