import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { fetchGet, fetchPost } from "../../../utils/requestsHelper.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import LoadingMask from "../../../components/loadingMask.jsx";
import ErrorMask from "../../../components/errorMask.jsx";
import {
    RenderProjectQuestionnaire,
    RenderProjectGeneralTab,
    RenderDescription,
    RenderLikeAGroup,
    RenderLinkedData,
    RenderAttachments,
    RenderCaptions
} from "./renderProject.jsx";
import {
    RenderProjectGroupGeneralTab
} from "./renderProjectGroup.jsx";
import {
    RenderProjectsListTreeView,
    RenderFieldsNeededTab,
    RenderVerificationFieldsNeededTab,
    RenderStagesAndActionTab,
    RenderTasksTab,
    RenderCardViewTab,
    RenderSearchViewTab,
    RenderProcessesTab,
    RenderFieldsTab
    } from "./renderCommonControls.jsx"


export default function AdminProjects() {
    const navigate = useNavigate();
    const params = useParams();
    const dragClue = useRef(null);

    const [data, setData] = useState([]);
    const [selectedProject, setSelected] = useState({});
    const [groupSelectedTab, setGroupSelectedTab] = useState({});
    const [projectSelectedTab, setProjectSelectedTab] = useState({});
    const [fieldsNeededSelectTab, setFieldsNeededSelectTab] = useState({});
    const [questionnaireSelectTab, setQuestionnaireSelectTab] = useState({});
    const [verificationNeededSelectTab, setVerificationNeededSelectTab] = useState({});
    const [cardViewTab, setCardViewTab] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [pending, setPending] = useState({});
    const [error, setError] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    const isMobile = useSelector(state => state.header.isMobile);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.ConfigProject}`;
    }, []);

    useEffect(() => fetchData(), []);

    if (data.accessError)
        return <ErrorMask error={data.accessError} />;

    //if (data.needReload) {
    //    data.needReload = false;
    //    this.props.loadProjects();
    //    return <LoadingMask />;
    //}

    let projectTemplate = [];
    if (selectedProject.id > 0 && !selectedProject.groupId) {
        projectTemplate = renderGroups();
    }
    else if (selectedProject.id > 0 && selectedProject.groupId > 0) {
        projectTemplate = renderProjects();
    }

    const treeProps = { className: "dm-size-33" };
    treeProps.style = { paddingRight: "16px" };
    if (selectedProject.id > 0)
        return projectTemplate;

    const filterGroups = <RenderProjectsListTreeView
        items={data.groups}
        onItemClick={onItemClick}
        onExpandChange={onExpandChange}
        onItemDragOver={onItemDragOver}
        onItemDragEnd={onItemDragEnd}
        dragClue={dragClue}
        removeStageView={removeStageView} />;

    return <div className="dm-full-height">
               <h1 className="dm-title">{window.captions.ConfigProject}</h1>
               <div className="dm-under-title-content">
                   <Button style={{ margin: "0 0 10px 20px" }} onClick={removeStageView}>{window.captions.Save
                   }</Button>
                   <Button style={{ margin: "0 0 10px 10px" }} onClick={removeStageView}>{window.captions.Delete
                   }</Button>
                   <div id="wikiWrapper" className="dm-container">
                       <div {...treeProps}>
                           {filterGroups}
                       </div>
                   </div>
               </div>
           </div>;

    function onItemDragEnd(event) {

    }

    function onItemDragOver(event) {
        if (!dragClue.current)
            return;

        dragClue.current.show(
            event.pageY + 10,
            event.pageX,
            event.item.text,
            getClueClassName(event)
        );
    }

    function getClueClassName(event) {
    }

    function fetchData() {
        setPending(window.captions.LoadingData);
        fetchGet(`${window.constants.getAdminProjects}`,
            (data) => {
                setData(data);
                setPending(null);
                setIsLoaded(true);
            },
            ex => setError(ex)
        );
    }

    //#region common render

    //#region documents helpers

    function remove(e) {
        const removeFiles = e.affectedFiles;
        for (let key in removeFiles) {
            const file = removeFiles[key];
            selectedProject.background = null;
        }
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function uploadStatusChange(e) {
        if (!e.response)
            return;

        var files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;
            selectedProject.background = file.fileName;
        }
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    //#endregion

    //#region renderHelpers

    function changeGeneralTabField(event, nameField) {
        selectedProject[nameField] = event.target.value;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function renderMenuItems() {
        return [
            {
                id: 0,
                text: window.captions.AddGroup,
                icon: "add"
            }, {
                id: -1,
                text: window.captions.Save,
                icon: "save"
            }
        ];
    }

    function onItemClick(event) {
        if (selectedProject)
            selectedProject.selected = false;

        event.item.selected = true;
        setSelected(event.item);
        setProjectSelectedTab(0);
        setGroupSelectedTab(0);
    };


    function onExpandChange(event) {
        event.item.expanded = !event.item.expanded;
    };


    function checkRecursive(parent) {
        for (let key in parent.items) {
            let child = parent.items[key];
            child.checked = parent.checked;

            if (child.items.length > 0)
                checkRecursive(child);
        }
        return parent;
    }

    function getParent(items, parentId) {
        let parent = items.filter(i => i.id === parentId);

        if (parent.length === 0) {
            for (let key in items) {
                const child = items[key];
                parent = getParent(child.items, parentId);
                if (parent.length > 0 || parent.id > 0)
                    return parent;
            }
        }

        return parent;
    }

    function onCheckChangeTreeView(event, arrayName) { //process and stages and todo: will do check checkIndeterminate
        event.item.checked = !event.item.checked;

        if (event.item.items.length > 0) {
            checkRecursive(event.item);
        }
        if (event.item.parentId === 0) {
            event.item.checkIndeterminate = event.item.checked;
            setSelected(selectedProject);
        }

        const parent = getParent(selectedProject[arrayName], event.item.parentId);
        if (parent.length === 0) {
            setSelected(selectedProject);
        }

        const isAllCheck = parent[0].items.filter(x => x.checked);
        if (isAllCheck.length === parent[0].items.length) {
            parent[0].checked = true;
            parent[0].checkIndeterminate = false;
        } else {
            if (isAllCheck.length !== 0) {
                parent[0].checked = false;
                parent[0].checkIndeterminate = true;
            } else {
                parent[0].checked = false;
                parent[0].checkIndeterminate = false;
            }
        }
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    //#endregion

    //#region render project fields
    
    function setEntityFields(fields) {
        selectedProject.fields = fields;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    //#endregion

    //#region render search view
    
    function removeView() {
        const newViews =
            selectedProject.searchView.searchViews.filter((x) => x.id !== selectedProject.searchView.searchView.id);
        selectedProject.searchView.searchViews = newViews;
        selectedProject.searchView.searchView = selectedProject.searchView.searchViews[0];
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function copyView(e) {
        selectedProject.searchView.searchView.fields = e.item.fields;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function changedView(e) {
        selectedProject.searchView.searchView = e.value;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function addSearchView(e) {
        e.item.fields = selectedProject.searchViewDefaultFields;
        selectedProject.searchView.searchViews.push(e.item);
        selectedProject.searchView.searchView = e.item;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    //#endregion

    //#region render card view

    function addStageView(e) {
        const newStage = {
            fields: [],
            id: e.item.id,
            name: e.item.name,
            tabs: []
        };
        if (!selectedProject.cardView.cardView.view) {
            selectedProject.cardView.cardView.view = [];
            selectedProject.cardView.cardView.view.cardViews = [];
        }

        selectedProject.cardView.cardView.view.cardViews.push(newStage);
        selectedProject.cardView.cardView.view.cardView = newStage;

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function addRuleIn(e) {
        selectedProject.cardView.cardView.selectedRules.push(e.item.id);
        let newNames = "";
        for (let key in selectedProject.cardView.rules) {
            var rule = selectedProject.cardView.rules[key];
            if (selectedProject.cardView.cardView.selectedRules.find(x => x === rule.id)) {
                if (newNames.length > 0)
                    newNames += " + ";

                newNames += rule.name;
            }
        }
        selectedProject.cardView.cardView.name = newNames;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function addRule(e) {
        const newRule = {
            id: 0,
            name: e.item.name,
            selectedRules: [e.item.id],
            stages: [],
            view: null
        };
        selectedProject.cardView.cardViews.push(newRule);
        selectedProject.cardView.cardView = newRule;

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function addTab(e) {
        const newTab = {
            content: {
                fields: [...selectedProject.cardView.fields],
                fieldsSelected: [],
                globalFields: selectedProject.cardView.globalFields,
                name: window.captions.NewTab,
                selectLangItem: {},
                sourceTypes: selectedProject.cardView.sourceTypes
            },
            headerText: window.captions.NewTab
        };
        selectedProject.cardView.cardView.view.cardView.tabs.push(newTab);

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function changedCardView(e) {
        selectedProject.cardView.cardView = e.value;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
        setCardViewTab(0);
    }

    function changedStageCardView(e) {
        selectedProject.cardView.cardView.view.cardView = e.value;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
        setCardViewTab(0);
    }

    function removeStageView(e) {
        const newArray =
            selectedProject.cardView.cardView.view.cardViews.filter(
                x => x.id !== selectedProject.cardView.cardView.view.cardView.id);
        selectedProject.cardView.cardView.view.cardViews = newArray;
        selectedProject.cardView.cardView.view.cardView = {};
        if (newArray.length > 0)
            selectedProject.cardView.cardView.view.cardView = newArray[0];

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function removeRuleView(e) {
        if (selectedProject.cardView.cardView.selectedRules.length > 1) {
            const newSelectedRules = selectedProject.cardView.cardView.selectedRules.filter(x => x !== e.item.id);
            let newNames = "";
            for (let key in selectedProject.cardView.rules) {
                var rule = selectedProject.cardView.rules[key];
                if (newSelectedRules.find(x => x === rule.id)) {
                    if (newNames.length > 0)
                        newNames += " + ";

                    newNames += rule.name;
                }
            }
            selectedProject.cardView.cardView.selectedRules = newSelectedRules;
            selectedProject.cardView.cardView.name = newNames;

            selectedProject.isUpdated = true;
            setSelected(selectedProject);
            return;
        }

        const newArray = selectedProject.cardView.cardViews.filter(x => x !== selectedProject.cardView.cardView);
        selectedProject.cardView.cardViews = newArray;
        selectedProject.cardView.cardView = {};
        if (newArray.length > 0)
            selectedProject.cardView.cardView = newArray[0];

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    //#endregion

    //#endregion

    //#region Save

    function saveProjects() {

    }

    function saveProjectGroups() {

    }

    function save() {
        const saveData = data.filter(x => x.isUpdated);
        saveData.accessError = "";
        fetchPost(`${window.constants.saveAdminProjects}`,
            saveData,
            (id) => { },
            () => { });
    }

    //#endregion

    //#region render project groups

    //#region event setters generalTab

    function changeLikeAGroup(event, nameField) {
        selectedProject.likeGroupView[nameField] = event.target.value;
        selectedProject.isUpdated = true;
        setSelected(selectedProject);
    }

    function uploadAttachmentChange(e) {
        if (!e.response)
            return;

        const attachment = selectedProject.attachments.find(x => x.index === e.item.index);

        const files = e.response.response;
        for (let index in files) {
            const file = files[index];
            if (!file || !file.fileName)
                continue;

            e.affectedFiles[index].fileName = file.fileName;

            attachment.fileName = file.fileName;
            attachment.name = file.name;
            attachment.size = file.size;

            attachments[e.item.index] = attachment;
        }

        selectedProject.isUpdated = true;
        setSelected(selectedProject);
        setAttachments({ attachments });
    }

    function addAttachment() {
        const attachments = selectedProject.attachments;
        const attachment = {
            id: 0,
            index: attachments.length,
            documentType: selectedProject.defaultDocument,
            fileName: "",
            name: window.captions.NewDocument,
            size: 0
        };

        attachments.push(attachment);
        setSelected(selectedProject);
        setAttachments({ attachments });
    }

    function addGroupingPayment() {
        const newModel = {
            fieldId: 0,
            fields: selectedProject.paymentFields,
            isAsc: false
        };
        selectedProject.paymentGrouping.push(newModel);
        setSelected({ selectedProject });
    }

    function changeLimit(value, limit, prop) {}
    function onSearchKey(e) {}

    function handleGroupSelectTab(e) {
        setGroupSelectedTab(e.selected);
    }
    function handleProjectSelectTab(e) {
        setProjectSelectedTab(e.selected);
    }

    function handleCardViewSelectTab(e) {
        setCardViewTab(e.selected);
    }

    function handleFieldsNeededSelectTab(e) {
        setFieldsNeededSelectTab(e.selected);
    }

    function handleQuestionnaireSelectTab(e) {
        setQuestionnaireSelectTab(e.selected);
    }

    function handleVerificationNeededSelectTab(e) {
        setVerificationNeededSelectTab(e.selected);
    }

    function selectedItemsChanged(e) {

    }

    function changedName(e) {

    }

    function changeName(e) {

    }

    function changeType(e) {

    }
    //#endregion

    function renderGroups() {
        if (selectedProject.id === 0) {
            return null;
        }
        let filterGroups = <RenderProjectsListTreeView
                               items={data.groups}
                               onItemClick={onItemClick}
                               onExpandChange={onExpandChange}
                               onItemDragOver={onItemDragOver}
                               onItemDragEnd={onItemDragEnd}
                               dragClue={dragClue}
                               removeStageView={removeStageView}/>;
        let generalTab = [];
        let fieldsNeeded = [];
        let verificationFields = [];
        let stagesAndActions = [];
        let tasksNeeded = [];
        let cardView = [];
        let searchView = [];
        let processes = [];
        let fields = [];

        if (data.groupSelectedTab === 0) {
            generalTab = <RenderProjectGroupGeneralTab selectedProject={selectedProject}
                                                       changeGeneralTabField={changeGeneralTabField}
                                                       onSearchKey={onSearchKey}
                                                       addGroupingPayment={addGroupingPayment}
                                                       uploadStatusChange={uploadStatusChange}
                                                       remove={remove}/>;
        }
        if (data.groupSelectedTab === 1) {
            fieldsNeeded = <RenderFieldsNeededTab
                               selectedProject={selectedProject}
                               selectedItemsChanged={selectedItemsChanged}/>;
        }
        if (data.groupSelectedTab === 2) {
            verificationFields = <RenderVerificationFieldsNeededTab selectedProject={selectedProject}
                                                                    verificationNeededSelectTab={verificationNeededSelectTab} selectedItemsChanged={selectedItemsChanged}/>;
        }
        if (data.groupSelectedTab === 3) {
            stagesAndActions = <RenderStagesAndActionTab selectedProject={selectedProject}
                                                         onExpandChange={onExpandChange}
                                                         onCheckChangeTreeView={onCheckChangeTreeView}/>;
        }
        if (data.groupSelectedTab === 4) {
            tasksNeeded = <RenderTasksTab selectedProject={selectedProject}
                                          onExpandChange={onExpandChange}
                                          onCheckChangeTreeView={onCheckChangeTreeView}/>;
        }
        if (data.groupSelectedTab === 5) {
            cardView = <RenderCardViewTab selectedProject={selectedProject}
                                          handleCardViewSelectTab={handleCardViewSelectTab}
                                          changedName={changedName}
                                          selectedItemsChanged={selectedItemsChanged}
                                          addRule={addRule}
                                          changedCardView={changedCardView}
                                          addRuleIn={addRuleIn}
                                          removeRuleView={removeRuleView}
                                          addStageView={addStageView} />;
        }
        if (data.groupSelectedTab === 6) {
            searchView = <RenderSearchViewTab selectedProject={selectedProject}
                                              addSearchView={addSearchView}
                                              changedView={changedView}
                                              removeView={removeView}
                                              copyView={copyView}/>;
        }
        if (data.groupSelectedTab === 7) {
            processes = <RenderProcessesTab selectedProject={selectedProject}
                                            onExpandChange={onExpandChange}
                                            onCheckChangeTreeView={onCheckChangeTreeView}/>;
        }
        if (data.groupSelectedTab === 8) {
            fields =
                <RenderFieldsTab selectedProject={selectedProject} isEdit={data.isEdit} setEntityFields={setEntityFields}/>;
        }

        var treeProps = { className: "dm-size-33" };
        treeProps.style = { paddingRight: "16px" };
        return <div className="dm-full-height">
                   <h1 className="dm-title">{window.captions.Project}</h1>
            <div className="dm-under-title-content">
                <Button style={{ margin: "0 0 10px 20px" }} onClick={save}>{window.captions.Save
                }</Button>
                <Button style={{ margin: "0 0 10px 10px" }} onClick={removeStageView}>{window.captions
                    .Delete
                }</Button>
                       <div id="wikiWrapper" className="dm-container dm-wrapper">
                           <div {...treeProps}>
                               {filterGroups}
                           </div>
                           <div className="dm-size-66">
                               <TabStrip selected={groupSelectedTab} onSelect={handleGroupSelectTab} scrollable={true}>
                                   <TabStripTab title={window.captions.General}>
                                       <div className="dm-container dm-no-padding">
                                           {generalTab}
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.FeeldsNeeded} style={treeProps}>
                                       <TabStrip selected={selectedProject.fieldsNeededSelectTab} onSelect={handleFieldsNeededSelectTab} scrollable={true}>
                                           <TabStripTab title={window.captions.ForClient}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForLoan}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForPayments}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForPaymentPlan}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={"ForCollateral"}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForOwners}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForLinked}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.CalculatedLoanClient}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForAccounts}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                       </TabStrip>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.FieldNeedVerification}>
                                       <TabStrip selected={selectedProject.verificationNeededSelectTab} onSelect={handleVerificationNeededSelectTab} scrollable={true}>
                                           <TabStripTab title={window.captions.Clients}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Loans}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Collaterals}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Process}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ProcessAction}>
                                               {verificationFields}
                                           </TabStripTab>
                                       </TabStrip>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.StagesAndActionsNeeded}>
                                       <div className="dm-wrapper">
                                           <div className="dm-wrapper-content">
                                               {stagesAndActions}
                                           </div>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.TasksNeeded}>
                                       {tasksNeeded}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.CardView}>
                                       {cardView}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.SearchView}>

                                       {searchView}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Process}>
                                       <div className="dm-wrapper">
                                           <div className="dm-wrapper-content">
                                               {processes}
                                           </div>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Fields}>
                                       <div className="dm-container dm-no-padding">
                                           {fields}
                                       </div>
                                   </TabStripTab>
                               </TabStrip>
                           </div>
                       </div>
                   </div>
               </div>;
    }

    //#endregion
    
    function renderProjects() {
        if (selectedProject.id === 0) {
            return null;
        }

        let filterGroups = <RenderProjectsListTreeView
                               items={data.groups}
                               onItemClick={onItemClick}
                               onExpandChange={onExpandChange}
                               onItemDragOver={onItemDragOver}
                               onItemDragEnd={onItemDragEnd}
                               dragClue={dragClue}
                               removeStageView={removeStageView} />;
        let general = [];
        let description = [];
        let likeGroup = [];
        let fieldsNeeded = [];
        let verificationFields = [];
        let stagesAndActions = [];
        let tasksNeeded = [];
        let cardView = [];
        let searchView = [];
        let processes = [];
        let linkedData = [];
        let documents = [];
        let fields = [];
        let questionnaire = [];
        let titleCaptions = [];

        if (projectSelectedTab === 0) {
            general =
                <RenderProjectGeneralTab selectedProject={selectedProject} changeGeneralTabField={changeGeneralTabField}
                    changeLimit={changeLimit} onSearchKey={onSearchKey} addGroupingPayment={addGroupingPayment}
                    uploadStatusChange={uploadStatusChange} remove={remove}/>;
        }
        if (projectSelectedTab === 1) {
            description =
                <RenderDescription selectedProject={selectedProject} changeGeneralTabField={changeGeneralTabField}/>;
        }
        if (projectSelectedTab === 2) {
            likeGroup = <RenderLikeAGroup selectedProject={selectedProject} changeLikeAGroup={changeLikeAGroup}/>;
        }
        if (projectSelectedTab === 3) {
            fieldsNeeded = <RenderFieldsNeededTab
                               selectedProject={selectedProject}
                               selectedItemsChanged={selectedItemsChanged} />;
        }
        if (projectSelectedTab === 4) {
            verificationFields = <RenderVerificationFieldsNeededTab selectedProject={selectedProject}
                                                                    verificationNeededSelectTab={verificationNeededSelectTab} selectedItemsChanged={selectedItemsChanged}/>;
        }
        if (projectSelectedTab === 5) {
            stagesAndActions = <RenderStagesAndActionTab selectedProject={selectedProject}
                                                         onExpandChange={onExpandChange}
                                                         onCheckChangeTreeView={onCheckChangeTreeView} />;
        }
        if (projectSelectedTab === 6) {
            tasksNeeded = <RenderTasksTab selectedProject={selectedProject}
                                          onExpandChange={onExpandChange}
                                          onCheckChangeTreeView={onCheckChangeTreeView} />;
        }
        if (projectSelectedTab === 7) {
            cardView = <RenderCardViewTab selectedProject={selectedProject}
                                          handleCardViewSelectTab={handleCardViewSelectTab}
                                          changedName={changedName}
                                          selectedItemsChanged={selectedItemsChanged}
                                          addRule={addRule}
                                          changedCardView={changedCardView}
                                          addRuleIn={addRuleIn}
                                          removeRuleView={removeRuleView}
                                          addStageView={addStageView}/>;
        }
        if (projectSelectedTab === 8) {
            searchView = <RenderSearchViewTab selectedProject={selectedProject}
                                              addSearchView={addSearchView}
                                              changedView={changedView}
                                              removeView={removeView}
                                              copyView={copyView} />;
        }
        if (projectSelectedTab === 9) {
            processes = <RenderProcessesTab selectedProject={selectedProject}
                                            onExpandChange={onExpandChange}
                                            onCheckChangeTreeView={onCheckChangeTreeView} />;
        }
        if (projectSelectedTab === 10) {
            linkedData =
                <RenderLinkedData selectedProject={selectedProject} selectedItemsChanged={selectedItemsChanged}/>;
        }
        if (projectSelectedTab === 11) {
            documents =
                <RenderAttachments selectedProject={selectedProject} addAttachment={addAttachment} changeType={
changeType} changeName={changeName} uploadAttachmentChange={uploadAttachmentChange}/>;
        }
        if (projectSelectedTab === 12) {
            fields = <RenderFieldsTab selectedProject={selectedProject} isEdit={data.isEdit} setEntityFields={setEntityFields} />;
        }
        if (projectSelectedTab === 13) {
            questionnaire =
                <RenderProjectQuestionnaire selectedProject={selectedProject} changeGeneralTabField={changeGeneralTabField} selectedItemsChanged={selectedItemsChanged}/>;
        }
        if (projectSelectedTab === 18) {
            titleCaptions = <RenderCaptions/>;
        }

        var treeProps = { className: "dm-size-33" };
        treeProps.style = { paddingRight: "16px" };
        return <div className="dm-full-height">
                   <h1 className="dm-title">{window.captions.Project}</h1>
                   <div className="dm-under-title-content">
                       <Button style={{ margin: "0 0 10px 20px" }} onClick={removeStageView}>{window.captions.Save
                       }</Button>
                       <Button style={{ margin: "0 0 10px 10px" }} onClick={removeStageView}>{window.captions
                           .Delete
                       }</Button>
                       <div id="wikiWrapper" className="dm-container dm-wrapper">
                           <div {...treeProps}>
                               {filterGroups}
                           </div>
                           <div className="dm-size-66">
                               <TabStrip selected={projectSelectedTab} onSelect={handleProjectSelectTab} scrollable={true}>
                                   <TabStripTab title={window.captions.General}>
                                       <div className="dm-container dm-no-padding">
                                           {general}
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Description}>
                                       <div className="dm-container dm-no-padding">
                                           {description}
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.SameAsGroup}>
                                       {likeGroup}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.FeeldsNeeded} style={treeProps}>
                                       <TabStrip selected={fieldsNeededSelectTab} onSelect={handleFieldsNeededSelectTab} scrollable={true}>
                                           <TabStripTab title={window.captions.ForClient}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForLoan}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForPayments}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForPaymentPlan}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={"ForCollateral"}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForOwners}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForLinked}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.CalculatedLoanClient}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ForAccounts}>
                                               {fieldsNeeded}
                                           </TabStripTab>
                                       </TabStrip>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.FieldNeedVerification}>
                                       <TabStrip selected={verificationNeededSelectTab} onSelect={handleVerificationNeededSelectTab} scrollable={true}>
                                           <TabStripTab title={window.captions.Clients}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Loans}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Collaterals}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.Process}>
                                               {verificationFields}
                                           </TabStripTab>
                                           <TabStripTab title={window.captions.ProcessAction}>
                                               {verificationFields}
                                           </TabStripTab>
                                       </TabStrip>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.StagesAndActionsNeeded}>
                                       <div className="dm-wrapper">
                                           <div className="dm-wrapper-content">
                                               {stagesAndActions}
                                           </div>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.TasksNeeded}>
                                       {tasksNeeded}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.CardView}>
                                       {cardView}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.SearchView}>
                                       {searchView}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Process}>
                                       <div className="dm-wrapper">
                                           <div className="dm-wrapper-content">
                                               {processes}
                                           </div>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.LinkedData}>
                                       {linkedData}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Attachments}>
                                       {documents}
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Fields}>
                                       <div className="dm-container dm-no-padding">
                                           {fields}
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Questionnaire}>
                                       <TabStrip selected={selectedProject.questionnaireSelectTab} onSelect={handleQuestionnaireSelectTab}>
                                           {questionnaire}
                                       </TabStrip>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.IncomingCall}>
                                       <div className="dm-container">
                                           <ErrorMask style={{ margin: "5px" }} error="This section is under development"/>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.OutgoingCall}>
                                       <div className="dm-container">
                                           <ErrorMask style={{ margin: "5px" }} error="This section is under development"/>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Action}>
                                       <div className="dm-container">
                                           <ErrorMask style={{ margin: "5px" }} error="This section is under development"/>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Scenario}>
                                       <div className="dm-container">
                                           <ErrorMask style={{ margin: "5px" }} error="This section is under development"/>
                                       </div>
                                   </TabStripTab>
                                   <TabStripTab title={window.captions.Caption}>
                                       <div className="dm-container">
                                           {titleCaptions }
                                       </div>
                                   </TabStripTab>
                               </TabStrip>
                           </div>
                       </div>
                   </div>
               </div>;
    }
    
    //function render() {
    //    if (data.data.accessError)
    //        return <ErrorMask error={data.accessError}/>;

    //    if (pending)
    //        return <LoadingMask/>;

    //    if (data.needReload) {
    //        data.needReload = false;
    //        loadProjects();
    //        return <LoadingMask/>;
    //    }
       
    //    let projectTemplate = [];
    //    if (model.isSelected.id > 0 && !model.isSelected.groupId) {
    //        projectTemplate = this.renderGroups();
    //    }
    //    else if (model.isSelected.id > 0 && model.isSelected.groupId > 0) {
    //        projectTemplate = this.renderProjects();
    //    }

    //    const treeProps = { className: "dm-size-33" };
    //    treeProps.style = { paddingRight: "16px" };
    //    if (model.isSelected.id > 0)
    //        return projectTemplate;

    //    const filterGroups = renderProjectsListTreeView(model.data.groups, this);
    //    return <div className="dm-full-height">
    //               <h1 className="dm-title">{window.captions.ConfigProject}</h1>
    //               <div className="dm-under-title-content">
    //            <Button style={{ margin: "0 0 10px 20px" }} onClick={this.removeStageView}>{window.captions.Save}</Button>
    //                   <Button style={{ margin: "0 0 10px 10px" }} onClick={this.removeStageView}>{window.captions.Delete}</Button>
    //                   <div id="wikiWrapper" className="dm-container">
    //                       <div {...treeProps}>
    //                           {filterGroups}
    //                       </div>
    //                   </div>
    //               </div>
    //           </div>;
    //}
}
