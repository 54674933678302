import React, { useState } from "react";
import { useSelector } from "react-redux";
import { renderPair } from "../../../../cards/cardRenderFunctions.jsx";
import MultilineTextArea from "../../../../../components/controls/multilineTextArea.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { Upload } from "@progress/kendo-react-upload";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import FileHelper from "../../../../../utils/fileHelper.jsx";
import { headersFile } from "../../../../../utils/requestsHelper.jsx";

export default function PersonalDataTab({ dataUser, userChanged }) {
    const [preview, setPreview] = useState(null);
    const user = useSelector(state => state.header.model);

    const elements = [];

    renderPair(elements, window.captions.Photo, "photo", () => (
        <div id="userPhoto">
            {!dataUser.photo ? (
                <div style={{ marginBottom: "10px" }} >
                    <Upload
                        batch={false}
                        multiple={false}
                        restrictions={{ allowedExtensions: [".jpg", ".png"] }}
                        defaultFiles={[]}
                        onStatusChange={(e) => documentUpload(e)}
                        saveHeaders={headersFile()}
                        removeHeaders={headersFile()}
                        saveUrl={window.constants.fileUpload}
                        removeUrl={window.constants.fileRemove}
                    />
                </div>
            ) : (
                <React.Fragment>
                    <Button
                        key="ViewUserPhoto"
                        icon="eye"
                        onClick={() => viewUserPhoto(dataUser.photo)}
                    />
                    <Button
                        key="DeleteUserPhoto"
                        icon="delete"
                        onClick={deleteUserPhoto}
                    />
                </React.Fragment>
            )}
        </div>
    ));

    renderPair(elements, window.captions.WorkAddress, "workAddress", () => (
        <MultilineTextArea
            id="workAddressUser"
            changeText={workAddressChanged}
            text={dataUser.workAddress}
        />
    ));

    renderPair(elements, window.captions.City, "city", () => (
        <MultilineTextArea
            id="cityUser"
            changeText={cityChanged}
            text={dataUser.city}
        />
    ));

    renderPair(elements, window.captions.PhoneMobile, "phoneMobile", () => (
        <MultilineTextArea
            id="phoneMobileUser"
            changeText={phoneMobileChanged}
            text={dataUser.phoneMobile}
        />
    ));

    renderPair(elements, window.captions.PhoneWork, "phoneWork", () => (
        <MultilineTextArea
            id="phoneWorkUser"
            changeText={phoneWorkChanged}
            text={dataUser.phoneWork}
        />
    ));

    renderPair(elements, window.captions.Phoneinternal, "phoneInternal", () => (
        <MultilineTextArea
            id="phoneInternalUser"
            changeText={phoneInternalChanged}
            text={dataUser.phoneInternal}
        />
    ));

    renderPair(elements, window.captions.Comments, "comments", () => (
        <MultilineTextArea
            id="commentsUser"
            changeText={commentsChanged}
            text={dataUser.comments}
        />
    ));

    let overContent = null;
    if (preview) {
        var filePath = `${window.constants.fileUserDocument}/${user.id}?fileName=${preview}`;

        overContent = <Dialog title={window.captions.Preview} onClose={() => setPreview(null)} width={1000}>
            <div className="dm-container">
                <img src={filePath} alt={window.captions.Photo} width="100%" />
            </div>
            <DialogActionsBar>
                <Button className="k-success-colored"
                    onClick={() => FileHelper.getUserDocument(preview)}>
                    {window.captions.Download}
                </Button>
                <Button themeColor="primary"
                    onClick={() => setPreview(null)}>
                    {window.captions.Close}
                </Button>
            </DialogActionsBar>
        </Dialog>;
    }

    return <div className="dm-container dm-no-padding">
        {elements}
        {overContent}
    </div>;

    function documentUpload(e) {
        if (!e.response)
            return;

        //if (e.response.response.length === 1) {
        //    setTitleImage(e.response.response[0].name);
        //}
        //userChanged({ ...dataUser, workAddress: e.value });
    }

    function viewUserPhoto(fileName) {
        setPreview(fileName);
    };

    function deleteUserPhoto(e) {
        userChanged({ ...dataUser, photo: '' });
    };

    function documentRemove(document) {
        const documents = dataUser.documents;
        if (!document)
            return;

        if (!confirm(window.captions.DeleteItemConfirm))
            return;

        if (document.id > 0)
            dataUser.removedDocuments.push(document.id);

        documents.splice(document.index, 1);
        documentsReindex();

        if (userChanged)
            userChanged(dataUser);
    }

    function workAddressChanged(e) {
        userChanged({ ...dataUser, workAddress: e.value });
    };

    function cityChanged(e) {
        userChanged({ ...dataUser, city: e.value });
    };

    function phoneMobileChanged(e) {
        userChanged({ ...dataUser, phoneMobile: e.value });
    };

    function phoneWorkChanged(e) {
        userChanged({ ...dataUser, phoneWork: e.value });
    };

    function phoneInternalChanged(e) {
        userChanged({ ...dataUser, phoneInternal: e.value });
    };

    function commentsChanged(e) {
        if (userChanged)
            userChanged({ ...dataUser, comments: e.value });
    };
}