import React, { useState, useEffect } from 'react';
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { TreeList, mapTree, extendDataItem, TreeListBooleanEditor } from "@progress/kendo-react-treelist";

const subItemsField = "items";
const expandField = "expanded";
const editField = "inEdit";

export default function RuleAccessClients({ dataRule, clientCardTypes }) {
    const columns = [
        { field: "name", title: " ", width: "250px", expandable: true },
        { field: "view", title: window.captions.View, width: "100px", editCell: TreeListBooleanEditor },
        { field: "editAddons", title: window.captions.AddonFieldsEdit, width: "100px", editCell: TreeListBooleanEditor },
        { field: "editFull", title: window.captions.FullFieldsEdit, width: "100px", editCell: TreeListBooleanEditor },
        { field: "historyView", title: window.captions.HistoryView, width: "100px", editCell: TreeListBooleanEditor },
        { field: "historyAdd", title: "DynamicLocalization.AddingActions", width: "100px", editCell: TreeListBooleanEditor },
        { field: "stateSetting", title: window.captions.ChangeToThisState, width: "100px", editCell: TreeListBooleanEditor },
        { field: "stateChanging", title: window.captions.ChangeToOtherState, width: "100px", editCell: TreeListBooleanEditor },
        { field: "fullAccess", title: window.captions.FullAccess, width: "100px", editCell: TreeListBooleanEditor },
        { field: "fieldVerification", title: window.captions.FieldNeedVerification, width: "100px", editCell: TreeListBooleanEditor },
        { field: "callRecords", title: window.captions.CallRecords, width: "100px", editCell: TreeListBooleanEditor },
        { title: "Action", cell: cellWithEditing, width: "62px", locked: true }
    ];

    const [items, setItems] = useState([]);
    const [addableItems, setAddableItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [editId, setEditId] = useState(null);

    useEffect(() => mountProperties(), [dataRule.id, clientCardTypes]);

    return <div className="dm-container dm-no-padding">
        <TreeList
            style={{
                maxHeight: "510px",
                overflow: "auto",
                width: "inherit",
            }}
            editField={editField}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={onExpandChange}
            data={mapTree(items, subItemsField, (item) =>
                extendDataItem(item, subItemsField, {
                    [expandField]: expanded.includes(item.id),
                    [editField]: item.id === editId,
                })
            )}
            columns={columns}
            onRowClick={rowClick}
            onItemChange={onItemChange}
        />
    </div>;

    function mountProperties() {
        if (!dataRule || !clientCardTypes) {
            setItems([]);
            setAddableItems([]);
            return;
        }

        let clientCardTypesList = Array.isArray(clientCardTypes) && clientCardTypes.length ? clientCardTypes : [];
        const globalAccess = dataRule.clientAccessGlobal;
        let general = {
            id: 0,
            name: window.captions.RuleConfigAccessGeneral,
            ...globalAccess,
            fullAccess: globalAccess.view && globalAccess.editAddons && globalAccess.editFull && globalAccess.historyView && globalAccess.historyAdd && globalAccess.stateSetting && globalAccess.stateChanging && globalAccess.stateChanging,
        };

        let data = [general];
        let addable = [];
        clientCardTypesList.forEach(i => {
            let access = dataRule.accessByClientsTypes.find(x => i.id == x.id);
            if (access) {
                access = access.access;
                data.push({
                    ...i,
                    ...access.access,
                    fullAccess: access.view && access.editAddons && access.editFull
                        && access.historyView && access.historyAdd && access.stateSetting
                        && access.stateChanging && access.stateChanging,
                });
            }
            else {
                addable.push({
                    ...i,
                    view: false,
                    editAddons: false,
                    editFull: false,
                    historyView: false,
                    historyAdd: false,
                    stateSetting: false,
                    stateChanging: false,
                    fieldVerification: false,
                    callRecords: false,
                    fullAccess: false,
                });
            }
        });

        setItems(data);
        setAddableItems(addable);
    }

    function cellWithEditing(props) {
        const { dataItem } = props;
        if (dataItem.id == 0)
            return <td
                className={props.className}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                data-grid-col-index={props.colIndex}
                style={props.style}
            >
                <DropDownButton
                    onItemClick={(e) => addClientType(e)}
                    popupSettings={{ animate: false }}
                    icon="add"
                    items={addableItems}
                    textField="name"
                />
            </td>;

        return <td
            className={props.className}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            data-grid-col-index={props.colIndex}
            style={props.style}
        >
            <Button
                icon="close"
                onClick={() => removeItem(dataItem)}
            />
        </td>;
    }

    function addClientType(e) {
        let index = addableItems.findIndex(i => i.id == e.item.id);
        if (index == -1)
            return;

        var newItems = [...addableItems];
        newItems.splice(index, 1);
        setItems([...items, e.item]);
        setAddableItems(newItems);
    }

    function removeItem(item) {
        let index = items.findIndex(i => i.id == item.id);
        if (index == -1)
            return;

        var newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        setAddableItems([...addableItems, item])
    }

    function onExpandChange(e) {
        setExpanded(e.value
            ? expanded.filter((id) => id !== e.dataItem.id)
            : [...expanded, e.dataItem.id]);
    }

    function rowClick(event) {
        setEditId(editId === event.dataItem.id ? null : event.dataItem.id);
    }

    function onItemChange(event) {
        const field = event.field;
        setItems(mapTree(items, subItemsField, (item) => {
            if (item.id === event.dataItem.id) {
                const result = extendDataItem(item, subItemsField, {
                    [field]: event.value,
                });
                if (field == "fullAccess") {
                    result.view = result.fullAccess;
                    result.editAddons = result.fullAccess;
                    result.editFull = result.fullAccess;
                    result.historyView = result.fullAccess;
                    result.historyAdd = result.fullAccess;
                    result.stateSetting = result.fullAccess;
                    result.stateChanging = result.fullAccess;
                } else {
                    result.fullAccess = result.view && result.editAddons && result.editFull && result.historyView && result.historyAdd && result.stateSetting && result.stateChanging && result.stateChanging;
                }

                return result;
            } else {
                return item;
            }
        }
        ));
    }
}