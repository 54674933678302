import React, { useState } from "react";
import LoadingMask from "../../../components/loadingMask.jsx";
import GridLoadingPanel from "../../../components/gridLoadingPanel.jsx";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { fetchPost } from '../../../utils/requestsHelper.jsx';
import GridTimeLoadCell from '../../../components/cells/gridTimeLoadCell.jsx';
import GridDateTimeCell from '../../../components/cells/gridDateTimeCell.jsx';
import { orderBy } from "@progress/kendo-data-query";
import { statGeneralTypeEnum } from "../../../utils/systemConstants.jsx";
import WizardFunctions from "../sharedUi/wizardFunctions.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export function StatisticTabControl({ template }) {
	const initialSort = [{
			field: "startLoad",
			dir: "desc"}
	];

	const [templateId, setTemplateId] = useState(null);
	const [statistics, setStatistics] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [sort, setSort] = React.useState(initialSort);
	if (!template)
		return null;

	if (templateId != template.id) {
		if (!loading) {
			var request = {
				type: template.type,
				id: template.id
			}
			statisticLoad(request);
		}
		else {
			return <div style={{ height: "300px" }}><LoadingMask /></div>;
		}
	}

	if (!statistics || statistics.length === 0) {
		return (
			<div style={{ height: "300px" }}>
				{(template.type === statGeneralTypeEnum.document)
					? WizardFunctions.firstCharUpperCase(window.captions.DocumentNoData)
					: WizardFunctions.firstCharUpperCase(window.captions.ReportNoData)}
			</div>
		);
	}

	const onCloseError = () => {
		setError(null);
	};

	if (error) {
		return <Dialog width={400}>
			<MessageMask inline error={error} />
			<DialogActionsBar>
				<Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
			</DialogActionsBar>
		</Dialog>;
	}

	let loadingPanel = null;
	if (loading) {
		loadingPanel = <GridLoadingPanel />;
	}

	return (
		<div className="dm-full-wh">
			<Grid className="dm-full-wh"
				  data={orderBy(statistics, sort)}
				  style={{ maxHeight: "700px" }}
				  sortable={true}
				  sort={sort}
				  onSortChange={(e) => {setSort(e.sort)}}>
				<GridColumn field="id" title={window.captions.Identifier} />
				<GridColumn field="author" title={window.captions.Author} />
				<GridColumn field="startLoad" title={window.captions.StartTime} cells={{ data: GridDateTimeCell }} />
				<GridColumn field="endLoad" title={window.captions.EndTime} cells={{ data: GridDateTimeCell }} />
				<GridColumn field="timeLoad.totalMilliseconds" title={window.captions.TimeLoad}
					cell={props => <GridTimeLoadCell {...props} childField="timeLoad" />}
					sortable={true} />
				<GridColumn field="linesCount" title={window.captions.LineCount} />
			</Grid>
			{loadingPanel}
		</div>
	);

	function statisticLoad(request) {
		setLoading(true);
		fetchPost(`${window.constants.getStatisticsWizardSettings}`,
			request,
			model => {
				if (model) {
					setStatistics(model.statistics);
					setTemplateId(model.id);
					setLoading(false);
				} else {
					showError(window.captions.LoadDataError);
				}

			},
			ex => showError(ex)
		);
	}

	function showError(ex) {
		setError(ex);
		setIsLoaded(true);
	}
}