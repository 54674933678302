import React, { useState, useEffect } from "react";
import ItemSelector from "../../../../../components/controls/itemSelector.jsx";

export default function ClientsInRegionTab({ dataUser, regionsList, userChanged }) {
    const [regions, setRegions] = useState([]);

    useEffect(() => mountProperties(), [dataUser.id, regionsList]);

    return <div className="dm-container dm-no-padding">
        <ItemSelector
            items={regions}
            selectedItemsChanged={selectedItemsChanged} />
    </div>;

    function mountProperties() {
        if (!dataUser) {
            setRegions([]);
            return;
        }

        let regionsListLocal = Array.isArray(regionsList) && regionsList.length ? regionsList : [];
        let items = regionsListLocal.map((item) => {
            const elementIndex = dataUser.regions.findIndex((i) => i == item.id);
            const index = elementIndex == -1 ? null : elementIndex;
            const isSelected = elementIndex != -1;
            return {
                id: item.id,
                name: item.name,
                isSelected: isSelected,
                index: index,
            };
        });

        setRegions(items);
    }

    function selectedItemsChanged(selectedItems) {
        if (userChanged)
            userChanged({
                ...dataUser,
                regions: selectedItems ? selectedItems.map(i => i.id) : [],
                isUpdated: true
            });
    }
}