import React, { useState, useEffect } from 'react';
import { statGeneralTypeEnum } from "../../../utils/systemConstants.jsx";
import { fetchGet, fetchPost } from '../../../utils/requestsHelper.jsx';
import LoadingMask from "../../../components/loadingMask.jsx";
import { WizardComponent } from "../sharedUi/wizardUi.jsx";
import { ItemsTreeView } from "../sharedUi/controls/itemsTreeView.jsx";
import { Button } from "@progress/kendo-react-buttons";
import { fileReportIcon, folderIcon, folderAddIcon, fileAddIcon } from "@progress/kendo-svg-icons";
import { useSelector } from 'react-redux';
import WizardFunctions from '../sharedUi/wizardFunctions.jsx';
import MessageMask from "../../../components/messageMask.jsx";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useBeforeUnload } from "react-router-dom";
import ErrorMask from "../../../components/emptyMask.jsx";

export default function AdminReportsSettings() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [remowedItemsFolders, setRemowedItemsFolders] = useState([]);
    const [remowedList, setRemowedList] = useState([]);
    const [editList, setEditList] = useState([]);
    const [title, setTitle] = useState(null);
    const [error, setError] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const access = useSelector(state => state.header.model.access);
    const user = useSelector(state => state.header.model);

    useEffect(() => {
        document.title = `Delta M. Crm. ${window.captions.MainTabStatisticReports}`;
        reportSettingsLoad();
        getTitle();
    }, []);

    useBeforeUnload((event) => {
        if (isEdited) {
            event.preventDefault();
            event.returnValue = "";
        }
    });

    if (!access.adminReports)
        return <ErrorMask error={window.captions.AccessDenied} />;

    const handleSelectTemplate = (template) => {
        setSelectedTemplate(null);

        if (template.item.id === 0) {
            setSelectedTemplate(template);
        }
        else {
            if (template.item.isLoaded)
                setSelectedTemplate(template);
            else
                selectedTemplateLoad(template);
        }

        getTitle(template.item.name);
    };

    const addFolderHandler = () => {
        addFolderItem(templates);
    };

    const addItemHandler = (request) => {
        addItem(request);
        getTitle(window.captions.MainTabStatisticReports);
    };

    const copyHandler = () => {
        if (!selectedTemplate)
            return;

        var parent = WizardFunctions.findItemById(templates, selectedTemplate.item.id, true);

        var reportNames = [];
        reportNames.push({ id: -1, name: selectedTemplate.item.name });
        parent.items.forEach(item => reportNames.push({ id: reportNames.length, name: item.name }));

        createCopy(reportNames, parent);
    }

    const deleteItemHandler = (item) => {
        if (!selectedTemplate)
            return;
        var newRemowedList = remowedList;

        if ("id" in item) {
            if (!remowedList.find((removedItem) => removedItem.id === item.id))
                newRemowedList.push(item);
            setRemowedList(newRemowedList);
            return;
        }

        if (selectedTemplate && !confirm(window.captions.DeleteItemConfirm))
            return;

        var deletedItemRequest = WizardFunctions.createDeletedModel(selectedTemplate.item.id, 0)
        deletedItemRequest.type = selectedTemplate.item.type;

        getAllDeletedIdList(deletedItemRequest, selectedTemplate.item);

        setSelectedTemplate(null);
        setIsEdited(true);

        getTitle(window.captions.MainTabStatisticReports);
    };

    const handleEdit = (model) => {
        getTitle(model.name);

        if (!editList.includes(model.id)) {
            var newEditList = editList;
            newEditList.push(model.id);
            setEditList(newEditList);
        }
        model.isEdited = true;

        setIsEdited(true);
        updateItem(model, selectedTemplate.index);
    };


    const handleSave = () => {
        var items = [];

        templates.map((template) => {
            if (template.id === 0)
                items.push(template)
            else {
                if (template.items.some((item) => item.id === 0)) {
                    var item = items.find((item) => item.id === template.id);
                    if (!item)
                        items.push(template)
                }
            }
        });

        remowedItemsFolders.map((template) => items.push(template));

        editList.map((item) => {
            var selected = WizardFunctions.findItemById(templates, item, true);

            var isRemoved = false;
            if (remowedList && remowedList.length > 0)
                isRemoved = remowedList.some((removedItem) => removedItem.id === selected.id);

            if (selected && !isRemoved)
                items.push({ ...selected });
        });

        items.map((templateItem) => {
            templateItem.items.map((item) => {
                if (!item.isLoaded)
                    templateItem.items = WizardFunctions.removeItemByObject(templateItem.items, item)
                if (item.mainFilter)
                    item.mainFilter.filterGroups = [];

                if (item.addonFilter)
                    item.addonFilter.filterGroups = [];
            });
        });

        var request = {
            items: WizardFunctions.removeDuplicatesById(items),
            deletedItemRequests: remowedList,
            isReport: true
        }

        saveModel(request);

        getTitle(window.captions.MainTabStatisticReports);
    };

    const onCloseError = () => {
        setError(null);

        setEditList([]);
        setIsEdited(false);
        setRemowedList([]);

        setTemplates([]);
        setSelectedTemplate(null);

        setLanguages([]);

        reportSettingsLoad();

        getTitle(window.captions.MainTabStatisticReports);

        reportSettingsLoad();
    };

    const onClose = () => {
        setSelectedTemplate(null);
    };

    if (error) {
        return <Dialog width={400}>
            <div style={{ minHeight: "100px", maxHeight: "700px", overflow: "auto" }}>
                <MessageMask inlinen error={error} />
            </div>
            <DialogActionsBar>
                <Button onClick={() => { onCloseError() }}>{window.captions.Close}</Button>
            </DialogActionsBar>
        </Dialog>;
    }

    const updateItem = (model, selectedIndex) => {
        const separator = "_";
        let data = templates;

        if (selectedIndex.indexOf(separator) < 0) {
            // A root item is selected.
            copyAndUpdateItem(Number(selectedIndex), data, model);
        } else {
            // A child item is selected.
            const parentIndex = Number(selectedIndex.split(separator)[0]);
            const childIndex = Number(selectedIndex.split(separator)[1]);

            data[parentIndex] = { ...data[parentIndex] };
            data[parentIndex].items = data[parentIndex].items.slice();
            copyAndUpdateItem(childIndex, data[parentIndex].items, model);
        }
        setTemplates([...data]);
    };

    const copyAndUpdateItem = (itemIndex, siblings, newData) => {
        siblings[itemIndex] = { ...siblings[itemIndex] };
        siblings[itemIndex] = newData;
    };

    if (isLoaded)
        return <LoadingMask />;

    const paddingY = "dm-m-bottom dm-m-top";
    const mainButtons = [
        <Button key="addItem"
            icon="add"
            className={`dm-m-right ${paddingY}`}
            onClick={addFolderHandler} />,
        <Button
            key="saveItem"
            icon="save"
            disabled={!isEdited}
            className={`dm-m-right dm-positive-filled ${paddingY}`}
            onClick={handleSave}/>,
        <Button
            key="copyItem"
            icon="copy"
            disabled={!selectedTemplate ||
                selectedTemplate.item.type === statGeneralTypeEnum.folder ||
                selectedTemplate.item.type == statGeneralTypeEnum.documentGroup}
            onClick={copyHandler}
            className={`dm-m-right ${paddingY}`}/>,
        <Button
            key="deleteItem"
            icon="delete"
            disabled={!selectedTemplate}
            className={`dm-m-right dm-negative-filled ${paddingY}`}
            onClick={deleteItemHandler}/>
    ];

    const fullWidth = { width: "100%" };
    return <div className="dm-full-height" key="wizardView">
        <h1 className="dm-title">{title}</h1>
        <div className="dm-under-title-content k-d-flex-row">
            <div>
                <div>
                    <div className="dm-m-left">{mainButtons}</div>
                    <div style={{ minWidth: "300px", maxWidth: "500px", maxHeight: "calc(100vh - 100px)", overflow: "scroll" }}>
                        <ItemsTreeView key="ReportTemplates"
                            items={templates}
                            onSelect={handleSelectTemplate}
                            iconsLevel={{
                                "0": folderIcon,
                                "1": fileReportIcon,
                                "-0": folderAddIcon,
                                "-1": fileAddIcon
                            }}
                            differentIcon={{
                                parameter: "id",
                                value: 0
                            }}
                            selectedItem={selectedTemplate} />
                    </div>
                </div>
            </div>
            <div style={fullWidth}>
                {selectedTemplate
                    ? selectedTemplate.item.isLoaded || selectedTemplate.item.id === 0
                        ? <WizardComponent
                            languages={languages}
                            className="dm-full-height"
                            template={selectedTemplate.item}
                            onAddItem={(model) => addItemHandler(model)}
                            onEdit={(model) => handleEdit(model)}
                            onDelete={(model) => deleteItemHandler(model)}
                        />
                        : <LoadingMask />
                    : isSettingsLoaded
                        ? <LoadingMask />
                        : <div style={{ position: "relative", height: "100%", width: "100%" }}>
                            <div style={{
                                position: "absolute", textAlign: "center",
                                top: "50%", left: "50%", marginRight: "-50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                {window.captions.ChoseOrAddTemplate}
                            </div>
                        </div>
                }
            </div>
        </div>
    </div>

    function reportSettingsLoad() {
        setIsLoaded(true);
        fetchGet(`${window.constants.getWizardSettings}/reports`,
            model => {
                if (model) {
                    setTemplates(model);
                    setError(null);
                    setIsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }

            },
            ex => showError(ex)
        );
    }

    function selectedTemplateLoad(request) {
        setIsSettingsLoaded(true);
        var formattedRequest = {
            item: request.item,
            isReport: true
        } 
        fetchPost(`${window.constants.selectedWizardSettings}`,
            formattedRequest,
            model => {
                if (model) {
                    request.item = model;
                    model.isLoaded = true;

                    updateItem(model, request.index);

                    setSelectedTemplate(request);
                    setError(null);
                    setIsSettingsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function getAllDeletedIdList(request, template) {
        setIsSettingsLoaded(true);
        request.isReport = true;

        fetchPost(`${window.constants.deleteWizardItemList}`,
            request,
            model => {
                if (model) {
                    if (template && template.type !== statGeneralTypeEnum.folder) {
                        var folder = WizardFunctions.findItemById(templates, template.id, true);
                        if (folder) {
                            var item = remowedItemsFolders.find((item) => item.id === folder.id);
                            if (!item) {
                                remowedItemsFolders.push(folder);
                                setRemowedItemsFolders(remowedItemsFolders);
                            }
                        }
                    }

                    if (selectedTemplate.item.id === 0)
                        setTemplates(WizardFunctions.removeItemByObject(templates, selectedTemplate.item));
                    else
                        setTemplates(WizardFunctions.removeItemById(templates, selectedTemplate.item.id));

                    if (remowedList && remowedList.length <= 0)
                        setRemowedList(model);
                    else {
                        var newList = remowedList;
                        model.map((item) => {
                            var seleectedItem = newList.find((listItem) => listItem.id === item.id && listItem.type === item.type)
                            if (!seleectedItem)
                                newList.push(item)
                        })
                        setRemowedList(newList);
                    }

                    setIsSettingsLoaded(false);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function saveModel(request) {
        setIsLoaded(true);
        request.isReport = true;

        fetchPost(`${window.constants.saveWizardSettings}`,
            request,
            model => {
                if (model) {
                    if (model.isSuccess) {
                        setEditList([]);
                        setIsEdited(false);
                        setRemowedList([]);

                        setTemplates([]);
                        setSelectedTemplate(null);

                        setLanguages([]);

                        reportSettingsLoad();
                    }
                    else {
                        showError(model.message);
                    }

                    getTitle(window.captions.MainTabStatisticReports);
                } else {
                    showError(window.captions.LoadDataError);
                }
            },
            ex => {
                showError(ex);
            }
        );
    }

    function addFolderItem(model) {
        setIsLoaded(true);

        let reportNames = [];
    
        if (model.names)
            model.names.forEach(item => reportNames.push({ id: reportNames.length, name: item.name }));
        else
            templates.forEach(item => reportNames.push({ id: reportNames.length, name: item.name }));
    
        let reportRequest = {
            names: reportNames,
            type: model.name ? model.type : 0,
            isReport: true
        };
    
        fetchPost(`${window.constants.addWizardSettings}`,
            reportRequest,
            model => {
                var newTemplates = templates;
                newTemplates.push(model.item);
                setTemplates(newTemplates);
                setIsEdited(true);
                setIsLoaded(false);
            },
            ex => showError(ex)
        );

        onClose();
    };

    function addItem(request) {
        setIsLoaded(true);
        fetchPost(`${window.constants.addWizardSettings}`,
            request,
            model => {
                if (model) {
                    var newTemplates = templates;
                    var item = WizardFunctions.findItemById(newTemplates, model.id);
                    if (!item) {
                        showError("Not found template");
                        return;
                    }

                    item.items.push(model.item);
                    setTemplates(newTemplates);

                    setIsEdited(true);
                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );

        onClose();
    };

    function createCopy(request, parent) {
        setIsLoaded(true);
        fetchPost(`${window.constants.loadWizardItem}`,
            {
                type: 4,
                names: request
            },
            model => {
                if (model) {

                    if (parent) {
                        const newItem = JSON.parse(JSON.stringify(selectedTemplate.item));
                        newItem.id = 0;
                        newItem.name = model;
                        newItem.authorId = user.id;
                        newItem.author = user.name;
                        newItem.created = new Date();

                        newItem.variables.map((variablegroups) => {
                            variablegroups.id = 0;

                            variablegroups.items.map((variable) => {
                                variable.id = 0;
                            });
                        });

                        newItem.scripts.map((script) => { script.id = 0; });

                        parent.items.push(newItem);
                    }

                    setIsEdited(true);
                    setIsLoaded(false);
                }
            },
            ex => showError(ex)
        );

        onClose();
    };

    function showError(ex) {
        setError(ex);
        setIsLoaded(false);
        setIsSettingsLoaded(false);
    }

    function getTitle(text) {
        var title;
        if (text) 
            title = text;
        else
            title = selectedTemplate
                ? selectedTemplate.item.name
                : window.captions.MainTabStatisticReports;

        setTitle(title);
    };
}